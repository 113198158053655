<script setup>
// #region Imports
import { defineProps, ref, computed } from 'vue'
import { useRouter } from 'vue-router'

import moment from 'moment'

import DishCard from '@/components/dishCard/DishCard'
import InitLoader from '@/components/initLoader/InitLoader'
import NavigationZone from '@/components/navigationZone/NavigationZone'

import mealPlanService from '@/services/mealPlanService'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Props
const props = defineProps(['weekdayId'])
// #endregion

// #region Data
const isInit = ref(false)
const day = ref({})
const dishes = ref([])
// #endregion

// #region Computed
const getDate = computed(() => {
  return moment(day.value.meal_plan_beginning_date).add(day.value.order, 'd')
})
// #endregion

// #region Methods
const generateDescription = (mealName) => {
  let description = ''
  for (const dish of day.value.meals.find((x) => x.category.name === mealName)
    .meal.dishes) {
    if (dish.dish.display) {
      description +=
        '<a style="color:#717171;font-weight:bold" href="/dish/' +
        dish.dish.uuid +
        '">' +
        dish.dish.name +
        '</a><br/>'
    } else {
      description += dish.dish.name + '<br/>'
    }
  }
  return description
}
const checkRoute = (name) => {
  router.push(name)
}
// #endregion

// #region Created
if (!props.weekdayId) checkRoute({ name: 'MealPlan' })
mealPlanService
  .getWeekday(props.weekdayId)
  .then((response) => {
    day.value = response.data
    for (const meal of response.data.meals) {
      for (const dish of meal.meal.dishes) {
        if (dish.dish.display) {
          dishes.value.push(dish)
        }
      }
    }
    isInit.value = true
  })
  .catch((error) => {
    if (error.response?.status === 404) {
      checkRoute({ name: 'MealPlan' })
    }
  })
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0" fluid>
    <v-container v-if="isInit" class="pa-0 pa-md-8" fluid>
      <NavigationZone />
      <v-container class="pa-4 pa-md-4" fluid>
        <h1 class="capitalize">{{ getDate.format('dddd') }}</h1>
        <label class="label-title">{{ getDate.format('DD MMMM YYYY') }}</label>
      </v-container>
      <v-slide-group class="mealsWrapper my-5">
        <v-slide-item
          class="width-full mx-2"
          v-for="meal in day.meals"
          :key="meal.category.name"
        >
          <v-card class="mr-4 ml-1 width-full height-full" flat>
            <v-card-title>
              <h2 class="capitalize text-center pt-4 pb-2">
                {{ meal.category.name }}
              </h2>
            </v-card-title>
            <v-divider class="card-divider"></v-divider>
            <v-card-text align="center">
              <p
                v-html="generateDescription(meal.category.name)"
                style="font-family: barlow_regular"
              ></p>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
      <v-container class="pa-4 px-md-8" fluid>
        <h2 class="mb-3">{{ $t('weekday.dishes') }}</h2>
        <v-row align="center">
          <v-col
            sm="6"
            md="4"
            xl="3"
            v-for="dish in dishes"
            :key="dish.dish.uuid"
            align="center"
          >
            <DishCard :dish="dish.dish" />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped lang="scss">
.mealsWrapper,
::v-deep .v-slide-group__wrapper {
  min-height: 200px !important;
}
.width-full {
  width: 250px;
  @media only screen and (min-width: 961px) {
    width: 100% !important;
  }
}
.height-full {
  height: 100% !important;
}
.meal {
  min-height: 180px;
  width: 250px;

  .v-card__title {
    margin-top: -2px;

    h2 {
      margin: auto !important;
    }
  }

  .v-card__text {
    color: var(--v-grey-darken-1-base);
    padding: 10px;
    height: 100%;
  }

  img {
    width: 80px;
    height: 80px;
  }
}

.meal:first-of-type {
  margin-left: 15px !important;
}

.meal:last-of-type {
  margin-right: 15px !important;
}

.card-divider {
  width: 50px;
  border-color: var(--v-grey-darken-1-base) !important;
  margin: auto;
}

@media (min-width: 960px) {
  .meal {
    width: 33% !important;
  }
}
</style>
