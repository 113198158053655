<script setup>
// #region Imports
import { ref, computed, onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import { useI18n } from 'vue-i18n'

import { useVuelidate } from '@vuelidate/core'
import { required, sameAs } from '@vuelidate/validators'

import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_PLANS } from '@/plugins/stripePlans'

import NavigationZone from '@/components/navigationZone/NavigationZone'
import InitLoader from '@/components/initLoader/InitLoader'
import TAC from '@/components/tac/TAC'

import userService from '@/services/userService'
import stripeService from '@/services/stripeService'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Vuetify
const { smAndDown } = useDisplay()
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Data
const isInit = ref(false)
const spk = ref(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
const stripe = ref(undefined)
const prices = ref(STRIPE_PLANS)
const dialogTAC = ref(false)
// #endregion

// #region Form Data
const formState = reactive({
  selectedPrice: null,
  validateTAC: false
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    selectedPrice: { required },
    validateTAC: { checked: sameAs(true) }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Computed
const validateTACErrors = computed(() => {
  if (v$.value.validateTAC?.$dirty && v$.value.validateTAC?.checked.$invalid) {
    return t('message.error.required')
  }
  return []
})
// #endregion

// #region Methods
const createCheckoutSession = () => {
  v$.value.$touch()
  if (!v$.$invalid) {
    userService.validateTAC().then(() => {
      // window.fbq('track', 'InitiateCheckout')
      const priceId = { priceId: formState.selectedPrice.id }
      const t = stripeService.createCheckoutSession(priceId)
      t.then((result) => {
        const sessionId = result.data.sessionId
        stripe.value.redirectToCheckout({
          sessionId: sessionId
        })
      })
    })
  }
}
const checkRoute = (name) => {
  router.push(name)
}
// #endregion

// #region Created
userService
  .getSubscriptionStatus()
  .then((response) => {
    if (
      response.data.subscription_status === 'active' ||
      response.data.subscription_status === 'past_due'
    ) {
      checkRoute({ name: 'Account' })
    }
    isInit.value = true
  })
  .catch(() => {})
// #endregion

// #region Mounted
onMounted(async () => {
  stripe.value = await loadStripe(spk.value)
})
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0" fluid>
    <v-container v-if="isInit" class="pa-0 pa-md-8" fluid>
      <NavigationZone />
      <v-container class="pa-4">
        <h1>{{ $t('checkout.title') }}</h1>
      </v-container>
      <v-container class="pa-4 pt-md-0">
        <v-row>
          <v-col align="center">
            <v-radio-group
              v-model="formState.selectedPrice"
              hide-details
              class="ma-0"
            >
              <v-container v-for="price in prices" :key="price.id" class="pa-0">
                <v-radio color="primary" :value="price">
                  <template v-slot:label>
                    <span class="txt-small txt-gray"
                      ><b>{{
                        $t('checkout.prices.name', { month: price.months }) +
                        ' - '
                      }}</b></span
                    >
                    <span class="mandatory ml-1 txt-primary txt-small">
                      {{ $t('checkout.prices.price', { price: price.price }) }}
                    </span>
                  </template>
                </v-radio>
                <p class="ml-8 txt-left">{{ price.description }}</p>
              </v-container>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" align="center" class="py-0">
            <v-checkbox
              v-model="formState.validateTAC"
              class="mt-0"
              color="primary"
              :error-messages="validateTACErrors"
              @input="v$.validateTAC.$touch()"
              @blur="v$.validateTAC.$touch()"
              hide-details
            >
              <template v-slot:label>
                <span class="txt-left">
                  <span class="checkbox-label mr-1 txt-left txt-small">{{
                    $t('signup.tac.validateTAC')
                  }}</span>
                  <span
                    class="checkbox-label mandatory mr-1 txt-left txt-small txt-primary"
                    @click="dialogTAC = true"
                  >
                    {{ $t('signup.tac.TAC') }}
                  </span>
                  <span class="checkbox-label mandatory">*</span>
                </span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col align="center">
            <v-btn
              color="primary"
              @click="createCheckoutSession"
              :disabled="v$.$invalid"
            >
              {{ $t('checkout.validateButton') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog
        v-model="dialogTAC"
        width="800"
        :fullscreen="smAndDown"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar color="primary" v-if="smAndDown">
            <v-btn icon dark @click="dialogTAC = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('signup.tac.title') }}</v-toolbar-title>
          </v-toolbar>
          <v-card-title v-else>{{ $t('signup.tac.title') }}</v-card-title>
          <v-card-text class="pt-5">
            <TAC />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped>
.txt-gray {
  color: #707070 !important;
}
.txt-primary {
  color: #eb5c43 !important;
}
.txt-small {
  font-size: 14px !important;
}
.txt-left {
  text-align: left;
}
::v-deep .v-label {
  opacity: 100%;
}
::v-deep .v-messages__message {
  text-align: left;
}
::v-deep .v-btn--disabled {
  background-color: #d8d8d8 !important;
}

::v-deep .v-btn__overlay {
  opacity: 0% !important;
}

::v-deep .v-btn__content {
  color: rgba(0, 0, 0, 0.26) !important;
  font-size: 14px !important;
}

::v-deep .v-btn:not(.v-btn--disabled) span {
  color: white !important;
}
</style>
