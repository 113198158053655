import axios from 'axios'
// import { STRIPE_PLANS } from '@/plugins/stripePlans'

export default {
  getConfig() {
    return {
      headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('tokens'))?.access }
    }
  },
  getPriceList() {
    return axios.get(process.env.VUE_APP_API_URL + '/prices/', this.getConfig())
  },
  createCheckoutSession(priceId) {
    return axios.post(process.env.VUE_APP_API_URL + '/checkout/', priceId, this.getConfig())
  },
  customerPortal() {
    return axios.post(process.env.VUE_APP_API_URL + '/customer-portal/', {}, this.getConfig())
  },
  getPlanId() {
    return axios.get(process.env.VUE_APP_API_URL + '/plan-id/', this.getConfig())
  },
  sendPriceToPixel(attempt) {
    if (!attempt) attempt = 1
    this.getPlanId().then(response => {
      if (attempt <= 10) {
        if (!response.data.plan_id) {
          attempt++
          return setTimeout(() => {
            this.sendPriceToPixel(attempt)
          }, 5000)
        }
        // else {
        // const plan = STRIPE_PLANS.find(x => x.id === response.data.plan_id)
        // if (plan) {
        // window.fbq('track', 'Purchase', { value: plan.price * plan.months, currency: 'EUR' })
        // }
        // }
      }
    }).catch(() => { })
  }
}
