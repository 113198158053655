<script setup>
// #region Imports
import { ref, computed, watch } from 'vue'

import InitLoader from '@/components/initLoader/InitLoader'
import DishCard from '@/components/dishCard/DishCard'
import NavigationZone from '@/components/navigationZone/NavigationZone'

import _ from 'lodash'

import userService from '@/services/userService'
// #endregion

// #region Data
const isInit = ref(false)
const favouritesDishes = ref([])
const search = ref('')
const page = ref(1)
const itemsPerPage = ref(10)
const sortBy = ref('dish.title')
const sortDesc = ref(true)
const myDishes = ref([])
const totalItems = ref(null)
// #endregion

// #region Computed
const numberOfPages = computed(() => {
  return Math.ceil(totalItems.value / itemsPerPage.value)
})
// #endregion

// #region Methods
const nextPage = () => {
  if (page.value + 1 <= numberOfPages.value) page.value += 1
}
const formerPage = () => {
  if (page.value - 1 >= 1) page.value -= 1
}
const reload = _.debounce(() => {
  getdata()
}, 500)
const getdata = () => {
  userService
    .getFav()
    .then((response) => {
      const favourites = response?.data || []
      favouritesDishes.value = favourites.map((x) => {
        const dish = x.dish
        dish.favourite = true
        return dish
      })
      return userService.getMyDishes(search.value, page.value)
    })
    .then((data) => {
      const items = data.results
      myDishes.value = items.map((x) => {
        x.favourite = false
        for (const favourite of favouritesDishes.value) {
          if (favourite.name === x.name) {
            x.favourite = true
            break
          }
        }
        return x
      })
      totalItems.value = data.count
    })
    .finally((_) => {
      isInit.value = true
    })
}
// #endregion

// #region Watch
watch(page, () => {
  reload()
})
watch(search, () => {
  page.value = 1
  reload()
})
// #endregion

// #region Created
getdata()
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0 pa-md-8 pb-md-0" fluid>
    <v-container v-if="isInit" class="pa-0" fluid>
      <NavigationZone />
      <v-container class="pa-4" fluid>
        <h1>{{ $t('fav.title') }}</h1>
        <v-row>
          <v-col cols="12">
            <p class="ma-0">
              Tu trouveras dans cette rubrique l'intégralité des recettes de ton
              progamme. Celles que tu as mises en favoris ont un petit
              <v-icon size="small" color="#eb5c43">mdi-heart</v-icon>.
            </p>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pa-4 pt-md-0" fluid>
        <v-data-iterator
          :items="myDishes"
          :items-per-page.sync="itemsPerPage"
          :page="page"
          :sort-by="sortBy.toLowerCase()"
          :sort-desc="sortDesc"
          hide-default-footer
        >
          <template v-slot:header>
            <v-container class="header pt-0 transparent elevation-0" fluid>
              <v-row align="center" justify="center">
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  class="mb-5 pa-0 pa-md-4 pl-md-0"
                >
                  <v-text-field
                    v-model="search"
                    class="search-bar pt-0 mt-0 v-icon-primary"
                    :placeholder="$t('fav.search')"
                    prepend-inner-icon="mdi-magnify"
                    color="primary"
                    hide-details
                    full-width
                    variant="flat"
                    rounded
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:default="{ items }">
            <v-row class="mb-7">
              <v-col
                cols="12"
                sm="6"
                md="4"
                xl="3"
                v-for="dish in items"
                :key="dish.raw.uuid"
                align="center"
              >
                <DishCard
                  :favourite="dish.raw.favourite"
                  :dish="dish.raw"
                  :hide-portions="true"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:no-results>
            <v-container class="my-4 text-center txt-gray txt-small">
              <span class="">{{ $t('fav.no-search-result') }}</span>
            </v-container>
          </template>
          <template v-slot:no-data>
            <v-container class="my-4 text-center txt-gray txt-small">
              <span>{{ $t('fav.no-data') }}</span>
            </v-container>
          </template>
          <template v-slot:footer>
            <v-row
              v-if="numberOfPages > 0"
              class="mt-2"
              align="center"
              justify="center"
            >
              <v-spacer></v-spacer>
              <span class="mr-4 grey--text">
                {{ $t('fav.page', { page, numberOfPages }) }}
              </span>
              <v-btn v-if="page > 1" icon class="mr-1" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                v-if="page < numberOfPages"
                icon
                class="ml-1"
                @click="nextPage"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
        </v-data-iterator>
      </v-container>
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped lang="scss">
.header > ::v-deep.v-toolbar__content {
  padding: 0px;
}

.search-bar {
  max-width: 600px;
  background-color: white;
  border-radius: 64px;
}

.sort-bar {
  width: 100%;
}

::v-deep.v-text-field--solo > .v-input__control > .v-input__slot {
  background: var(--v-grey-lighten-2-base) !important;
}

::v-deep.v-btn.v-btn--active {
  opacity: 0.7 !important;
}
.v-icon-primary > .v-icon {
  background-color: #eb5c43 !important;
}
.txt-gray {
  color: #707070 !important;
}

.txt-small {
  font-size: 14px !important;
}

.txt-primary {
  color: #eb5c43 !important;
}
</style>
