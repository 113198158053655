<script setup>
// #region Imports
import { defineProps, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Props
const props = defineProps(['dish', 'hidePortions', 'favourite'])
// #endregion

// #region Store
const store = useStore()
// #endregion

// #region Store Data
const nbOfPersons = computed(() => store.getters.nbOfPersons)
// #endregion

// #region Computed
const getPictureUrl = computed(() => {
  return process.env.VUE_APP_API_URL + props.dish.pictures[0]?.uri || ''
})
const getAlternativeText = computed(() => {
  return props.dish.pictures[0]?.alternative_text || ''
})
const getTime = computed(() => {
  return props.dish.preparation_time + props.dish.cooking_time
})
// #endregion

// #region Methods
const getTagPictureUrl = (uri) => {
  return process.env.VUE_APP_API_URL + '/' + uri
}
const checkRoute = (name) => {
  router.push(name)
}
// #endregion
</script>

<template>
  <v-container class="pa-0">
    <v-card
      class="dish-wrapper"
      elevation="3"
      @click="checkRoute({ name: 'Dish', params: { dishId: props.dish.uuid } })"
    >
      <img
        class="dish-picture"
        :src="getPictureUrl"
        :alt="getAlternativeText"
        height="100%"
        width="100%"
      />
      <div v-if="favourite" class="dish-favourite">
        <v-icon size="40px" color="#eb5c43">mdi-heart</v-icon>
      </div>
      <v-card class="dish-card">
        <v-card-title style="padding: 16px">
          <h2 class="title">{{ props.dish.name }}</h2>
        </v-card-title>
        <v-card-text align="center">
          <v-row justify="center">
            <v-avatar
              v-for="tag in props.dish.tags"
              :key="tag.name"
              size="35"
              class="tag mx-1"
            >
              <img
                style="width: 35px"
                :src="getTagPictureUrl(tag.picture_uri)"
                :alt="tag.picture_alternative_text"
              />
            </v-avatar>
          </v-row>
          <v-row v-if="!hidePortions" no-gutters class="mt-6">
            <v-col sm="5" class="time-section">
              <span>{{ getTime }}</span>
              <span class="sublabel ml-1">{{ $t('dish.time') }}</span>
            </v-col>
            <v-col sm="2" class="time-portion-section">│</v-col>
            <v-col sm="5" class="portion-section">
              <span>{{ props.dish.portions * nbOfPersons }}</span>

              <span class="sublabel ml-1">
                {{ $t('dish.portion', props.dish.portions) }}</span
              >
            </v-col>
          </v-row>

          <v-row
            v-if="hidePortions"
            no-gutters
            class="mt-6"
            align-content="center"
            align="center"
          >
            <v-col>
              <v-icon
                class="mr-2"
                style="height: 24px"
                size="x-large"
                color="#232323"
                >mdi-clock-time-four-outline</v-icon
              >
              <span>{{ getTime }}</span>
              <span class="sublabel ml-1">{{ $t('dish.time') }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
  </v-container>
</template>

<style scoped lang="scss">
.dish-wrapper {
  height: 250px;
  max-width: 400px;
  min-width: 250px;
  width: 100%;
  position: relative;
  margin-bottom: 80px;
  overflow: visible;

  .dish-favourite {
    position: absolute;
    right: 30px;
    top: 30px;
  }

  .dish-picture {
    object-fit: cover;
    width: 100%;
    height: 250px;
  }

  img {
    border-radius: inherit;
  }
}

.title {
  white-space: normal !important;
  text-align: center;
}
.dish-card {
  position: absolute;
  width: 85%;
  margin-top: -90px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;

  .v-card__title h2 {
    margin: auto !important;
    word-break: normal;
  }

  .time-section {
    text-align: end;

    padding: 2px 15px 0px 0px;
    height: 25px;
  }

  .portion-section {
    text-align: start;
    padding: 2px 0px 0px 15px;
    height: 25px;
    border-left: solid 1px var(--v-grey-darken-1-base);
  }

  .time-portion-section {
    margin-left: -40px !important;
    margin-right: -40px !important;
    @media only screen and (min-width: 961px) {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
}

::v-deep.v-card--link::before {
  border-radius: 20px !important;
}
</style>
