import { createRouter as _createRouter, createWebHistory } from 'vue-router'

import MealPlan from '../views/MealPlan.vue'
import ShoppingList from '../views/ShoppingList.vue'
import History from '../views/History.vue'
import Weekday from '../views/Weekday.vue'
import Dish from '../views/Dish.vue'
import Fav from '../views/Fav.vue'
import Advices from '../views/Advices.vue'
import Account from '../views/Account.vue'
import AccountInformationsView from '../views/AccountInformationsView.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Home from '../views/auth/Home.vue'
import JoanaAdvices from '@/views/JoanaAdvices.vue'
import InstallationInstructions from '@/views/InstallationInstructions.vue'
import Onboarding from '@/views/Onboarding'
// auth
import Login from '../views/auth/Login.vue'
import Signup from '../views/auth/Signup.vue'
import SignupRedirection from '@/views/auth/SignupRedirection'
import ResendActivationEmail from '@/views/auth/ResendActivationEmail'
import ActivateAccount from '@/views/auth/ActivateAccount'
import ActivateFreeAccount from '@/views/auth/ActivateFreeAccount'
import ResetEmail from '@/views/auth/ResetEmail'
import ResetEmailRedirection from '@/views/auth/ResetEmailRedirection'
import ResetEmailConfirmation from '@/views/auth/ResetEmailConfirmation'
import ResetPassword from '@/views/auth/ResetPassword'
import ResetPasswordRedirection from '@/views/auth/ResetPasswordRedirection'
import ResetPasswordConfirmation from '../views/auth/ResetPasswordConfirmation.vue'
// stripe
import Checkout from '../views/stripe/Checkout.vue'
import CheckoutRedirection from '@/views/stripe/CheckoutRedirection'
import CustomerPortal from '@/views/stripe/CustomerPortal'
// admin
import AdminHome from '../views/admin/AdminHome.vue'
import MealPlansList from '../views/admin/mealPlans/MealPlansList.vue'
import DishesList from '../views/admin/dishes/DishesList.vue'
import IngredientsList from '../views/admin/ingredients/IngredientsList.vue'
import ProfilesList from '../views/admin/profiles/ProfilesList.vue'
import PicturesList from '../views/admin/pictures/PicturesList.vue'
import TagsList from '../views/admin/tags/TagsList.vue'
import UnitsOfMeasureList from '../views/admin/unitsOfMeasure/UnitsOfMeasureList.vue'
import IngredientCategoriesList from '../views/admin/ingredientCategories/IngredientCategoriesList.vue'
import TACList from '../views/admin/tac/TACList.vue'
import HowTo from '@/views/HowTo'
import JoanaAdvicesCategories from '../views/admin/joanaAdvices/JoanaAdvicesCategories.vue'
import JoanaAdvicesSubCategories from '../views/admin/joanaAdvices/JoanaAdvicesSubCategories.vue'

const routes = [
  {
    path: '/meal-plan/:mealPlanId?',
    name: 'MealPlan',
    component: MealPlan
  },
  {
    path: '/shoppinglist/:mealPlanId',
    name: 'ShoppingList',
    props: true,
    component: ShoppingList
  },
  {
    path: '/history',
    name: 'History',
    component: History
  },
  {
    path: '/weekday/:weekdayId',
    name: 'Weekday',
    props: true,
    component: Weekday
  },
  {
    path: '/dish/:dishId',
    name: 'Dish',
    props: true,
    component: Dish
  },
  {
    path: '/fav',
    name: 'Fav',
    component: Fav
  },
  {
    path: '/howTo',
    name: 'HowTo',
    component: HowTo
  },
  {
    path: '/advices',
    name: 'Advices',
    component: Advices
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/account-informations',
    name: 'AccountInformationsView',
    component: AccountInformationsView
  },
  {
    path: '/joana-advices',
    name: 'JoanaAdvices',
    component: JoanaAdvices
  },
  {
    path: '/installation-instructions',
    name: 'InstallationInstructions',
    component: InstallationInstructions
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding
  },
  /*
  Auth routes
   */
  {
    path: '/login',
    name: 'Login',
    props: true,
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/signup/redirect',
    name: 'SignupRedirection',
    component: SignupRedirection
  },
  {
    path: '/resend-activation',
    name: 'ResendActivationEmail',
    component: ResendActivationEmail
  },
  {
    path: '/activate/:uuid/:token',
    name: 'ActivateAccount',
    component: ActivateAccount
  },
  {
    path: '/activate/free/:uuid/:token',
    name: 'ActivateFreeAccount',
    component: ActivateFreeAccount
  },
  {
    path: '/email/reset',
    name: 'ResetEmail',
    component: ResetEmail
  },
  {
    path: '/email/reset/redirect',
    name: 'ResetEmailRedirection',
    component: ResetEmailRedirection
  },
  {
    path: '/email/reset/confirm/:uuid/:token',
    name: 'ResetEmailConfirmation',
    component: ResetEmailConfirmation
  },
  {
    path: '/password/reset',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/password/reset/redirect',
    name: 'ResetPasswordRedirection',
    component: ResetPasswordRedirection
  },
  {
    path: '/password/reset/confirm/:uuid/:token',
    name: 'ResetPasswordConfirmation',
    component: ResetPasswordConfirmation
  },
  /*
  Stripe routes
   */
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/checkout/redirect',
    name: 'CheckoutRedirection',
    component: CheckoutRedirection
  },
  {
    path: '/customer-portal',
    name: 'CustomerPortal',
    component: CustomerPortal
  },
  /*
  Admin routes
   */
  {
    path: '/admin/home',
    name: 'AdminHome',
    component: AdminHome
  },
  {
    path: '/admin/meal-plans',
    name: 'MealPlansList',
    component: MealPlansList
  },
  {
    path: '/admin/dishes',
    name: 'DishesList',
    component: DishesList
  },
  {
    path: '/admin/ingredients',
    name: 'IngredientsList',
    component: IngredientsList
  },
  {
    path: '/admin/profiles',
    name: 'ProfilesList',
    component: ProfilesList
  },
  {
    path: '/admin/pictures',
    name: 'PicturesList',
    component: PicturesList
  },
  {
    path: '/admin/tags',
    name: 'TagsList',
    component: TagsList
  },
  {
    path: '/admin/unitsOfMeasure',
    name: 'UnitsOfMeasureList',
    component: UnitsOfMeasureList
  },
  {
    path: '/admin/ingredientCategories',
    name: 'IngredientCategoriesList',
    component: IngredientCategoriesList
  },
  {
    path: '/admin/tac',
    name: 'TACList',
    component: TACList
  },
  {
    path: '/admin/joana-advices-categories',
    name: 'JoanaAdvicesCategories',
    component: JoanaAdvicesCategories
  },
  {
    path: '/admin/joana-advices-categories/:joanaAdviceCategoryId/sub-categories',
    name: 'JoanaAdvicesSubCategories',
    component: JoanaAdvicesSubCategories
  },
  {
    path: '/**',
    name: 'PageNotFound',
    component: PageNotFound
  }
]

export const createRouter = () => {
  return _createRouter({
    history: createWebHistory(),
    scrollBehavior: () => ({ top: 0 }),
    routes
  })
}

// const router = new VueRouter({
//   mode: 'history',
//   routes
// })

// export default router
