<script setup>
// #region Imports
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import Markdown from 'vue3-markdown-it'

import AccountProfileForm from '../components/Account/AccountProfileForm.vue'
import AndroidInstallation from '../components/Account/AndroidInstallation.vue'
import IosInstallation from '../components/Account/IosInstallation.vue'
import InitLoader from '@/components/initLoader/InitLoader'

import userService from '@/services/userService'
import joanaAdviceCategoryService from '@/services/adminJoanaAdvice/joanaAdviceCategoryService'
import authService from '@/services/authService'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Data
const user = ref(null)
const subscriptionStatus = ref(null)
const selectedAdviceId = ref(null)
const os = ref('')
const osList = ref([
  { value: 'android', display: 'Android' },
  { value: 'apple', display: 'Apple' }
])
const advices = ref([])
const isInit = ref(false)
// #endregion

// #region Computed
const selectedAdvice = computed(() => {
  return advices.value.find((x) => x.id === selectedAdviceId.value)
})
const profileFormValid = computed(() => {
  if (
    !user.value.sex ||
    !user.value.goal ||
    !user.value.diet ||
    !user.value.sport_frequency ||
    !user.value.nb_of_persons
  ) {
    return false
  }
  return true
})
// #endregion

// #region Methods
const getPictureUrl = (uri) => {
  if (uri) {
    return process.env.VUE_APP_API_URL + '/' + uri
  }
}
const handleProfileFormChange = (data) => {
  user.value = data
}
const validateOnbaording = () => {
  authService
    .updateUserProfile({ is_onboarding: true, ...user.value })
    .then(() => {
      checkRoute({ name: 'MealPlan' })
    })
    .catch(() => {
      alert('Une erreur est survenue.')
    })
}
const checkRoute = (name) => {
  router.push(name)
}
// #endregion

// #region Created
userService
  .getUser()
  .then((response) => {
    user.value = response.data
    userService.getSubscriptionStatus().then((response) => {
      subscriptionStatus.value = response.data.subscription_status
    })
  })
  .then(() => {
    return joanaAdviceCategoryService
      .getAdvicePublicCategories()
      .then((joanaAdvicesCategories) => {
        const tempAdvices = []
        if (joanaAdvicesCategories.length > 0) {
          for (const cat of joanaAdvicesCategories) {
            for (const subCat of cat.subcategories) {
              for (const advice of subCat.advices) {
                if (advice) {
                  tempAdvices.push(advice)
                }
              }
            }
          }
        }
        advices.value = [
          { id: null, question: 'Autre question/pas de question' },
          ...tempAdvices.filter((item) => item.is_onboarding === true)
        ]
      })
  })
  .then(() => {
    user.value.has_seen_onboarding = true
    return userService.updateUser(user.value)
  })
  .catch((error) => {
    alert(error)
  })
  .finally(() => {
    isInit.value = true
  })
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0" style="min-width: 370px" fluid>
    <v-container
      v-if="isInit"
      class="mt-16 px-7 mb-16"
      fluid
      style="max-width: 600px"
    >
      <div id="welcome-box">
        <h2 class="mb-3">Bienvenue</h2>
        <img width="30px" :src="require('@/assets/icons/welcome_logo.png')" />
      </div>
      <p>
        Je m’appelle Joana Hubert, je suis diététicienne nutritionniste diplômée
        d’état. Depuis 7 ans j’accompagne des femmes dans leur rééquilibrage
        alimentaire.
      </p>

      <v-row class="mt-9" justify="space-between">
        <v-col>
          <u><b>Ma mission?</b></u>
          <p class="mt-4">
            T’aider à perdre du poids définitivement et sans frustration
          </p>
        </v-col>
        <v-col class="pa-0" cols="auto"
          ><img
            width="139px"
            height="133px"
            :src="require('@/assets/img/photo_joana.png')"
        /></v-col>
      </v-row>

      <p class="mt-10 mb-15">
        Pour que tu réussisses à atteindre tes objectifs efficacement,
        <b
          >j’ai besoin que tu suives les 4 étapes de création de ton compte.
        </b>
      </p>

      <v-row justify="center">
        <div id="time-box">
          <p>Durée : - de 3 minutes</p>
          <img height="40px" :src="require('@/assets/icons/arrow_down.png')" />
        </div>
      </v-row>

      <h2 class="mt-16">Etape 1</h2>
      <strong class="mt-2">Dis-moi en + sur toi</strong>

      <p class="mt-7">
        Rempli les informations ci-dessous pour savoir si le programme est fait
        pour toi.
      </p>

      <AccountProfileForm
        @change="handleProfileFormChange($event)"
        class="mt-9"
        v-if="user"
        :user="user"
        :onboarding="true"
      >
      </AccountProfileForm>

      <h2 class="mt-16">Etape 2</h2>
      <strong>Retrouve tes menus en 1 clic</strong>

      <p class="mt-7">
        Installe ton espace client sur ton téléphone pour retrouver facilement
        les menus.
      </p>

      <v-row class="mt-11 mb-9" no-gutters>
        <v-col align-self="center" cols="2"
          ><v-img
            width="43"
            aspect-ratio="1"
            :src="require('@/assets/img/smartphone.png')"
          ></v-img
        ></v-col>
        <v-col cols="5" align-self="center" class="mr-2">
          <span>Système d’exploitation</span>
        </v-col>
        <v-col align="end" align-self="center">
          <v-select
            color="primary"
            variant="solo"
            density="compact"
            flat
            height="41"
            v-model="os"
            class="mt-0 pt-0"
            hide-details
            item-value="value"
            item-title="display"
            :items="osList"
          >
            <template v-slot:selection="{ item }">
              <span class="selected-item">{{ item.title }}</span>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <AndroidInstallation v-if="os === 'android'"></AndroidInstallation>
      <IosInstallation v-if="os === 'apple'"></IosInstallation>

      <h2 class="mt-16">Etape 3</h2>
      <strong>Découvre ton espace client</strong>

      <v-row
        ><v-col cols="12">
          <v-img
            :src="require('@/assets/img/tutoApp_2023.png')"
          ></v-img> </v-col
      ></v-row>

      <h2 class="mt-16">Etape 4</h2>
      <strong>La réponse aux questions que tu te poses</strong>

      <p class="mt-7">Sélectionne la question que tu te poses</p>

      <v-row class="mt-11 mb-7" no-gutters>
        <v-col align-self="center" cols="2"
          ><v-img
            width="43"
            aspect-ratio="1"
            :src="require('@/assets/icons/lightbulb.png')"
          ></v-img
        ></v-col>
        <v-col align="end" align-self="center">
          <v-select
            height="41"
            v-model="selectedAdviceId"
            class="mt-0 pt-0"
            color="primary"
            variant="solo"
            density="compact"
            flat
            hide-details
            item-value="id"
            item-title="question"
            :items="advices"
          >
            <template v-slot:selection="{ item }">
              <span class="selected-item txt-small">{{ item.title }}</span>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <div v-if="selectedAdvice.id" class="mt-3">
        <Markdown
          v-if="selectedAdvice.response"
          class="md-body advice-response"
          :source="selectedAdvice.response"
        />

        <v-img
          class="mt-5"
          v-if="selectedAdvice.photo"
          aspect-ration="1"
          :src="getPictureUrl(selectedAdvice.photo)"
        ></v-img>
      </div>

      <div v-if="!selectedAdvice.id" class="mt-14">
        <h3 class="" style="font-size: 18px !important; line-height: 22px">
          Tu as une question sans réponse ?<br />
          Voici 3 solutions :
        </h3>
        <ul style="list-style-position: inside">
          <li class="mt-14">
            Tu peux consulter la totalité de mes conseils dans cette rubrique.
            Tu y trouveras très probablement la réponse à ta question.
            <v-row justify="center" class="mt-4"
              ><img
                width="318px"
                :src="require('@/assets/img/onboarding_menu_capture.png')"
                alt="menu"
            /></v-row>
          </li>
          <li class="mt-14">
            Tu peux m’écrire un message privé sur
            <a
              style="color: #eb5c43"
              href="https://www.instagram.com/joanaetvous/?hl=fr"
              >instagram</a
            >
            <v-row justify="center" class="mt-6"
              ><img
                width="318px"
                :src="require('@/assets/img/onboarding_capture_instagram.png')"
                alt="menu"
            /></v-row>
          </li>
          <li class="mt-14">
            Tu peux m’écrire un email à l’adresse suivante :
            <a style="color: #eb5c43" href="mailto:contact@joanaetvous.com"
              >contact@joanaetvous.com</a
            >
          </li>
        </ul>
      </div>

      <h2 class="mt-16">Place à l’action ?</h2>

      <p class="mt-3">
        Merci d’avoir répondu à mes questions, maintenant place à l’action.
        Clique sur le bouton pour découvrir le menu de la semaine en cours.
      </p>

      <div v-if="!profileFormValid" class="iphone-advertising mb-5 mt-12">
        Merci de remplir tous les champs obligatoires avant de pouvoir passer à
        l'étape suivante
        <img :src="require('@/assets/icons/warning.png')" alt="" />
      </div>

      <div class="button-wrapper my-10" style="width: fit-content">
        <img width="30px" :src="require('@/assets/icons/welcome_logo.png')" />

        <v-btn
          :disabled="!profileFormValid"
          @click="validateOnbaording"
          text
          class="link boutonPrincipales mt-4"
        >
          Découvrir le programme
        </v-btn>
      </div>
    </v-container>

    <InitLoader v-if="!isInit"></InitLoader>
  </v-container>
</template>

<style scoped lang="scss">
::v-deep .message-box .message-text {
  font-size: 16px !important;
}

h2 {
  font-size: 25px;
  color: #eb5c43;
}

p {
  color: #323232;
  font-size: 16px;
  line-height: 19px;
}

strong {
  color: #eb5c43;
  font-size: 18px;
  line-height: 22px;
  margin-top: 10px;
  font-family: Barlow-Bold;
  display: block;
}

#welcome-box {
  position: relative;

  img {
    position: absolute;
    left: 120px;
    top: -17px;
  }
}

#time-box {
  position: relative;

  img {
    position: absolute;
    right: -22px;
    top: -10px;
  }
}

.v-input {
  background-color: white !important;
  font-family: 'Barlow-Bold';
}

.v-text-field--rounded {
  border-radius: 7px;
}

::v-deep .v-input {
  border-radius: 233px !important;
}

::v-deep .v-input__control {
  border-radius: 233px !important;
}

::v-deep .v-field {
  border-radius: 7px !important;
}

.selected-item {
  color: #323232;
  text-overflow: ellipsis;
}

ul {
  li {
    font-size: 16px;
    line-height: 19px;
  }
}

a {
  font-family: Barlow-Bold;
}

.button-wrapper {
  position: relative;

  img {
    position: absolute;
    right: -30px;
    top: -12px;
  }
}

.iphone-advertising {
  position: relative;
  background-color: #fec46d;
  color: white;
  border-radius: 7px;
  padding: 10px;
  font-size: 14px;
  line-height: 17px;

  img {
    width: 40px;
    top: -30px;
    height: 40px;
    position: absolute;
    right: 20px;
  }
}

.md-body {
  list-style-position: inside;
}
.txt-small {
  font-size: 14px !important;
}
</style>
