import { createStore as _createStore } from 'vuex'

export const createStore = () => {
  return _createStore({
    state() {
      return {
        user: null,
        foo: 'bar'
      }
    },
    getters: {
      nbOfPersons: (state) => {
        if (state.user) {
          return state.user.nb_of_persons
        } else {
          return 1
        }
      },
      userInfo: (state) => {
        if (state.user) {
          return state.user
        } else {
          return null
        }
      }
    },
    mutations: {
      SET_USER(state, payload) {
        state.user = payload
      }
    },
    actions: {
      setUser(context, payload) {
        context.commit('SET_USER', payload)
      }
    },
    modules: {}
  })
}
