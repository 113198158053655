import answers from './planMessageAnswers'
const getMessage = (data) => {
  const dataString = JSON.stringify(data)
  if (dataString === JSON.stringify(['woman', 'weight_loss', 'less_than_2_hours', 'single', 'under_sixty_years_old'])) {
    return answers.get(1)
  }
  if (dataString === JSON.stringify(['woman', 'weight_loss', 'less_than_2_hours', 'single', 'over_sixty_years_old'])) {
    return answers.get(2)
  }
  if (dataString === JSON.stringify(['woman', 'weight_loss', 'less_than_2_hours', 'multi', 'under_sixty_years_old'])) {
    return answers.get(4)
  }
  if (dataString === JSON.stringify(['woman', 'weight_loss', 'less_than_2_hours', 'multi', 'over_sixty_years_old'])) {
    return answers.get(5)
  }
  if (dataString === JSON.stringify(['woman', 'weight_loss', 'more_than_2_hours', 'single', 'under_sixty_years_old'])) {
    return answers.get(7)
  }
  if (dataString === JSON.stringify(['woman', 'weight_loss', 'more_than_2_hours', 'single', 'over_sixty_years_old'])) {
    return answers.get(8)
  }
  if (dataString === JSON.stringify(['woman', 'weight_loss', 'more_than_2_hours', 'multi', 'under_sixty_years_old'])) {
    return answers.get(10)
  }
  if (dataString === JSON.stringify(['woman', 'weight_loss', 'more_than_2_hours', 'multi', 'over_sixty_years_old'])) {
    return answers.get(4)
  }
  if (dataString === JSON.stringify(['woman', 'weight_stability', 'less_than_2_hours', 'single', 'under_sixty_years_old'])) {
    return answers.get(3)
  }
  if (dataString === JSON.stringify(['woman', 'weight_stability', 'less_than_2_hours', 'single', 'over_sixty_years_old'])) {
    return answers.get(3)
  }
  if (dataString === JSON.stringify(['woman', 'weight_stability', 'less_than_2_hours', 'multi', 'under_sixty_years_old'])) {
    return answers.get(6)
  }
  if (dataString === JSON.stringify(['woman', 'weight_stability', 'less_than_2_hours', 'multi', 'over_sixty_years_old'])) {
    return answers.get(6)
  }
  if (dataString === JSON.stringify(['woman', 'weight_stability', 'more_than_2_hours', 'single', 'under_sixty_years_old'])) {
    return answers.get(9)
  }
  if (dataString === JSON.stringify(['woman', 'weight_stability', 'more_than_2_hours', 'single', 'over_sixty_years_old'])) {
    return answers.get(3)
  }
  if (dataString === JSON.stringify(['woman', 'weight_stability', 'more_than_2_hours', 'multi', 'under_sixty_years_old'])) {
    return answers.get(11)
  }
  if (dataString === JSON.stringify(['woman', 'weight_stability', 'more_than_2_hours', 'multi', 'over_sixty_years_old'])) {
    return answers.get(12)
  }
  if (dataString === JSON.stringify(['man', 'weight_loss', 'less_than_2_hours', 'single', 'under_sixty_years_old'])) {
    return answers.get(13)
  }
  if (dataString === JSON.stringify(['man', 'weight_loss', 'less_than_2_hours', 'single', 'over_sixty_years_old'])) {
    return answers.get(13)
  }
  if (dataString === JSON.stringify(['man', 'weight_loss', 'less_than_2_hours', 'multi', 'under_sixty_years_old'])) {
    return answers.get(13)
  }
  if (dataString === JSON.stringify(['man', 'weight_loss', 'less_than_2_hours', 'multi', 'over_sixty_years_old'])) {
    return answers.get(13)
  }
  if (dataString === JSON.stringify(['man', 'weight_loss', 'more_than_2_hours', 'single', 'under_sixty_years_old'])) {
    return answers.get(15)
  }
  if (dataString === JSON.stringify(['man', 'weight_loss', 'more_than_2_hours', 'single', 'over_sixty_years_old'])) {
    return answers.get(15)
  }
  if (dataString === JSON.stringify(['man', 'weight_loss', 'more_than_2_hours', 'multi', 'under_sixty_years_old'])) {
    return answers.get(15)
  }
  if (dataString === JSON.stringify(['man', 'weight_loss', 'more_than_2_hours', 'multi', 'over_sixty_years_old'])) {
    return answers.get(15)
  }
  if (dataString === JSON.stringify(['man', 'weight_stability', 'less_than_2_hours', 'single', 'under_sixty_years_old'])) {
    return answers.get(14)
  }
  if (dataString === JSON.stringify(['man', 'weight_stability', 'less_than_2_hours', 'single', 'over_sixty_years_old'])) {
    return answers.get(14)
  }
  if (dataString === JSON.stringify(['man', 'weight_stability', 'less_than_2_hours', 'multi', 'under_sixty_years_old'])) {
    return answers.get(14)
  }
  if (dataString === JSON.stringify(['man', 'weight_stability', 'less_than_2_hours', 'multi', 'over_sixty_years_old'])) {
    return answers.get(14)
  }
  if (dataString === JSON.stringify(['man', 'weight_stability', 'more_than_2_hours', 'single', 'under_sixty_years_old'])) {
    return answers.get(14)
  }
  if (dataString === JSON.stringify(['man', 'weight_stability', 'more_than_2_hours', 'single', 'over_sixty_years_old'])) {
    return answers.get(14)
  }
  if (dataString === JSON.stringify(['man', 'weight_stability', 'more_than_2_hours', 'multi', 'under_sixty_years_old'])) {
    return answers.get(14)
  }
  if (dataString === JSON.stringify(['man', 'weight_stability', 'more_than_2_hours', 'multi', 'over_sixty_years_old'])) {
    return answers.get(14)
  }
  return '-'
}
export default getMessage
