<script setup>
// #region Imports
import { reactive, computed, defineProps, defineEmits, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import _ from 'lodash'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import adminService from '@/services/adminService'
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Props
const props = defineProps(['pictureToEdit'])
// #endregion

// #region Emits
const emit = defineEmits(['close'])
// #endregion

// #region Form Data
const formState = reactive({
  picture: {},
  pictureFile: null
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    picture: {
      alternative_text: { required },
      name: { required },
      type: { required }
    },
    pictureFile: { required }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Data
const dialog = ref(true)

const searchTypes = ref('')
const typesLoaded = ref([])
const isLoadingTypes = ref(false)
// #endregion

// #region Computed
const pictureFileErrors = computed(() => {
  if (v$.value.pictureFile.$dirty && v$.value.pictureFile.required.$invalid) {
    return t('message.error.required')
  }
  return []
})
const alternativeTextErrors = computed(() => {
  if (
    v$.value.picture.alternative_text.$dirty &&
    v$.value.picture.alternative_text.required.$invalid
  ) {
    return t('message.error.required')
  }
  return []
})
const nameErrors = computed(() => {
  if (v$.value.picture.name.$dirty && v$.value.picture.name.required.$invalid) {
    return t('message.error.required')
  }
  return []
})
// #endregion

// #region Methods
const savePicture = () => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    const formData = new FormData()
    formData.append('uri', formState.pictureFile[0])
    formData.append('alternative_text', formState.picture.alternative_text)
    formData.append('name', formState.picture.name)
    formData.append('type', formState.picture.type.id)
    if (formState.picture.id) {
      adminService.updatePicture(formState.picture.id, formData).then(() => {
        emit('close')
      })
    } else {
      adminService.createPicture(formData).then(() => {
        emit('close')
      })
    }
  }
}
// #endregion

// #region Watch
watch(searchTypes.value, () => {
  _.debounce((val) => {
    if (isLoadingTypes.value) return
    isLoadingTypes.value = true
    adminService
      .getPictureTypes(null, val)
      .then((response) => {
        typesLoaded.value = response.data.results
      })
      .finally(() => (isLoadingTypes.value = false))
  }, 500)
})
// #endregion

// #region Created
formState.picture = props.pictureToEdit

isLoadingTypes.value = true
adminService
  .getPictureTypes(null)
  .then((response) => {
    typesLoaded.value = response.data.results
  })
  .finally(() => (isLoadingTypes.value = false))
// #endregion
</script>

<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2 bg-grey-title">
        Editer photo
      </v-card-title>
      <v-form @submit.prevent="savePicture">
        <v-card-text>
          <v-row>
            <v-col cols="12" align="center">
              <v-text-field
                v-model="formState.picture.name"
                :error-messages="nameErrors"
                @input="v$.picture.name.$touch()"
                @blur="v$.picture.name.$touch()"
                hide-details
                variant="outlined"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Nom pour la recherche
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" align="center">
              <v-text-field
                v-model="formState.picture.alternative_text"
                :error-messages="alternativeTextErrors"
                @input="v$.picture.alternative_text.$touch()"
                @blur="v$.picture.alternative_text.$touch()"
                hide-details
                variant="outlined"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Texte alternatif
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="formState.picture.type"
                :items="typesLoaded"
                item-title="name"
                :search-input.sync="searchTypes"
                return-object
                hide-details
                variant="outlined"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Type de photo
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" align="center">
              <v-file-input
                v-model="formState.pictureFile"
                :error-messages="pictureFileErrors"
                @input="v$.pictureFile.$touch()"
                @blur="v$.pictureFile.$touch()"
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                accept="image/*"
                hide-details
                variant="outlined"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Photo
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('close')"> Fermer </v-btn>
          <v-btn
            aria-label="Valider"
            color="primary"
            type="submit"
            text
            :disabled="v$.$invalid"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.bg-grey-title {
  background-color: #e0e0e0;
}
</style>
