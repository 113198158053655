<script setup></script>

<template>
  <v-container class="fill-height text-center">
    <div class="text-center loader-wrapper">
      <v-progress-circular indeterminate color="primary" :size="100" :width="7">
        <v-img class="loader-logo" src="@/assets/logo.png"></v-img>
      </v-progress-circular>
    </div>
  </v-container>
</template>

<style scoped>
.loader-wrapper {
  width: 100%;
}
.loader-logo {
  width: 90px;
}
</style>
