<script setup>
// #region Imports
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'

import EditMealPlanModal from '@/components/admin/mealPlans/EditMealPlanModal'
import EditWeekdayModal from '@/components/admin/weekdays/EditWeekdayModal'
import ConfirmDialog from '@/components/admin/miscellaneous/confirmDialog/ConfirmDialog'
import InitLoader from '@/components/initLoader/InitLoader'

import moment from 'moment'

import _ from 'lodash'

import adminService from '@/services/adminService'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Data
const confirm = ref(null)

const isInit = ref(false)
const mealPlans = ref([])
const page = ref(1)
const totalItems = ref(0)
const itemsPerPage = ref(10)
const search = ref('')
const expanded = ref([])
const editMealPlanModal = ref(false)
const mealPlanToEdit = ref({})
const editWeekdayModal = ref(false)
const weekdayToEdit = ref({})
// #endregion

// #region Computed
const pageCount = computed(() => {
  const totalPages = ~~(totalItems.value / itemsPerPage.value)
  const modulo = totalItems.value % itemsPerPage.value
  return modulo === 0 ? totalPages : totalPages + 1
})
const headers = computed(() => {
  return [
    { title: '', key: 'data-table-expand' },
    { title: 'Date de début', value: 'beginning_date' },
    { title: 'Date de sortie', value: 'release_date' },
    { value: 'diet', sortable: false, width: '200px', fixed: true },
    { value: 'published', sortable: false, width: '20px', fixed: true },
    {
      title: 'Actions',
      value: 'actions',
      sortable: false,
      width: '250px',
      fixed: true
    }
  ]
})
// #endregion

// #region Methods
const formatDate = (date) => {
  return moment(date).format('DD/MM/YYYY')
}
const getDate = (date, order) => {
  return moment(date).add(order, 'd').format('dddd')
}
const editMealPlan = (item) => {
  mealPlanToEdit.value = item ? { ...item } : {}
  editMealPlanModal.value = true
}
const publishMealPlan = (item) => {
  confirm.value
    .open(
      'Publier le programme',
      'Souhaitez-vous vraiment publier ce programme ?',
      { color: 'primary' }
    )
    .then((confirm) => {
      if (confirm) {
        const mealPlantoSave = { ...item, ...{ published: true } }
        adminService.publishMealPlan(mealPlantoSave).then(() => {
          reload()
        })
      }
    })
}
const editWeekday = (item, uuid) => {
  weekdayToEdit.value = JSON.parse(JSON.stringify(item))
  weekdayToEdit.value.meal_plan = uuid
  editWeekdayModal.value = true
}
const deleteMealPlan = (id) => {
  confirm.value
    .open(
      'Supprimer le programme',
      'Souhaitez-vous vraiment supprimer ce programme ?',
      { color: 'primary' }
    )
    .then((confirm) => {
      if (confirm) {
        adminService.deleteMealPlan(id).then(() => {
          reload()
        })
      }
    })
}
const resolveMealPlanPath = (mealPlanId) => {
  const route = router.resolve({
    name: 'MealPlan',
    params: { mealPlanId }
  })
  return route.href
}
const reload = _.debounce(() => {
  editMealPlanModal.value = false
  editWeekdayModal.value = false
  adminService.getMealPlans(page.value, search.value).then((response) => {
    totalItems.value = response.data.count
    mealPlans.value = response.data.results
  })
}, 500)
// #endregion

// #region Watch
watch(page, () => {
  reload()
})
watch(search, () => {
  page.value = 1
  reload()
})
// #endregion

// #region Created
adminService
  .getMealPlans()
  .then((response) => {
    totalItems.value = response.data.count
    mealPlans.value = response.data.results
    isInit.value = true
  })
  .catch(() => {})
// #endregion
</script>

<template>
  <v-container class="admin-wrapper-container" fluid>
    <v-container v-if="isInit" class="pa-0" fluid>
      <h1 class="mb-3">Programmes</h1>
      <v-data-table
        :headers="headers"
        :items="mealPlans"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        class="py-4"
        hide-default-footer
        single-expand
        :expanded.sync="expanded"
        hover
        density="compact"
        item-key="uuid"
        show-expand
      >
        <template v-slot:bottom></template>
        <template v-slot:top>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="5" class="py-0" align="left">
              <h2 class="ml-5">Liste des programmes</h2>
            </v-col>
            <v-col cols="8" sm="6" class="py-0" align="right">
              <v-text-field
                v-model="search"
                variant="outlined"
                rounded
                color="primary"
                label="Rechercher"
                class="mx-4 admin-search-bar"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="1" class="py-0" align="center">
              <v-btn
                icon
                variant="text"
                color="primary"
                style="opacity: 75%"
                @click="editMealPlan()"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.beginning_date`]="{ item }">
          {{ formatDate(item.beginning_date) }}
        </template>
        <template v-slot:[`item.release_date`]="{ item }">
          {{ formatDate(item.release_date) }}
        </template>
        <template v-slot:[`item.diet`]="{ item }">
          <span
            :class="[item.diet === 'vegetarian' ? 'vegetarian' : 'flexitarian']"
            >{{
              item.diet === 'vegetarian' ? 'Végétarien' : 'Flexitarien'
            }}</span
          >
        </template>
        <template v-slot:[`item.published`]="{ item }">
          <v-icon small v-if="item.published" color="success">
            mdi-check
          </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            style="opacity: 75%"
            icon
            variant="text"
            :href="resolveMealPlanPath(item.uuid)"
            target="_blank"
          >
            <v-icon> mdi-eye </v-icon>
          </v-btn>
          <v-btn
            v-if="!item.published"
            style="opacity: 75%"
            icon
            variant="text"
            @click="publishMealPlan(item)"
          >
            <v-icon> mdi-check </v-icon>
          </v-btn>
          <v-btn
            style="opacity: 75%"
            icon
            variant="text"
            @click="editMealPlan(item)"
          >
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            style="opacity: 75%"
            icon
            variant="text"
            @click="deleteMealPlan(item.uuid)"
          >
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-row="{ columns, item }">
          <td :colspan="columns.length">
            <v-row>
              <v-col sm="3" v-for="day in item.weekdays" :key="day.uuid">
                <v-card>
                  <v-card-title>
                    <div class="flex">
                      <div class="font-bold">
                        {{ getDate(item.beginning_date, day.order) }}
                      </div>
                      <v-btn
                        style="opacity: 75%"
                        icon
                        variant="text"
                        @click="editWeekday(day, item.uuid)"
                      >
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </div>
                  </v-card-title>
                  <v-card-text align="center">
                    <v-container
                      v-for="meal in day.meals"
                      :key="meal.order"
                      class="pa-0 mb-3"
                    >
                      <h3 class="mb-1">{{ meal.category.name }}</h3>
                      <v-container
                        v-for="dish in meal.meal.dishes"
                        :key="dish.order"
                        class="pa-0"
                      >
                        {{ dish.dish.name }}
                      </v-container>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
      <div class="text-center pt-2 mb-8">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="10"
          active-color="primary"
          density="comfortable"
          variant="elevated"
          opacity="100"
        ></v-pagination>
      </div>
      <EditMealPlanModal
        v-if="editMealPlanModal"
        :mealPlanToEdit="mealPlanToEdit"
        @close="reload()"
      />
      <EditWeekdayModal
        v-if="editWeekdayModal"
        :weekdayToEdit="weekdayToEdit"
        @close="reload()"
      />
      <ConfirmDialog ref="confirm" />
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped lang="scss">
::v-deep .v-data-table-header__content {
  font-weight: bold;
}
.vegetarian {
  background-color: #41553d;
  padding: 5px 10px;
  border-radius: 15px;
  color: white;
  height: 30px;
}
.flexitarian {
  background-color: #fec46d;
  padding: 5px 10px;
  border-radius: 15px;
  color: white;
  height: 30px;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.font-bold {
  font-weight: bold;
  font-size: 16px !important;
}
</style>
