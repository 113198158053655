<script setup>
// #region Imports
import { defineProps, computed, ref, onUpdated } from 'vue'

import Sortable from 'sortablejs'

import EditAdviceQuestionModal from '@/components/admin/joanaAdvices/EditAdviceQuestionModal'
import ConfirmDialog from '@/components/admin/miscellaneous/confirmDialog/ConfirmDialog'

import joanaAdviceQuestionService from '@/services/adminJoanaAdvice/joanaAdviceQuestionService'
// #endregion

// #region Props
const props = defineProps(['adviceSubCategory', 'joanaAdviceCategoryId'])
// #endregion

// #region Data
const confirm = ref(null)

const isInit = ref(false)
const advices = ref(null)
const adviceQuestionToManage = ref(null)
const editAdviceQuestionModal = ref(false)
const page = ref(1)
const totalItems = ref(0)
const itemsPerPage = ref(10)
const sortableInstance = ref(null)
// #endregion

// #region Computed
const headers = computed(() => {
  return [
    { title: 'Icone', value: 'icon', sortable: false, width: '50px' },
    { title: 'Question', value: 'question', sortable: false },
    {
      title: 'Actions',
      value: 'actions',
      sortable: false,
      width: '150px',
      fixed: true
    }
  ]
})
// #endregion

// #region Methods
const editAdvice = (advice) => {
  adviceQuestionToManage.value = advice
  editAdviceQuestionModal.value = true
}
const deleteAdvice = (id) => {
  const deletedItem = advices.value.find((x) => x.id === id)
  confirm.value
    .open(
      'Supprimer la question',
      'Souhaitez-vous vraiment supprimer cette question ?',
      { color: 'primary' }
    )
    .then((confirm) => {
      if (confirm) {
        joanaAdviceQuestionService
          .deleteAdviceQuestion(
            props.joanaAdviceCategoryId,
            props.adviceSubCategory.id,
            id
          )
          .then(() => {
            const index = this.advices.findIndex((x) => x.id === id)
            this.advices.splice(index, 1)
          })
          .then(() => {
            const list = advices.value
            const newOrders = []
            for (const item of list) {
              if (item.order > deletedItem.order) {
                newOrders.push({ id: item.id, order: item.order - 1 })
              } else {
                newOrders.push({ id: item.id, order: item.order })
              }
            }
            return joanaAdviceQuestionService
              .updateAdviceQuestionOrder(
                props.joanaAdviceCategoryId,
                props.adviceSubCategory.id,
                newOrders
              )
              .then((advices) => {
                advices.value = advices
              })
          })
          .catch((error) => {
            alert(error)
          })
      }
    })
}
const closeEditAdviceQuestionModal = () => {
  editAdviceQuestionModal.value = false
}
const getPictureUrl = (uri) => {
  if (uri) {
    return process.env.VUE_APP_API_URL + '/' + uri
  }
}
const handleSubmitQuestionModal = (data) => {
  if (data.id) {
    joanaAdviceQuestionService
      .updateAdviceQuestion(
        props.joanaAdviceCategoryId,
        props.adviceSubCategory.id,
        data.id,
        data.formData
      )
      .then((updatedAdviceQuestion) => {
        const index = advices.value.findIndex((x) => x.id === data.id)
        const updatedList = [...advices.value]
        updatedList[index] = { ...updatedAdviceQuestion }
        advices.value = updatedList
      })
      .catch((error) => {
        alert(error)
      })
      .finally(() => {
        editAdviceQuestionModal.value = false
      })
  } else {
    data.formData.append('order', advices.value.length + 1)
    joanaAdviceQuestionService
      .createAdviceQuestion(
        props.joanaAdviceCategoryId,
        props.adviceSubCategory.id,
        data.formData
      )
      .then((newAdviceQuestion) => {
        advices.value.push(newAdviceQuestion)
      })
      .catch((error) => {
        alert(error)
      })
      .finally(() => {
        editAdviceQuestionModal.value = false
      })
  }
}
const initSortable = () => {
  const table = document.querySelector('#advice-question-table tbody')
  if (!table) {
    return
  }
  const vueObj = this
  sortableInstance.value = Sortable.create(table, {
    group: 'adviceQuestion',
    onEnd({ newIndex, oldIndex }) {
      const data = vueObj._data.advices
      const moveId = data[oldIndex].id
      const newPosition = newIndex + 1
      const oldPosition = oldIndex + 1
      const newOrders = []
      for (const item of vueObj._data.advices) {
        if (
          moveId !== item.id &&
          item.order >= newPosition &&
          item.order < oldPosition
        ) {
          newOrders.push({ id: item.id, order: item.order + 1 })
        } else if (
          moveId !== item.id &&
          item.order > oldPosition &&
          item.order <= newPosition
        ) {
          newOrders.push({ id: item.id, order: item.order - 1 })
        } else if (item.id === moveId) {
          newOrders.push({ id: item.id, order: newPosition })
        } else {
          newOrders.push({ id: item.id, order: item.order })
        }
      }
      joanaAdviceQuestionService
        .updateAdviceQuestionOrder(
          vueObj.joanaAdviceCategoryId,
          vueObj.adviceSubCategory.id,
          newOrders
        )
        .then((advices) => {
          vueObj.advices = advices
        })
        .catch((error) => {
          alert(error)
        })
    }
  })
}
// #endregion

// #region Created
joanaAdviceQuestionService
  .getAdviceQuestions(props.joanaAdviceCategoryId, props.adviceSubCategory.id)
  .then((data) => {
    advices.value = data
    totalItems.value = advices.value.length
    isInit.value = true
    initSortable()
  })
// #endregion

// #region Updated
onUpdated(() => {
  initSortable()
})

// #endregion
</script>

<template>
  <v-container fluid>
    <v-data-table
      v-if="isInit"
      :items="advices"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      :headers="headers"
      hover
      density="compact"
      hide-default-footer
      id="advice-question-table"
    >
      <template v-slot:bottom></template>
      <template v-slot:top>
        <v-row justify="center" align="center">
          <v-col sm="11" cols="auto">
            <h3 class="mb-5">Questions</h3>
          </v-col>
          <v-col class="mb-5" sm="1" cols="auto" align="right">
            <v-btn icon variant="text" color="primary" @click="editAdvice()">
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.icon`]="{ item }">
        <v-row>
          <v-col>
            <div v-if="!item.icon" class="icon-placeholder"></div>
            <v-img
              v-if="item.icon"
              width="40"
              height="40"
              aspect-ration="1"
              class="admin-list-picture"
              :src="getPictureUrl(item.icon)"
            ></v-img>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          style="opacity: 75%"
          variant="text"
          @click="editAdvice(item)"
        >
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
        <v-btn
          icon
          style="opacity: 75%"
          variant="text"
          @click="deleteAdvice(item.id)"
        >
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <EditAdviceQuestionModal
      :adviceQuestionToManage="adviceQuestionToManage"
      v-if="editAdviceQuestionModal"
      @close="closeEditAdviceQuestionModal()"
      @submitQuestionModal="(data) => handleSubmitQuestionModal(data)"
    />
    <v-row v-if="!isInit">
      <v-col cols="auto">Chargement des questions...</v-col>
    </v-row>
    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<style scoped>
.icon-placeholder {
  width: 40px;
  height: 40px;
  background-color: grey;
  border-radius: 100%;
}
</style>
