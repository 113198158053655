<script setup>
// #region Imports
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Vuetify
const { smAndDown } = useDisplay()
// #endregion

// #region Props
const props = defineProps(['dark'])
// #endregion
</script>

<template>
  <v-container v-if="smAndDown" class="navigation" @click="router.go(-1)">
    <v-icon class="mt-2" :color="props.dark ? 'white' : 'grey darken-1'">
      mdi-chevron-left
    </v-icon>
  </v-container>
</template>
