<script setup>
// #region Imports
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
// import { useStore } from 'vuex'

import _ from 'lodash'

import InitLoader from '@/components/initLoader/InitLoader'

import authService from '@/services/authService'
// #endregion

// #region Router
const router = useRouter()
const route = useRoute()
// #endregion

// #region Store
// const store = useStore()
// #endregion

// #region Mounted
onMounted(async () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'sign_up',
    user_data: {
      // user_id: store.getters.userInfo.id,
      // email_address: store.getters.userInfo.email,
      // phone_number: store.getters.userInfo.phone_number,
      // address: {
      //   first_name: store.getters.userInfo.first_name,
      //   last_name: store.getters.userInfo.last_name
      // }
    }
  })
})
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Data
const isInit = ref(false)
// #endregion

// #region Methods
const validateAccount = _.debounce(() => {
  const credentials = {
    uid: route.params.uuid,
    token: route.params.token
  }
  authService
    .activateAccount(credentials)
    .then(() => {
      isInit.value = true
      // window.fbq('track', 'Lead')
      setTimeout(() => checkRoute({ name: 'Login' }), 5000)
    })
    .catch(() => {
      checkRoute({
        name: 'Login',
        params: {
          messages: [{ type: 'error', text: t('signup.activateAccount.error') }]
        }
      })
    })
}, 1000)
const checkRoute = (name) => {
  router.push(name)
}
// #endregion

// #region Created
validateAccount()
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0">
    <v-container v-if="isInit" class="content fill-height">
      <v-row justify="center" class="ma-1">
        <v-col cols="12" sm="12" align="center">
          <v-container class="main-title my-5">
            <v-img src="@/assets/img/Joana_Horizontal_v1.png"
              style="display: block; margin-left: auto; margin-right: auto">
            </v-img>
          </v-container>
        </v-col>
        <v-col cols="12" align="center">
          <v-icon dark color="success" class="mr-2"> mdi-check-circle </v-icon>
          <span>{{ $t('signup.activateAccount.accountActivated') }}</span>
        </v-col>
        <v-col cols="12" align="center">
          <span class="link-lowercase" @click="checkRoute({ name: 'Login' })">
            {{ $t('signup.activateAccount.noRedirection') }}
          </span>
        </v-col>
      </v-row>
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped lang="scss">
.content {
  max-width: 500px !important;
}

.main-title {
  font-size: 40px;
  line-height: 41px;
  letter-spacing: -0.02em;
}
</style>
