<script setup>
// #region Imports
import { reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import _ from 'lodash'

import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import authService from '@/services/authService'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Form Data
const formState = reactive({
  email: ''
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    email: { required, email }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Computed
const emailErrors = computed(() => {
  if (v$.value.email?.$dirty) {
    if (v$.value.email.required.$invalid) return t('message.error.required')
    else if (v$.value.email.email.$invalid) return t('message.error.email')
  }
  return []
})
// #endregion

// #region Methods
const save = _.debounce(() => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    const credentials = {
      email: formState.email
    }
    authService.resendActivationEmail(credentials).then(() => {
      checkRoute({ name: 'SignupRedirection' })
    })
  }
}, 1000)
const checkRoute = (name) => {
  router.push(name)
}
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0">
    <v-container class="content fill-height">
      <v-row justify="center" class="ma-1">
        <v-col cols="12" sm="12" align="center">
          <v-container class="main-title text-uppercase">{{
            $t('general.title')
          }}</v-container>
        </v-col>
        <v-col align="center" cols="12">
          <h2>{{ $t('signup.resendActivationEmail.title') }}</h2>
        </v-col>
        <v-col align="center" cols="12">
          <v-text-field
            v-model="formState.email"
            :error-messages="emailErrors"
            variant="outlined"
            color="primary"
            rounded
            type="email"
            @blur="v$.email.$touch()"
            @input="v$.email.$touch()"
          >
            <template v-slot:label>
              <span class="checkbox-label txt-gray txt-small">
                {{ $t('signup.resendActivationEmail.email') }}
                <span class="mandatory">*</span>
              </span>
            </template>
          </v-text-field>
        </v-col>
        <v-col align="center" cols="12" sm="12">
          <v-btn
            :disabled="v$.$invalid"
            class="confirm-button"
            color="primary"
            @click="save"
          >
            {{ $t('signup.resendActivationEmail.button') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
.content {
  max-width: 500px !important;
}
.main-title {
  font-size: 40px;
  line-height: 41px;
  letter-spacing: -0.02em;
}
.v-input {
  width: 300px;
}
.confirm-button {
  width: 300px;
  height: 50px !important;
  border-radius: 15px;
}
.txt-gray {
  color: #707070 !important;
}

.txt-small {
  font-size: 14px !important;
}

.txt-primary {
  color: #eb5c43 !important;
}
::v-deep .v-messages__message {
  text-align: left;
}
::v-deep .v-btn--disabled {
  background-color: #d8d8d8 !important;
}

::v-deep .v-btn__overlay {
  opacity: 0% !important;
}

::v-deep .v-btn__content {
  color: rgba(0, 0, 0, 0.26) !important;
  font-size: 14px !important;
}
::v-deep .v-btn:not(.v-btn--disabled) span {
  color: white !important;
}
</style>
