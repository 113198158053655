<script setup>
// #region Imports
import { ref } from 'vue'

import InitLoader from '@/components/initLoader/InitLoader'
import HistoryList from '@/components/historyList/HistoryList'
import NavigationZone from '@/components/navigationZone/NavigationZone'

import moment from 'moment'

import mealPlanService from '@/services/mealPlanService'
// #endregion

// #region Data
const isInit = ref(false)
const history = ref([])
const years = ref([])
// #endregion

// #region Methods
const getLabel = (month) => {
  return month.month[0].toUpperCase() + month.month.slice(1) + ' ' + month.year
}
// #endregion

// #region Created
mealPlanService.getHistory().then((response) => {
  for (const mealPlan of response.data.history.reverse()) {
    const month = moment(mealPlan.beginning_date).format('MMMM')
    const year = moment(mealPlan.beginning_date).format('YYYY')
    let index
    for (const i in history.value) {
      if (!years.value.includes(history.value[i].year)) {
        years.value.push(history.value[i].year)
      }
      if (history.value[i].month === month && history.value[i].year === year) {
        index = i
        break
      }
    }
    if (index) history.value[index].weeks.push(mealPlan)
    else history.value.push({ month: month, year: year, weeks: [mealPlan] })
  }
  isInit.value = true
})
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0 pa-md-8" fluid>
    <v-container v-if="isInit" class="pa-0" fluid>
      <NavigationZone />
      <v-container class="pa-4 mb-md-4" fluid>
        <h1>{{ $t('history.title') }}</h1>

        <p class="mt-7">
          Les options végétarienne ou carnivore
          <b>dépendent des paramètres activés lors de la publication du menu</b>
          et ne sont pas modifiables.
        </p>
        <p class="mt-7">
          Pour changer le nombre de personnes avec qui tu suis les menus, rends
          toi dans la rubrique profil (<v-icon size="16px">mdi-account</v-icon>)
        </p>
      </v-container>
      <div v-for="(year, z) in years" :key="z">
        <h2 class="text-center pa-6">Année {{ year }}</h2>
        <v-row no-gutters>
          <v-col cols="12" md="4" xl="3" v-for="(month, i) in history" :key="i">
            <v-container class="pa-4" fluid v-if="year == month.year">
              <v-card elevation="3">
                <template v-slot:title>
                  <v-card-title class="pa-0">
                    <h2>{{ getLabel(month) }}</h2>
                  </v-card-title>
                </template>

                <v-divider />
                <template v-slot:text>
                  <v-card-text class="pa-0">
                    <HistoryList :history="month.weeks" />
                  </v-card-text>
                </template>
              </v-card>
            </v-container>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped lang="scss">
p {
  color: #323232;
  font-size: 16px;
}
::v-deep .v-list-item-subtitle {
  opacity: 100% !important;
}
</style>
