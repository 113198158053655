<script setup>
// #region Imports
import { ref, computed, watch } from 'vue'

import EditIngredientModal from '@/components/admin/ingredients/EditIngredientModal'
import ConfirmDialog from '@/components/admin/miscellaneous/confirmDialog/ConfirmDialog'
import InitLoader from '@/components/initLoader/InitLoader'

import _ from 'lodash'

import adminService from '@/services/adminService'
// #endregion

// #region Data
const confirm = ref(null)

const isInit = ref(false)
const ingredients = ref([])
const page = ref(1)
const totalItems = ref(0)
const itemsPerPage = ref(10)
const search = ref('')
const editIngredientModal = ref(false)
const ingredientToEdit = ref({})
// #endregion

// #region Computed
const pageCount = computed(() => {
  const totalPages = ~~(totalItems.value / itemsPerPage.value)
  const modulo = totalItems.value % itemsPerPage.value
  return modulo === 0 ? totalPages : totalPages + 1
})
const headers = computed(() => {
  return [
    {
      title: 'Image',
      value: 'pictures',
      sortable: false,
      width: '70px',
      fixed: true
    },
    { title: 'Nom', value: 'name' },
    { title: 'Pluriel', value: 'plural_name' },
    { title: 'Catégorie', value: 'category.name' },
    {
      title: 'Actions',
      value: 'actions',
      sortable: false,
      width: '150px',
      fixed: true
    }
  ]
})
// #endregion

// #region Methods
const editIngredient = (item) => {
  ingredientToEdit.value = item ? { ...item } : {}
  editIngredientModal.value = true
}
const deleteIngredient = (id) => {
  confirm.value
    .open(
      "Supprimer l'ingrédient",
      'Souhaitez-vous vraiment supprimer cet ingrédient ?',
      { color: 'primary' }
    )
    .then((confirm) => {
      if (confirm) {
        adminService.deleteIngredient(id).then(() => {
          reload()
        })
      }
    })
}
const reload = _.debounce(() => {
  editIngredientModal.value = false
  adminService.getIngredients(page.value, search.value).then((response) => {
    totalItems.value = response.data.count
    ingredients.value = response.data.results
  })
}, 500)
const getPictureUrl = (pictures) => {
  let url = ''
  if (pictures.length > 0) {
    url = process.env.VUE_APP_API_URL + pictures[0].uri
  }
  return url
}
// #endregion

// #region Watch
watch(page, () => {
  reload()
})
watch(search, () => {
  page.value = 1
  reload()
})
// #endregion

// #region Created
adminService
  .getIngredients()
  .then((response) => {
    totalItems.value = response.data.count
    ingredients.value = response.data.results
    isInit.value = true
  })
  .catch(() => {})
// #endregion
</script>

<template>
  <v-container class="admin-wrapper-container" fluid>
    <v-container v-if="isInit" class="pa-0" fluid>
      <h1 class="mb-3">Ingrédients</h1>
      <v-data-table
        :headers="headers"
        :items="ingredients"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        hover
        density="compact"
        s
        class="py-4"
        hide-default-footer
      >
        <template v-slot:bottom></template>
        <template v-slot:top>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="5" class="py-0" align="left">
              <h2 class="ml-5">Liste des ingrédients</h2>
            </v-col>
            <v-col cols="8" sm="6" class="py-0" align="right">
              <v-text-field
                v-model="search"
                variant="outlined"
                rounded
                color="primary"
                label="Rechercher"
                class="mx-4 admin-search-bar"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="1" class="py-0" align="center">
              <v-btn
                style="opacity: 75%"
                icon
                variant="text"
                color="primary"
                @click="editIngredient()"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.pictures`]="{ item }">
          <v-img
            :width="70"
            :height="70"
            class="admin-list-picture"
            :src="getPictureUrl(item.pictures)"
          ></v-img>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            style="opacity: 75%"
            icon
            variant="text"
            @click="editIngredient(item)"
          >
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            style="opacity: 75%"
            icon
            variant="text"
            @click="deleteIngredient(item.id)"
          >
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <div class="text-center pt-2 mb-8">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="10"
          active-color="primary"
          density="comfortable"
          variant="elevated"
          opacity="100"
        ></v-pagination>
      </div>
      <EditIngredientModal
        v-if="editIngredientModal"
        :ingredientToEdit="ingredientToEdit"
        @close="reload()"
      />
      <ConfirmDialog ref="confirm" />
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped>
::v-deep .v-data-table-header__content {
  font-weight: bold;
}
::v-deep .v-img__img--contain {
  object-fit: cover !important;
}
</style>
