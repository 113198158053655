export const STRIPE_STATUS = [
  {
    id: 'active',
    color: 'success',
    icon: 'mdi-check',
    desc: 'Abonnement actif'
  },
  {
    id: 'canceled',
    color: 'error',
    icon: 'mdi-cancel',
    desc: 'Abonnement annulé'
  },
  {
    id: 'incomplete_expired',
    color: 'error',
    icon: 'mdi-timer-off',
    desc: 'Tentatives de paiement expirées'
  },
  {
    id: 'incomplete',
    color: 'pending',
    icon: 'mdi-puzzle-remove',
    desc: 'Tentative de paiement initiale échouée'
  },
  {
    id: 'past_due',
    color: 'pending',
    icon: 'mdi-timer-sand',
    desc: 'Tentative de paiement en cours'
  },
  {
    id: 'trialing',
    color: 'pending',
    icon: 'mdi-cctv',
    desc: "Abonnement en période d'essai"
  },
  {
    id: 'unpaid',
    color: 'error',
    icon: 'mdi-currency-eur-off',
    desc: 'Impayé'
  }
]
