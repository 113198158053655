<script setup>
// #region Imports
import { useDisplay } from 'vuetify'
// #endregion

// #region Vuetify
const { smAndDown, mdAndUp } = useDisplay()
// #endregion
</script>

<template>
  <div>
    <div v-if="smAndDown" class="iphone-advertising mb-10">
      Ce guide d’installation fonctionne uniquement si tu as ouvert cette page
      via Safari. Si ce n’est pas le cas, tu peux passer cette étape. Tu pourras
      retrouver le tutoriel sur ton espace client ensuite
      <img :src="require('@/assets/icons/warning.png')" alt="" />
    </div>
    <ul>
      <li v-if="mdAndUp" class="mt-2 mb-2">
        Ouvre un navigateur et rejoins le site
        <a href="https://app.joanaetvous.com">https://app.joanaetvous.com</a>
      </li>
      <li class="mt-2 mb-2">
        Clique sur l’onglet «Télécharger» en bas de page
        <v-row class="mt-2 mb-2">
          <v-col
            ><v-img
              width="300"
              :src="require('@/assets/img/apple1.png')"
            ></v-img
          ></v-col>
        </v-row>
      </li>
      <li class="mt-2 mb-2">
        Sélectionne «Sur l’écran d’accueil»
        <v-row class="mt-2 mb-2">
          <v-col
            ><v-img
              width="300"
              height="46"
              :src="require('@/assets/img/apple2.png')"
            ></v-img
          ></v-col>
        </v-row>
      </li>
      <li class="mt-2">
        La pastille Joana&Vous vient d’être ajoutée sur ton téléphone
        <v-row align-content="center">
          <v-col align="center">
            <v-img width="50" class="mt-2" src="@/assets/logo.png"></v-img>
          </v-col>
        </v-row>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
p,
ul {
  color: #323232;
}

h2 {
  font-weight: 400 !important;
}

h3 {
  font-size: 18px;
  font-weight: bold;
}

span {
  font-size: 14px !important;
  color: #707070;
}

.os-btn {
  span: {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 17px;
  }
}

.iphone-advertising {
  position: relative;
  background-color: #fec46d;
  color: white;
  border-radius: 7px;
  padding: 10px;
  font-size: 14px;
  line-height: 17px;

  img {
    width: 40px;
    top: -30px;
    height: 40px;
    position: absolute;
    right: 20px;
  }
}
</style>
