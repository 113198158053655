import axios from 'axios'

export default {
  getConfig() {
    return {
      headers: {
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('tokens'))?.access
      }
    }
  },
  getUser() {
    return axios.get(
      process.env.VUE_APP_API_URL + '/auth/users/me/',
      this.getConfig()
    )
  },
  updateUser(user) {
    return axios.put(
      process.env.VUE_APP_API_URL + '/auth/users/me/',
      user,
      this.getConfig()
    )
  },
  getSubscriptionStatus() {
    return axios.get(
      process.env.VUE_APP_API_URL + '/subscription-status/',
      this.getConfig()
    )
  },
  validateTAC() {
    return axios.post(
      process.env.VUE_APP_API_URL + '/terms-and-agreement-entry/',
      {},
      this.getConfig()
    )
  },
  getFav() {
    return axios.get(
      process.env.VUE_APP_API_URL + '/favorites-dishes/',
      this.getConfig()
    )
  },
  addFav(dishId) {
    return axios.post(
      process.env.VUE_APP_API_URL + '/favorites-dishes/',
      { uuid: dishId },
      this.getConfig()
    )
  },
  deleteFav(dishId) {
    return axios.delete(
      process.env.VUE_APP_API_URL + '/favorites-dishes/' + dishId + '/',
      this.getConfig()
    )
  },
  getMyDishes(search, page) {
    const config = this.getConfig()
    let params = {}
    if (search) {
      params = { search: search, ...params }
    }
    if (page) {
      params = { page: page, ...params }
    }
    config.params = params
    return axios
      .get(process.env.VUE_APP_API_URL + '/my_dishes/', config)
      .then((response) => {
        return response.data
      })
  }
}
