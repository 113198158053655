export const STRIPE_PLANS = [
  {
    id: 'plan_GinA744NoHzdyC',
    price: 24.95,
    months: 3,
    description: 'La formule idéale pour tester le concept et voir les premiers résultats. Accès au programme illimité avec un engagement de 3 mois.'
  },
  {
    id: 'plan_GinBCwOUukgnXQ',
    price: 19.95,
    months: 6,
    description: 'La formule parfaite pour ancrer les bonnes habitudes et perdre du poids durablement. Accès au programme illimité avec un engagement de 6 mois.'
  },
  {
    id: 'plan_GinBU5dYf26kKa',
    price: 14.95,
    months: 12,
    description: 'La formule divine pour avoir des idées repas et recettes de chaque saison. Accès au programme illimité avec un engagement de 12 mois.'
  }
]

export const STRIPE_STATUS = [
  {
    id: 'active',
    color: 'success',
    icon: 'mdi-check',
    desc: 'Abonnement actif'
  },
  {
    id: 'canceled',
    color: 'error',
    icon: 'mdi-cancel',
    desc: 'Abonnement annulé'
  },
  {
    id: 'incomplete_expired',
    color: 'error',
    icon: 'mdi-timer-off',
    desc: 'Tentatives de paiement expirées'
  },
  {
    id: 'incomplete',
    color: 'pending',
    icon: 'mdi-puzzle-remove',
    desc: 'Tentative de paiement initiale échouée'
  },
  {
    id: 'past_due',
    color: 'pending',
    icon: 'mdi-timer-sand',
    desc: 'Tentative de paiement en cours'
  },
  {
    id: 'trialing',
    color: 'pending',
    icon: 'mdi-cctv',
    desc: 'Abonnement en période d\'essai'
  },
  {
    id: 'unpaid',
    color: 'error',
    icon: 'mdi-currency-eur-off',
    desc: 'Impayé'
  }
]
