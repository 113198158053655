<script setup>
// #region Imports
import { reactive, computed, defineProps, defineEmits, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import moment from 'moment'

// import 'codemirror/lib/codemirror.css'
// import '@toast-ui/editor/dist/toastui-editor.css'
// import { Editor } from '@toast-ui/vue-editor'

import RichTextEditor from '@/components/RichTextEditor/RichTextEditor'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import adminService from '@/services/adminService'
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Props
const props = defineProps(['tacToManage'])
// #endregion

// #region Emits
const emit = defineEmits(['close'])
// #endregion

// #region Form Data
const formState = reactive({
  tac: {}
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    tac: {
      publication_date: { required },
      body: { required }
    }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Data
// const toastuiEditor = ref(null)

const dialog = ref(true)
const pickerPublicationDate = ref(false)
// const editorOptions = ref({
//   language: 'fr-FR',
//   hideModeSwitch: true,
//   usageStatistics: false,
//   toolbarItems: [
//     'heading',
//     'bold',
//     'italic',
//     'strike',
//     'divider',
//     'hr',
//     'quote',
//     'divider',
//     'ul',
//     'ol',
//     'link',
//     'table'
//   ]
// })
// #endregion

// #region Computed
const formattedPublicationDate = computed(() => {
  return moment(formState.tac.publication_date).format('DD/MM/YYYY')
})
const publicationDateErrors = computed(() => {
  if (
    v$.value.tac.publication_date.$dirty &&
    v$.value.tac.publication_date.required.$invalid
  ) {
    return t('message.error.required')
  }
  return []
})
// #endregion

// #region Methods
const changeTAC = (e) => {
  formState.tac.body = e
}
const saveTAC = () => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    if (formState.tac.id) {
      formState.tac.publication_date = moment(
        formState.tac.publication_date
      ).format('YYYY-MM-DD')
      adminService.updateTAC(formState.tac).then(() => {
        emit('close')
      })
    } else {
      formState.tac.publication_date = moment(
        formState.tac.publication_date
      ).format('YYYY-MM-DD')
      adminService.createTAC(formState.tac).then(() => {
        emit('close')
      })
    }
  }
}
// #endregion

// #region Created
formState.tac = props.tacToManage
// #endregion
</script>

<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2 bg-grey-title">
        Editer conditions générales
      </v-card-title>
      <v-form @submit.prevent="saveTAC">
        <v-card-text>
          <v-row>
            <v-col cols="12" align="center">
              <v-menu
                ref="menuPublicationDate"
                v-model="pickerPublicationDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ props: menu }">
                  <v-text-field
                    v-bind="menu"
                    v-model="formattedPublicationDate"
                    :error-messages="publicationDateErrors"
                    hide-details
                    readonly
                    variant="outlined"
                    rounded
                    v-on="on"
                  >
                    <template v-slot:label>
                      <span class="checkbox-label">
                        Date de publication
                        <span class="mandatory">*</span>
                      </span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  class="datepicker"
                  color="primary"
                  ref="picker"
                  v-model="formState.tac.publication_date"
                  @input="v$.tac.publication_date.$touch()"
                  @blur="v$.tac.publication_date.$touch()"
                  :first-day-of-week="1"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <RichTextEditor
                :content="formState.tac.body"
                @change="changeTAC($event)"
              />
              <!-- <Editor
                :initialValue="formState.tac.body"
                :options="editorOptions"
                ref="toastuiEditor"
                class="mb-3"
                initialEditType="wysiwyg"
                previewStyle="vertical"
                @change="changeTAC()"
              /> -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('close')"> Fermer </v-btn>
          <v-btn
            aria-label="Valider"
            color="primary"
            type="submit"
            text
            :disabled="v$.$invalid"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.bg-grey-title {
  background-color: #e0e0e0;
}
</style>
