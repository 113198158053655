<script setup>
// #region Imports
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useDisplay } from 'vuetify'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import HistoryList from '@/components/historyList/HistoryList'
import ShoppingListTables from '@/components/shoppingList/ShoppingListTables'
import WeekdayCard from '@/components/weekdayCard/WeekdayCard'
import InitLoader from '@/components/initLoader/InitLoader'
import RandomAdvicesCard from '@/components/randomAdvicesCard/RandomAdvicesCard'
import NavigationZone from '@/components/navigationZone/NavigationZone'
import ShoppingListNotes from '@/components/shoppingList/ShoppingListNotes'

import moment from 'moment'

import userService from '@/services/userService'
import mealPlanService from '@/services/mealPlanService'
import joanaAdviceCategoryService from '@/services/adminJoanaAdvice/joanaAdviceCategoryService'
// #endregion

// #region Router
const router = useRouter()
const route = useRoute()
// #endregion

// #region Vuetify
const { smAndDown, mdAndUp } = useDisplay()
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Store
const store = useStore()
// #endregion

// #region Store Data
const nbOfPersons = computed(() => store.getters.nbOfPersons)
// #endregion

// #region Data
const isInit = ref(false)
const isShoppingListInit = ref(false)
const mealPlan = ref({})
const history = ref([])
const advices = ref([])
const subscriptionStatus = ref(null)
const dialogReminder = ref(false)
const isLoadingFullMealPlan = ref(false)
const randomAdvice = ref(null)
// #endregion

// #region Methods
const getRandomInt = (max) => {
  return Math.floor(Math.random() * max)
}
const redirectToCheckout = () => {
  checkRoute({ name: 'Checkout' })
}
const checkRoute = (name) => {
  router.push(name)
}
const getDay = (i) => {
  return moment(mealPlan.value.beginning_date).add(i, 'd')
}
const getpdf = () => {
  if (mealPlan.value.pdf) {
    window
      .open(process.env.VUE_APP_API_URL + mealPlan.value.pdf, '_blank')
      .focus()
  }
}
const subscriptionReminder = () => {
  userService
    .getSubscriptionStatus()
    .then((response) => {
      subscriptionStatus.value = response.data.subscription_status
    })
    .catch(() => {})
    .finally(() => {
      if (
        subscriptionStatus.value !== 'active' &&
        subscriptionStatus.value !== 'past_due'
      ) {
        let reminder = JSON.parse(localStorage.getItem('reminder'))
        if (!reminder) {
          reminder = 1
          dialogReminder.value = true
        } else {
          const secondReminder = moment(reminder.date).add(7, 'd')
          const thirdReminder = moment(reminder.date).add(10, 'd')
          const fourthReminder = moment(reminder.date).add(15, 'd')
          if (
            (reminder.type === 1 && moment().isAfter(secondReminder)) ||
            (reminder.type === 2 && moment().isAfter(thirdReminder)) ||
            (reminder.type === 3 && moment().isAfter(fourthReminder))
          ) {
            reminder = reminder.type + 1
            dialogReminder.value = true
          }
        }
      }
    })
}
const getLabel = (date) => {
  const firstDayMonth = moment(date).format('MMMM')
  const firstDay = moment(date).format('DD')
  const lastDayMonth = moment(date).add(6, 'd').format('MMMM')
  const lastDay = moment(date).add(6, 'd').format('DD')
  if (firstDayMonth !== lastDayMonth) {
    return t('history.periodDifferentMonth', {
      startDay: firstDay,
      startMonth: firstDayMonth,
      endDay: lastDay,
      endMonth: lastDayMonth
    })
  } else {
    return t('history.periodSameMonth', {
      start: firstDay,
      end: lastDay,
      month: firstDayMonth
    })
  }
}
// #endregion

// #region Created
mealPlanService
  .getMealPlan(route.params.mealPlanId)
  .then((response) => {
    mealPlan.value = response.data
    mealPlanService
      .getHistory()
      .then((response) => {
        history.value = response.data.history.reverse().slice(0, 4)
        subscriptionReminder()
      })
      .catch(() => {})
    mealPlanService.getAdvices().then((response) => {
      const flattenArray = []
      for (const section of response) {
        for (const advice of section.adv) {
          flattenArray.push(advice)
        }
      }
      advices.value = flattenArray.sort(() => Math.random() - 0.2).slice(0, 3)
    })
  })
  .then(() => {
    return joanaAdviceCategoryService
      .getAdvicePublicCategories()
      .then((joanaAdvicesCategories) => {
        let advice = null
        const advices = []
        if (joanaAdvicesCategories.length > 0) {
          for (const cat of joanaAdvicesCategories) {
            for (const subCat of cat.subcategories) {
              for (const advice of subCat.advices) {
                if (advice) {
                  advices.push(advice)
                }
              }
            }
          }
        }
        advice = advices[getRandomInt(advices.length - 1)]
        return advice
      })
  })
  .then((advice) => {
    randomAdvice.value = advice
    isInit.value = true
  })
  .catch((error) => {
    if (error.response?.status === 404) {
      checkRoute({ name: 'MealPlan' })
    }
  })
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0 x-hidden" fluid>
    <v-container v-if="isInit && smAndDown" class="pa-0">
      <v-row justify="center">
        <v-col cols="2" class="">
          <NavigationZone />
        </v-col>
        <v-col cols="8" class="justify-center" align="center">
          <v-btn
            v-if="dialogReminder"
            @click="redirectToCheckout"
            text
            class="link boutonPrincipales mt-4"
          >
            Je m'abonne au programme
          </v-btn>
        </v-col>
        <v-col cols="2" align="right">
          <!-- <v-btn
            elevation="0"
            value="howTo"
            @click="checkRoute({ name: 'HowTo' })"
            align="right"
            style="padding-top: 25px"
            ><img height="32" width="32" src="@/assets/icons/Tuto_32.png"
          /></v-btn> -->
        </v-col>
      </v-row>
      <v-container class="pa-4" fluid>
        <h1>{{ $t('mealPlan.title') }}</h1>
        <label class="label-title">{{
          getLabel(mealPlan.beginning_date)
        }}</label>
      </v-container>

      <v-container class="py-4 px-0" fluid>
        <v-slide-group class="daysWrapper">
          <v-slide-item v-for="(weekday, i) in mealPlan.weekdays" :key="i">
            <WeekdayCard
              :weekdayId="weekday.uuid"
              :weekdayDate="getDay(weekday.order)"
            />
          </v-slide-item>
        </v-slide-group>
      </v-container>

      <v-container class="pa-4 pb-4" fluid>
        <v-row>
          <v-col align="center">
            <v-btn
              v-if="mealPlan.pdf !== undefined"
              text
              class="link boutonPrincipales"
              @click="getpdf"
            >
              <div v-if="isLoadingFullMealPlan">
                {{ $t('mealPlan.downloading') }}
              </div>
              <div v-else>{{ $t('mealPlan.download') }}</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pa-4" fluid>
        <v-card
          style="overflow: visible"
          elevation="3"
          class="shoppingListCard"
          @click="
            checkRoute({
              name: 'ShoppingList',
              params: { mealPlanId: mealPlan.uuid }
            })
          "
        >
          <v-card-text class="pa-0" style="height: 100%">
            <v-row no-gutters style="height: 100%">
              <v-col cols="7">
                <v-container class="fill-height pa-4">
                  <v-row no-gutters justify="center" align="center">
                    <v-col cols="12" align="center">
                      <h2>{{ $t('mealPlan.shoppingList') }}</h2>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="5">
                <v-container class="card-corner fill-height pa-3">
                  <img :src="require('@/assets/img/groceries.png')" />
                </v-container>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>

      <v-container class="pa-4 mt-5" fluid>
        <v-card elevation="3">
          <v-card-title class="space-between">
            <h2>{{ $t('mealPlan.history.myHistory') }}</h2>

            <span
              class="link link-history"
              @click="checkRoute({ name: 'History' })"
            >
              {{ $t('mealPlan.history.historyLink') }}
            </span>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <HistoryList
              :history="history"
              :currentMealPlanId="mealPlan.uuid"
            />
            <v-divider />
            <v-list>
              <v-list-item v-if="dialogReminder">
                <template v-slot:prepend>
                  <v-list-item-icon>
                    <img
                      style="width: 30px; height: 30px"
                      :src="require('@/assets/icons/date-limite.png')"
                    />
                  </v-list-item-icon>
                </template>
                <template v-slot:title>
                  <v-list-item-title class="pl-3">
                    <a
                      @click="redirectToCheckout"
                      class="color-primary"
                      style="
                        text-decoration: underline;
                        font-weight: bold;
                        white-space: normal;
                      "
                      >Je m'abonne pour recevoir les prochaines<br />
                      semaines de menus</a
                    ></v-list-item-title
                  >
                </template>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-container>
      <!-- <v-container fluid>
        <v-row justify="center" v-if="dialogReminder">
          <p class="text-center mb-0">
            <a
              @click="redirectToCheckout"
              style="text-decoration: underline; font-weight: bold"
              >Je m'abonne pour recevoir les prochaines<br />
              semaines de menus</a
            >
          </p>
        </v-row>
      </v-container>
      <div v-if="!dialogReminder" style="height: 16px"></div> -->
      <v-container class="pa-4 pb-8" fluid>
        <RandomAdvicesCard
          v-if="randomAdvice"
          :advice="randomAdvice"
        ></RandomAdvicesCard>
      </v-container>
    </v-container>
    <v-container
      v-if="mdAndUp"
      v-show="isInit && isShoppingListInit"
      fluid
      class="pa-8"
    >
      <v-container class="mb-5 pa-4" fluid>
        <v-row no-gutters>
          <v-col md="4" v-if="dialogReminder">
            <h1>{{ $t('mealPlan.title') }}</h1>
            <label class="label-title">{{
              getLabel(mealPlan.beginning_date)
            }}</label>
          </v-col>
          <v-col md="6" v-else>
            <h1>{{ $t('mealPlan.title') }}</h1>
            <label class="label-title">{{
              getLabel(mealPlan.beginning_date)
            }}</label>
          </v-col>
          <v-col align="center" class="pt-8" md="4" v-if="dialogReminder">
            <v-btn
              @click="redirectToCheckout"
              text
              class="link boutonPrincipales"
              style="margin-left: auto; margin-right: auto"
            >
              Je m'abonne au programme
            </v-btn>
          </v-col>
          <v-col md="4" align="right" class="pt-8" v-if="dialogReminder">
            <v-btn
              v-if="mealPlan.pdf !== undefined"
              text
              class="link boutonPrincipales"
              @click="getpdf"
            >
              <div v-if="isLoadingFullMealPlan">
                {{ $t('mealPlan.downloading') }}
              </div>
              <div v-else>{{ $t('mealPlan.download') }}</div>
            </v-btn>
          </v-col>
          <v-col md="6" align="right" class="pt-8" v-else>
            <v-btn
              v-if="mealPlan.pdf !== undefined"
              text
              class="link boutonPrincipales"
              @click="getpdf"
            >
              <div v-if="isLoadingFullMealPlan">
                {{ $t('mealPlan.downloading') }}
              </div>
              <div v-else>{{ $t('mealPlan.download') }}</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-row class="mx-0">
        <v-col sm="4" class="pa-0">
          <p class="mt-4 mb-0 mx-3">
            La liste de courses ci-dessous est prévue pour :
          </p>
          <span class="persons mx-3"
            >{{ nbOfPersons }}
            {{ nbOfPersons > 1 ? 'personnes' : 'personne' }}</span
          >
          <p class="mt-3 mx-3">
            Tu peux modifier ce chiffre dans la rubrique compte.
          </p>

          <v-row v-if="mdAndUp">
            <v-col class="px-4">
              <ShoppingListNotes></ShoppingListNotes>
            </v-col>
          </v-row>

          <ShoppingListTables
            v-if="mealPlan.uuid"
            :mealPlanId="mealPlan.uuid"
            :fullPage="false"
            class="pa-0"
            @isShoppingListInit="isShoppingListInit = true"
          />
        </v-col>
        <v-col sm="4" class="px-5">
          <v-row>
            <v-col
              sm="6"
              class="px-0 pt-0 pb-5"
              v-for="(weekday, i) in mealPlan.weekdays"
              :key="i"
              :align="i % 2 === 0 ? 'center' : 'center'"
            >
              <WeekdayCard
                :weekdayId="weekday.uuid"
                :weekdayDate="getDay(weekday.order)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="4">
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-card elevation="3" class="mt-0">
                <v-card-title class="space-between">
                  <h2>{{ $t('mealPlan.history.myHistory') }}</h2>

                  <span
                    class="link link-history"
                    @click="checkRoute({ name: 'History' })"
                  >
                    {{ $t('mealPlan.history.historyLink') }}
                  </span>
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-0">
                  <HistoryList
                    :history="history"
                    :currentMealPlanId="mealPlan.uuid"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <RandomAdvicesCard
                v-if="randomAdvice"
                :advice="randomAdvice"
              ></RandomAdvicesCard>
            </v-col>
            <v-col cols="12" justify="center" v-if="dialogReminder">
              <v-row justify="center">
                <p
                  class="text-center"
                  style="width: 380px; margin: 0px; line-height: 1.3"
                >
                  <a
                    @click="redirectToCheckout"
                    class="text-center color-primary"
                    style="font-weight: bold; text-decoration: underline"
                    >Je m'abonne pour recevoir les prochaines<br />
                    semaines de menus</a
                  >
                </p>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <InitLoader
      v-if="
        (smAndDown && !isInit) || (mdAndUp && (!isInit || !isShoppingListInit))
      "
    />

    <!-- <SubscriptionReminder
      v-if="dialogReminder"
      :dialogReminder="dialogReminder"
      :reminder="reminder"
      @close="dialogReminder = false"
    /> -->
  </v-container>
</template>

<style scoped lang="scss">
::v-deep.day {
  margin-right: 1px !important;
}

.v-card-title {
  padding: 16px !important;
}
.shoppingListCard {
  height: 100px;
  max-width: 400px;
}

.shoppingListCard .v-card__text,
.shoppingListCard .row {
  height: 100%;
}

::v-deep .card-corner > img {
  height: 140px;
  width: 140px;
  top: -40px;
  right: -15px;
  position: absolute;
}

.daysWrapper,
::v-deep .v-slide-group__wrapper {
  height: 185px !important;
}

.persons {
  color: #eb5c43;
  font-weight: bold;
}
.color-primary {
  color: #eb5c43;
}
.link-history {
  color: #eb5c43;
  font-weight: bold;
  font-size: 13px !important;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.x-hidden {
  overflow-x: hidden;
}
::v-deep .v-list-item-subtitle {
  opacity: 100% !important;
}
</style>
