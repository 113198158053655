<script setup>
// #region Imports
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'
import { useStore } from 'vuex'

import BottomMenu from '@/components/bottomMenu/BottomMenu.vue'
import TopMenu from '@/components/topMenu/TopMenu.vue'
import NavigationDrawer from '@/components/admin/miscellaneous/navigationDrawer/NavigationDrawer'

import userService from '@/services/userService'
import authService from '@/services/authService'
// #endregion

// #region Router
const route = useRoute()
const router = useRouter()
router.beforeEach((to, from) => {
  if (store.getters.userInfo) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'info',
      user_data: {
        member: store.getters.userInfo.is_active,
        user_id: store.getters.userInfo.id,
        email_address: store.getters.userInfo.email,
        phone_number: store.getters.userInfo.phone_number,
        address: {
          first_name: store.getters.userInfo.first_name,
          last_name: store.getters.userInfo.last_name
        }
      }
    })
  }
})
// #endregion

// #region Vuetify
const { smAndDown, mdAndUp } = useDisplay()
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Meta
useMeta({
  title: t('general.title'),
  meta: [{ name: 'description', content: t('general.description') }]
})
// #endregion

// #region Store
const store = useStore()
// #endregion

// #region Computed
const showMenu = computed(() => {
  const userComponents = [
    'MealPlan',
    'Weekday',
    'Dish',
    'ShoppingList',
    'PageNotFound',
    'History',
    'Fav',
    'Advices',
    'Account',
    'AccountInformationsView',
    'Checkout',
    'HowTo',
    'JoanaAdvices',
    'InstallationInstructions'
  ]
  return userComponents.includes(route.name)
})
const showAdminMenu = computed(() => {
  const adminComponents = [
    'AdminHome',
    'MealPlansList',
    'DishesList',
    'IngredientsList',
    'ProfilesList',
    'PicturesList',
    'TagsList',
    'UnitsOfMeasureList',
    'IngredientCategoriesList',
    'TACList',
    'JoanaAdvicesCategories',
    'JoanaAdvicesSubCategories'
  ]
  return adminComponents.includes(route.name)
})
// #endregion

// #region Created
authService
  .checkTokens()
  .then((_) =>
    userService.getUser().then((data) => {
      const user = data.data
      if (user) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'log_in',
          user_data: {
            user_id: user.id
          }
        })
      }
      store.dispatch('setUser', user)
      if (user.has_seen_onboarding === false) {
        checkRoute({ name: 'Onboarding' })
      }
    })
  )
  .catch((_) => null)
// #endregion

// #region Methods
const checkRoute = (name) => {
  router.push(name)
}
// #endregion
</script>

<template>
  <v-app>
    <v-main>
      <v-container class="pa-0 overflow-hidden" fluid>
        <NavigationDrawer v-if="showAdminMenu" />
        <BottomMenu v-if="showMenu && smAndDown" />
        <TopMenu v-if="showMenu && mdAndUp" />
        <v-container class="main-container pa-0" fluid>
          <router-view :key="route.fullPath"></router-view>
        </v-container>
      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="scss">
@import './style/main.scss';
@import './style/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');

@font-face {
  font-family: 'made-gentle';
  src: url('assets/fonts/MADE_Gentle.otf');
}

@font-face {
  font-family: 'barlow_regular';
  src: url('assets/fonts/Barlow-Regular.ttf');
}

@font-face {
  font-family: 'barlow_SemiBold';
  src: url('assets/fonts/Barlow-SemiBold.ttf');
}

@font-face {
  font-family: 'Barlow-Bold';
  src: url('assets/fonts/Barlow-Bold.ttf');
}
</style>
