<script setup>
// #region Imports
import { ref, defineProps } from 'vue'
import { useRouter } from 'vue-router'

import Markdown from 'vue3-markdown-it'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Props
const props = defineProps(['advice'])
// #endregion

// #region Data
const randomAdvice = ref(null)
const init = ref(false)
// #endregion

// #region Created
randomAdvice.value = props.advice
init.value = true
// #endregion

// #region Methods
const getPictureUrl = (uri) => {
  if (uri) {
    return process.env.VUE_APP_API_URL + '/' + uri
  }
}
const checkRoute = (name) => {
  router.push(name)
}
// #endregion
</script>

<template>
  <v-card v-if="init" elevation="3" class="">
    <v-card-title class="space-between">
      <h2>{{ $t('mealPlan.advices.title') }}</h2>

      <span class="link" @click="checkRoute({ name: 'JoanaAdvices' })">
        {{ $t('mealPlan.advices.advicesLink') }}
      </span>
    </v-card-title>
    <v-card-text class="p-0">
      <v-row>
        <v-col v-if="randomAdvice.icon" cols="auto">
          <v-img
            width="21"
            height="21"
            aspect-ration="1"
            :src="getPictureUrl(randomAdvice.icon)"
          ></v-img>
        </v-col>
        <v-col class="pl-3 pb-0">
          <span class="advice-title m-0">{{ randomAdvice.question }}</span>
        </v-col>
        <v-col cols="12">
          <Markdown
            v-if="randomAdvice.response"
            class="md-body advice-response"
            :source="randomAdvice.response"
          />
        </v-col>
        <v-col v-if="randomAdvice.photo">
          <v-img
            v-if="randomAdvice.photo"
            :src="getPictureUrl(advice.photo)"
          ></v-img>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
h2 {
  white-space: normal !important;
  text-align: center;
}
::v-deep .carousel-text {
  cursor: grab !important;
}

$font-size: 14px;
$line-height: 1.4;
$lines-to-show: 5;

// ::v-deep .markdown-body {
//   display: block;
//   display: -webkit-box;
//   max-width: 100%;
//   height: $font-size * $line-height * $lines-to-show;
//   margin: 0 auto;
//   font-size: $font-size;
//   line-height: $line-height;
//   -webkit-line-clamp: $lines-to-show;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
.md-body {
  list-style-position: inside !important;
}
.advice-title {
  color: #eb5c43;
  font-size: 16px;
  font-family: 'Barlow-Bold';
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.link {
  color: #eb5c43 !important;
  font-size: 13px !important;
}
</style>
