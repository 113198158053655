<script setup>
// #region Imports
import { useRouter } from 'vue-router'
// import { useStore } from 'vuex'
import { onMounted } from 'vue'

// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Methods
const checkRoute = (name) => {
  router.push(name)
}
// #endregion

// #region Store
// const store = useStore()
// #endregion

// #region Mounted
onMounted(async () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'free_trial',
    user_data: {
      // user_id: store.getters.userInfo.id,
      // email_address: store.getters.userInfo.email,
      // phone_number: store.getters.userInfo.phone_number,
      // address: {
      //   first_name: store.getters.userInfo.first_name,
      //   last_name: store.getters.userInfo.last_name
      // }
    }
  })
})
// #endregion

</script>

<template>
  <v-container class="wrapper-container pa-0">
    <v-container class="content fill-height">
      <v-row justify="center">
        <v-col cols="12" sm="12" align="center">
          <v-container class="main-title my-5">
            <v-img src="@/assets/img/Joana_Horizontal_v1.png"
              style="display: block; margin-left: auto; margin-right: auto">
            </v-img>
          </v-container>
        </v-col>
        <v-col cols="12" align="center">
          <h1>
            <v-icon dark color="success" class="mr-2">
              mdi-check-circle
            </v-icon>
            {{ $t('signup.signupRedirection.title') }}
          </h1>
        </v-col>
        <v-col cols="12" align="center">
          <span class="txt-small txt-gray">{{
              $t('signup.signupRedirection.emailSent')
            }}</span>
        </v-col>
        <v-col cols="12" align="center">
          <span class="link-lowercase txt-small txt-primary" @click="checkRoute({ name: 'ResendActivationEmail' })">{{
              $t('signup.signupRedirection.emailNotReceived') }}</span>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped lang="scss">
.content {
  max-width: 500px !important;
}

.main-title {
  font-size: 40px;
  line-height: 41px;
  letter-spacing: -0.02em;
}

.txt-gray {
  color: #707070 !important;
}

.txt-small {
  font-size: 14px !important;
}

.txt-primary {
  color: #eb5c43 !important;
}
</style>
