<script setup>
// #region Imports
import { reactive, computed, defineProps, defineEmits, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import adminService from '@/services/adminService'
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Props
const props = defineProps(['unitOfMeasureToEdit'])
// #endregion

// #region Emits
const emit = defineEmits(['close'])
// #endregion

// #region Form Data
const formState = reactive({
  unitOfMeasure: {}
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    unitOfMeasure: {
      name: { required },
      symbol: { required }
    }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Data
const dialog = ref(true)
// #endregion

// #region Computed

const nameErrors = computed(() => {
  if (
    v$.value.unitOfMeasure.name.$dirty &&
    v$.value.unitOfMeasure.name.required.$invalid
  ) {
    return t('message.error.required')
  }
  return []
})
// #endregion

// #region Methods
const saveUnitOfMeasure = () => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    if (formState.unitOfMeasure.id) {
      adminService.updateUnitOfMeasure(formState.unitOfMeasure).then(() => {
        emit('close')
      })
    } else {
      adminService.createUnitOfMeasure(formState.unitOfMeasure).then(() => {
        emit('close')
      })
    }
  }
}
// #endregion

// #region Created
formState.unitOfMeasure = props.unitOfMeasureToEdit
// #endregion
</script>

<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2 bg-grey-title">
        Editer unité de mesure
      </v-card-title>
      <v-form @submit.prevent="saveUnitOfMeasure">
        <v-card-text>
          <v-row>
            <v-col cols="12" align="center">
              <v-text-field
                v-model="formState.unitOfMeasure.name"
                :error-messages="nameErrors"
                @input="v$.unitOfMeasure.name.$touch()"
                @blur="v$.unitOfMeasure.name.$touch()"
                hide-details
                variant="outlined"
                color="primary"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Nom pour la recherche
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" align="center">
              <v-text-field
                v-model="formState.unitOfMeasure.symbol"
                :error-messages="symbolErrors"
                @input="v$.unitOfMeasure.symbol.$touch()"
                @blur="v$.unitOfMeasure.symbol.$touch()"
                hide-details
                variant="outlined"
                color="primary"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Symbole
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" align="center">
              <v-text-field
                v-model="formState.unitOfMeasure.symbol_plural"
                hide-details
                variant="outlined"
                color="primary"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Pluriel
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="emit('close')"> Fermer </v-btn>
          <v-btn
            aria-label="Valider"
            color="primary"
            type="submit"
            text
            :disabled="v$.$invalid"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.bg-grey-title {
  background-color: #e0e0e0;
}
</style>
