<script setup>
// #region Imports
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import AccountInformations from '@/components/Account/AccountInformations.vue'
import NavigationZone from '@/components/navigationZone/NavigationZone'
import InitLoader from '@/components/initLoader/InitLoader'

import userService from '@/services/userService'
import planMessage from '@/services/planMessage'
import authService from '@/services/authService'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Store
const store = useStore()
// #endregion

// #region Data
const isInit = ref(false)
const user = ref([])
const message = ref({ title: 'title', text: 'text' })
const subscriptionStatus = ref(null)
const sexItems = ref([
  { value: 'woman', display: 'Femme' },
  { value: 'man', display: 'Homme' }
])
const goalItems = ref([
  { value: 'weight_loss', display: 'Perte de poids' },
  { value: 'weight_stability', display: 'Maintien du poids' }
])
const dietItems = ref([
  { value: 'vegetarian', display: 'Végétarien' },
  { value: 'flexitarian', display: 'Flexitarien' }
])
const sportFrequencyItems = ref([
  { value: 'more_than_2_hours', display: '+ de 2h/semaine' },
  { value: 'less_than_2_hours', display: '- de 2h/semaine' }
])
const vegeAdvertising = ref(null)
// #endregion

// #region Methods
const userFormChange = () => {
  authService
    .updateUserProfile(user.value)
    .then((response) => {
      const choices = [
        user.value.sex,
        user.value.goal,
        user.value.sport_frequency,
        user.value.nb_of_persons > 1 ? 'multi' : 'single',
        Date.now() - new Date(user.value.birthday) >
        60 * 365 * 24 * 60 * 60 * 1000
          ? 'over_sixty_years_old'
          : 'under_sixty_years_old'
      ]

      const messagetemp = planMessage(choices)
      message.value = messagetemp

      store.dispatch('setUser', user.value)

      setVegeAdvertising()
    })
    .catch(() => {
      alert('Une erreur est survenue.')
    })
}
const logout = () => {
  localStorage.clear()
  localStorage.setItem('first_connection', 'false')
  checkRoute({ name: 'Login' })
}
const setVegeAdvertising = () => {
  const vegeMealPlanRelease = new Date('April 30, 2023 23:59:00')
  if (user.value.diet === 'vegetarian' && Date.now() < vegeMealPlanRelease) {
    vegeAdvertising.value =
      'Le premier menu végétarien sera disponible le lundi 1er mai 2023. Encore un tout petit peu de patience'
  } else {
    vegeAdvertising.value = null
  }
}
const checkRoute = (name) => {
  router.push(name)
}
// #endregion

// #region Created
userService
  .getUser()
  .then((response) => {
    user.value = response.data
    userService
      .getSubscriptionStatus()
      .then((response) => {
        subscriptionStatus.value = response.data.subscription_status
      })
      .finally(() => {
        isInit.value = true
        const choices = [
          user.value.sex,
          user.value.goal,
          user.value.sport_frequency,
          user.value.nb_of_persons > 1 ? 'multi' : 'single',
          Date.now() - new Date(user.value.birthday) >
          60 * 365 * 24 * 60 * 60 * 1000
            ? 'over_sixty_years_old'
            : 'under_sixty_years_old'
        ]
        const messageTemp = planMessage(choices)
        message.value = messageTemp
        setVegeAdvertising()
      })
  })
  .catch(() => {})
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0" fluid>
    <v-container v-if="isInit" class="pa-0 pa-md-8">
      <NavigationZone />
      <v-container class="pa-4 pa-md-4">
        <v-row>
          <v-col cols="12">
            <h1 id="account-page-title">{{ $t('account.title') }}</h1>
          </v-col>
        </v-row>

        <v-card
          class="mt-4"
          id="account-informations-view-link"
          flat
          align="left"
          height="85"
          @click="checkRoute({ name: 'AccountInformationsView' })"
        >
          <v-row align="center" class="fill-height px-2 py-3" no-gutters>
            <v-col>
              <v-row no-gutters>
                <h3>Mes informations</h3>
              </v-row>
              <v-row no-gutters>
                <p>Nom, prénom, email, mot de passe, carte bancaire</p>
              </v-row>
            </v-col>
            <v-col align-self="center" cols="auto">
              <v-icon class="mt-2" color="black">
                mdi-chevron-right
              </v-icon></v-col
            >
          </v-row>
        </v-card>

        <v-row>
          <v-col cols="12" md="6" lg="4">
            <AccountInformations
              id="account-informations"
            ></AccountInformations>
          </v-col>

          <v-col cols="12" md="6" lg="8">
            <div id="program-form-wrapper">
              <v-row>
                <v-col cols="12">
                  <h2>Programme</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" lg="7">
                  <v-form class="mt-4">
                    <v-row align="center">
                      <v-col cols="auto">
                        <v-img
                          width="39"
                          aspect-ratio="1"
                          :src="require('@/assets/icons/008-femme.png')"
                        ></v-img>
                      </v-col>
                      <v-col class="px-0"
                        ><label class="form-label" for="">Sexe</label></v-col
                      >
                      <v-col cols="auto">
                        <v-select
                          height="41"
                          v-model="user.sex"
                          class="mt-0 pt-0"
                          color="primary"
                          variant="solo"
                          density="compact"
                          flat
                          hide-details
                          item-value="value"
                          item-title="display"
                          :items="sexItems"
                          required
                        >
                          <template v-slot:selection="{ item }">
                            <span class="selected-item">{{ item.title }}</span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="auto">
                        <v-img
                          width="39"
                          aspect-ratio="1"
                          :src="require('@/assets/icons/001-target.png')"
                        ></v-img>
                      </v-col>
                      <v-col class="px-0"
                        ><label class="form-label" for=""
                          >Objectif</label
                        ></v-col
                      >
                      <v-col cols="auto">
                        <v-select
                          height="41"
                          v-model="user.goal"
                          class="mt-0 pt-0"
                          color="primary"
                          variant="solo"
                          density="compact"
                          flat
                          hide-details
                          item-value="value"
                          item-title="display"
                          :items="goalItems"
                        >
                          <template v-slot:selection="{ item }">
                            <span class="selected-item">{{ item.title }}</span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="auto">
                        <v-img
                          width="39"
                          aspect-ratio="1"
                          :src="require('@/assets/icons/002-fork.png')"
                        ></v-img>
                      </v-col>
                      <v-col class="px-0"
                        ><label class="form-label" for="">Régime</label></v-col
                      >
                      <v-col cols="auto">
                        <v-select
                          height="41"
                          v-model="user.diet"
                          class="mt-0 pt-0"
                          color="primary"
                          variant="solo"
                          density="compact"
                          flat
                          hide-details
                          item-value="value"
                          item-title="display"
                          :items="dietItems"
                        >
                          <template v-slot:selection="{ item }">
                            <span class="selected-item">{{ item.title }}</span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row class="px-3" v-if="vegeAdvertising">
                      <p class="vege-advertising">{{ vegeAdvertising }}</p>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="auto">
                        <v-img
                          width="39"
                          aspect-ratio="1"
                          :src="require('@/assets/icons/haltere.png')"
                        ></v-img>
                      </v-col>
                      <v-col class="px-0"
                        ><label class="form-label" for="">Sport</label></v-col
                      >
                      <v-col cols="auto">
                        <v-select
                          height="41"
                          v-model="user.sport_frequency"
                          class="mt-0 pt-0"
                          color="primary"
                          variant="solo"
                          density="compact"
                          flat
                          hide-details
                          item-value="value"
                          item-title="display"
                          :items="sportFrequencyItems"
                        >
                          <template v-slot:selection="{ item }">
                            <span class="selected-item">{{ item.title }}</span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="auto">
                        <v-img
                          width="39"
                          aspect-ratio="1"
                          :src="require('@/assets/icons/003-user.png')"
                        ></v-img>
                      </v-col>
                      <v-col class="px-0"
                        ><label class="form-label" for=""
                          >Nbr de personnes</label
                        ></v-col
                      >
                      <v-col cols="auto" align-self="end" class="nbe-field">
                        <v-text-field
                          v-model="user.nb_of_persons"
                          class="mt-0 pt-0 background-gray"
                          height="41"
                          hide-details
                          flat
                          color="primary"
                          variant="solo"
                          density="compact"
                          single-line
                          type="number"
                          :min="1"
                          oninput="if(Number(this.value) > 8) this.value = 8;"
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-col cols="auto">
                        <v-btn
                          @click="userFormChange()"
                          text
                          class="link boutonPrincipales mt-0"
                        >
                          Enregistrer
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
                <v-col
                  id="program-message"
                  v-if="message"
                  cols="12"
                  md="12"
                  lg="5"
                  class="message-box"
                >
                  <p v-html="message.title" class="message-title"></p>
                  <div v-html="message.text" class="message-text mt-4"></div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" align="right">
            <v-btn
              v-if="
                subscriptionStatus !== 'active' &&
                subscriptionStatus !== 'past_due'
              "
              class="boutonPrincipales"
              @click="checkRoute({ name: 'Checkout' })"
            >
              {{ $t('account.information.subscribe') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row justify="end">
          <v-col align-self="end" cols="auto">
            <a
              class="links"
              target="_blank"
              href="https://joanaetvous.com/page-conditions-generales-de-vente-et-dutilisation/"
              >Conditions générales de vente</a
            >
          </v-col>
        </v-row>

        <v-row justify="end">
          <v-col align-self="end" cols="auto">
            <a id="logout-link" @click="logout">déconnexion</a>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped lang="scss">
h2 {
  margin: 0 !important;
}

.v-card {
  p {
    font-size: 14px;
    margin: 0;
  }
}

.selected-item {
  color: #323232;
  font-size: 14px !important;
  font-family: 'Barlow-Bold';
}

.form-label {
  color: #707070;
  font-size: 14px;
}

.v-text-field--rounded {
  border-radius: 7px;
}

.links {
  font-size: 14px;
  color: #323232;
  font-weight: bold;
}

.message-box {
  .message-title {
    color: #eb5c43 !important;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Barlow-Bold';
  }

  .message-text {
    font-size: 14px;
  }
}

#logout-link {
  text-transform: uppercase;
  text-decoration: underline;
  color: #323232;
  font-weight: bold;
  font-family: 'Barlow-Bold';
}

.vege-advertising {
  color: #eb5c43;
}

.v-input {
  width: 204px !important;

  @media only screen and (min-width: 961px) {
    background-color: #f6f6f6 !important;
  }
}

::v-deep #program-form-wrapper .v-field {
  @media only screen and (min-width: 961px) {
    background-color: #f6f6f6 !important;
  }
}

.nbe-field {
  .v-input {
    width: 70px !important;
    font-family: 'Barlow-Bold';
    @media only screen and (min-width: 961px) {
      background-color: #f6f6f6 !important;
    }
  }
}

.links {
  font-family: 'Barlow-Bold';
}

#account-informations-view-link {
  @media only screen and (min-width: 961px) {
    display: none;
  }
}

#account-informations {
  @media only screen and (max-width: 960px) {
    display: none;
  }
}

#program-form-wrapper {
  @media only screen and (min-width: 961px) {
    background-color: white;
    border-radius: 15px;
    padding: 15px 20px;
  }

  @media only screen and (max-width: 960px) {
    margin-top: 10px;
    margin-bottom: 0px;

    h2 {
      margin-bottom: 15px;
    }

    #program-message {
      margin-top: 0px;
    }
  }
}
</style>
