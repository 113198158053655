<script setup>
// #region Imports
import { reactive, computed, ref, defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import _ from 'lodash'

import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import InitLoader from '@/components/initLoader/InitLoader'

import authService from '@/services/authService'
import userService from '@/services/userService'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Props
const props = defineProps(['redirect', 'messages'])
// #endregion

// #region Data
const isInit = ref(false)
const snackbarErrorCredentials = ref(false)
// #endregion

// #region Form Data
const formState = reactive({
  email: '',
  password: ''
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    email: { required, email },
    password: { required }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Computed
const emailErrors = computed(() => {
  if (v$.value.email.$dirty) {
    if (v$.value.email.required.$invalid) {
      return t('message.error.required')
    } else if (v$.value.email.email.$invalid) {
      return t('message.error.email')
    }
  }
  return []
})
const passwordErrors = computed(() => {
  if (v$.value.password?.$dirty) {
    if (v$.value.password.required.$invalid) {
      return t('message.error.required')
    }
  }
  return []
})
// #endregion

// #region Created
authService
  .checkTokens()
  .then(() => {
    checkRoute({ name: 'MealPlan' })
  })
  .catch(() => {
    isInit.value = true
  })
// #endregion

// #region Methods
const login = _.debounce(() => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    authService
      .logIn({
        email: formState.email,
        password: formState.password
      })
      .then((response) => {
        localStorage.setItem(
          'tokens',
          JSON.stringify({
            access: response.data.access,
            refresh: response.data.refresh
          })
        )
        if (localStorage.getItem('first_connection') == null) {
          localStorage.setItem('first_connection', 'true')
        }
        return true
      })
      .then(() => userService.getUser())
      .then((data) => {
        const user = data.data
        if (user) {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'log_in',
            user_data: {
              user_id: user.id
            }
          })
        }
        if (user.has_seen_onboarding === false) {
          checkRoute({ name: 'Onboarding' })
          return
        }
        if (props.redirect) {
          router.go(-1)
        } else checkRoute({ name: 'MealPlan' })
        // else if (this.v$uetify.breakpoint.smAndDown & localStorage.getItem('first_connection') === 'true') {
        //   this.checkRoute({ name: 'HowTo' })
        // }
      })
      .catch(() => {
        snackbarErrorCredentials.value = true
      })
  }
}, 2000)
const checkRoute = (name) => {
  router.push(name)
}
// #endregion
</script>

<template>
  <v-container class="wrapper-without-navigation">
    <v-container v-if="isInit" class="content fill-height">
      <v-row>
        <v-col style="display: block; margin-left: auto; margin-right: auto" cols="12" align="center">
          <v-container class="main-title">
            <v-img eager src="@/assets/img/Joana_Horizontal_v1.png"
              style="display: block; margin-left: auto; margin-right: auto">
            </v-img>
          </v-container>
        </v-col>
      </v-row>
      <v-row justify="center" class="ma-1">
        <v-form @submit.prevent="login">
          <v-col cols="12" align="center">
            <v-text-field v-model="formState.email" :label="$t('login.email')" :error-messages="emailErrors"
              @input="v$.email.$touch()" @blur="v$.email.$touch()" type="email" hide-details variant="outlined" rounded>
            </v-text-field>
          </v-col>
          <v-col cols="12" align="center" class="pt-0">
            <v-text-field v-model="formState.password" type="password" :label="$t('login.password')"
              :error-messages="passwordErrors" @input="v$.password.$touch()" @blur="v$.password.$touch()" hide-details
              variant="outlined" rounded>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" align="center">
            <v-btn class="confirm-button" :aria-label="$t('login.button')" color="primary" type="submit"
              :disabled="v$.$invalid">
              {{ $t('login.button') }}
            </v-btn>
          </v-col>
        </v-form>
        <v-col cols="12" sm="12" align="center">
          <span class="txt-gray txt-small">{{ $t('login.noAccount') }}</span>
          <span class="signup txt-small" @click="checkRoute({ name: 'Signup' })">
            {{ $t('login.signup') }}
          </span>
        </v-col>
        <v-col cols="12" sm="12" align="center">
          <span class="forgottenPassword signup txt-small" @click="checkRoute({ name: 'ResetPassword' })">
            {{ $t('login.forgottenPassword') }}
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbarErrorCredentials" timeout="5000" location="top" color="error">
      {{ $t('login.errorCredentials') }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" :aria-label="$t('general.button.close')" variant="text"
          @click="snackbarErrorCredentials = false">
          {{ $t('general.button.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar :model-value="true" v-for="(message, i) in props.messages" :key="i" :color="message.type"
      timeout="15000" location="top">
      {{ message.text }}
    </v-snackbar>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped lang="scss">
.content {
  max-width: 500px !important;
}

.main-title {
  font-size: 40px;
  line-height: 41px;
  letter-spacing: -0.02em;
  font-family: made-gentle;
}

.v-input {
  width: 300px;
}

.confirm-button {
  width: 300px;
  height: 50px !important;
  border-radius: 15px;
}

.forgottenPassword,
.signup {
  cursor: pointer;

  color: #eb5c43 !important;
  margin-bottom: 3px;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.signup {
  color: var(--v-primary-base);
}

::v-deep input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232300;
}

::v-deep .v-btn--disabled {
  background-color: #d8d8d8 !important;
}

::v-deep .v-btn__overlay {
  opacity: 0% !important;
}

::v-deep .v-btn__content {
  color: rgba(0, 0, 0, 0.26) !important;
  font-size: 14px !important;
}

::v-deep .v-btn:not(.v-btn--disabled) span {
  color: white !important;
}

.txt-gray {
  color: #707070 !important;
}

.txt-small {
  font-size: 14px !important;
}
</style>
