<script setup>
// #region Imports
import { reactive, computed, ref, defineProps, defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'

// import TurndownService from 'turndown'
// import { QuillEditor } from '@vueup/vue-quill'
// import '@vueup/vue-quill/dist/vue-quill.snow.css'

import RichTextEditor from '@/components/RichTextEditor/RichTextEditor'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Props
const props = defineProps(['adviceQuestionToManage'])
// #endregion

// #region Emits
const emits = defineEmits(['submitQuestionModal', 'close'])
// #endregion

// #region Data
const iconFile = ref(null)
const iconSrc = ref(null)
const photoFile = ref(null)
const photoSrc = ref(null)
const editorOptions = ref({
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }]
  ]
})
// #endregion

// #region Form Data
const formState = reactive({
  dialog: true,
  adviceQuestion: { question: '', isOnboarding: false, response: 'Default' }
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    adviceQuestion: {
      question: { required }
    }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Computed

const questionErrors = computed(() => {
  if (
    v$.value.adviceQuestion.question.$dirty &&
    v$.value.adviceQuestion.question.required.$invalid
  ) {
    return t('message.error.required')
  }
  return []
})
// #endregion

// #region Methods
const saveAdviceQuestion = () => {
  const formData = new FormData()
  formData.append('question', formState.adviceQuestion.question)
  formData.append('response', formState.adviceQuestion.response)
  formData.append('is_onboarding', formState.adviceQuestion.isOnboarding)
  if (iconFile.value && iconFile.value[0]) {
    formData.append('icon', iconFile.value[0])
  }
  if (photoFile.value && photoFile.value[0]) {
    formData.append('photo', photoFile.value[0])
  }
  v$.value.$touch()
  if (!v$.value.$invalid) {
    emits('submitQuestionModal', {
      id: formState.adviceQuestion.id,
      formData: formData
    })
  }
}
const changeIconFile = () => {
  if (iconFile.value && iconFile.value[0]) {
    const reader = new FileReader()
    reader.onloadend = (e) => {
      iconSrc.value = reader.result
    }

    reader.readAsDataURL(iconFile.value[0])
  } else {
    iconFile.value = null
    iconSrc.value = null
  }
}
const changePhotoFile = () => {
  if (photoFile.value && photoFile.value[0]) {
    const reader = new FileReader()
    reader.onloadend = (e) => {
      photoSrc.value = reader.result
    }
    reader.readAsDataURL(photoFile.value[0])
  } else {
    photoFile.value = null
    photoSrc.value = null
  }
}
const getPictureUrl = (uri) => {
  return process.env.VUE_APP_API_URL + '/' + uri
}
const changeResponse = (e) => {
  // const markDown = this.$refs.toastuiEditor.invoke('getMarkdown')
  formState.adviceQuestion.response = e
}
const deletePhotoFile = () => {
  photoSrc.value = null
  photoFile.value = null
}
// #endregion

// #region Created
if (props.adviceQuestionToManage) {
  formState.adviceQuestion = props.adviceQuestionToManage
  if (formState.adviceQuestion.icon) {
    iconSrc.value = getPictureUrl(formState.adviceQuestion.icon)
  }
  if (formState.adviceQuestion.photo) {
    photoSrc.value = getPictureUrl(formState.adviceQuestion.photo)
  }
}
// #endregion
</script>

<template>
  <v-dialog v-model="formState.dialog" width="80vw" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2 bg-grey-title">
        Editer question
      </v-card-title>
      <v-form @submit.prevent="saveAdviceQuestion">
        <v-card-text>
          <v-row>
            <v-col align-self="center" cols="6">
              <v-text-field
                v-model="formState.adviceQuestion.question"
                label="Question"
                variant="outlined"
                color="primary"
                rounded
                :error-messages="questionErrors"
                @input="v$.adviceQuestion.question.$touch()"
                @blur="v$.adviceQuestion.question.$touch()"
              >
              </v-text-field>
            </v-col>
            <v-col align-self="center" cols="4">
              <v-file-input
                accept="image/*"
                @change="changeIconFile()"
                label="Pictogramme"
                variant="outlined"
                color="primary"
                rounded
                prepend-icon="mdi-file-upload"
                v-model="iconFile"
              ></v-file-input
            ></v-col>
            <v-col align-self="center" cols="2"
              ><img
                v-if="iconSrc"
                height="30"
                :src="iconSrc"
                alt="icon"
                srcset=""
            /></v-col>
          </v-row>
          <v-row>
            <v-col>
              <RichTextEditor
                :content="formState.adviceQuestion.response"
                @change="changeResponse($event)"
              />
              <Editor
                :initialValue="formState.adviceQuestion.response"
                :options="editorOptions"
                ref="toastuiEditor"
                class="mb-3"
                :height="'150px'"
                initialEditType="wysiwyg"
                previewStyle="horizontal"
                @change="onEditorChange"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6"
              ><v-file-input
                v-model="photoFile"
                accept="image/*"
                @change="changePhotoFile()"
                label="Réponse photo"
                variant="outlined"
                color="primary"
                rounded
                prepend-icon="mdi-file-upload"
              ></v-file-input>
              <v-btn
                v-if="photoSrc"
                variant="flat"
                color="primary"
                @click="deletePhotoFile"
              >
                Supprimer la photo
              </v-btn>
              <v-checkbox
                v-model="formState.adviceQuestion.isOnboarding"
                label="Afficher sur l'onboarding client"
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
              <div class="response-img-wrapper">
                <img
                  id="response-img"
                  v-if="photoSrc"
                  :src="photoSrc"
                  alt="response image"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="emits('close')"> Fermer </v-btn>
          <v-btn
            aria-label="Valider"
            color="primary"
            type="submit"
            text
            :disabled="v$.$invalid"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.bg-grey-title {
  background-color: #e0e0e0;
}
.response-img-wrapper {
  width: 30vw;
  height: calc(30vw * 9 / 16);
  background-color: grey;
}

.response-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
