<script setup>
// #region Imports
import { defineProps, computed } from 'vue'
import { useStore } from 'vuex'

import Markdown from 'vue3-markdown-it'
// #endregion

// #region Store
const store = useStore()
// #endregion

// #region Store Data
const nbOfPersons = computed(() => store.getters.nbOfPersons)
// #endregion

// #region Props
const props = defineProps(['dish'])
// #endregion

// #region Methods
const getIngredientName = (ingredient) => {
  let name = ingredient.ingredient.name
  if (ingredient.quantity > 1 && ingredient.ingredient.plural_name) {
    name = ingredient.ingredient.plural_name
  }
  return name.charAt(0).toUpperCase() + name.slice(1)
}
// #endregion
</script>

<template>
  <v-card class="pa-md-6" elevation="0">
    <section class="pdf-item">
      <!-- <v-img
        :src="require('@/assets/img/Joana_Horizontal_v1.png')"
        style="
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 500px;
        "
      >
      </v-img> -->
      <h1 style="text-align: center; color: #eb5c43">Joana & Vous</h1>
      <h1
        class="capitalize"
        style="
          width: 100%;
          margin-top: 30px;
          margin-left: 0;
          padding-left: 0;
          text-align: left;
          font-family: 'Barlow-Bold';
          font-size: 25px;
        "
      >
        {{ dish.name }}
      </h1>
      <!-- <strong style="font-size: 25px; margin-top: 30px; margin-bottom: 30px">{{
        props.dish.name
      }}</strong> -->
      <span>Nombre de portions : {{ props.dish.portions * nbOfPersons }}</span>
      <div style="margin-top: 30px">
        <span>Préparation : {{ props.dish.preparation_time }}</span>
        <span class="sublabel ml-1"> {{ $t('dish.time') }}</span>
      </div>
      <div>
        <span>Cuisson : {{ props.dish.cooking_time }}</span>
        <span class="sublabel ml-1"> {{ $t('dish.time') }}</span>
      </div>

      <div style="margin-top: 50px; align-self: flex-start" class="ingredients">
        <h2 style="color: #eb5c43">{{ $t('dish.ingredients') }} :</h2>
        <ul style="margin-left: 30px; margin-top: 30px">
          <li
            v-for="ingredient in props.dish.ingredients"
            :key="
              ingredient.ingredient.name +
              ingredient.quantity +
              ingredient.unit_of_measure.symbol
            "
          >
            <span>{{ getIngredientName(ingredient) }} </span>
            <span
              v-if="ingredient.quantity > 0"
              class="quantity ml-1"
              :class="ingredient.unit_of_measure.name === 'unit' ? 'mr-2' : ''"
            >
              {{ ingredient.quantity * nbOfPersons }}
            </span>

            <span
              v-if="ingredient.unit_of_measure.name != 'unit'"
              class="unit_of_measure mr-2"
            >
              <span
                v-if="
                  ingredient.quantity > 1 &&
                  ingredient.unit_of_measure.symbol_plural
                "
              >
                {{ '&nbsp;' + ingredient.unit_of_measure.symbol_plural }}
              </span>
              <span v-else>
                {{ '&nbsp;' + ingredient.unit_of_measure.symbol }}
              </span>
            </span>
          </li>
        </ul>
      </div>

      <div style="margin-top: 50px; align-self: flex-start" class="preparation">
        <h2 style="color: #eb5c43">{{ $t('dish.preparation') }} :</h2>
        <Markdown
          class="md-body"
          :style="{
            fontSize: '14px',
            color: '#707070',
            fontWeight: 'normal',
            'margin-top': '30px'
          }"
          :source="props.dish.instructions"
        />
      </div>
    </section>
  </v-card>
</template>

<style scoped lang="scss">
.pdf-item {
  padding: 50px;
  width: 100%;
  // margin-left: auto;
  // margin-right: auto;
  // font-family: 'Barlow-Bold';
  display: flex;
  flex-direction: column;
  align-content: center;
  display: flex;
  flex-direction: column;
  // align-items: center;

  p {
    font-size: 16px !important;
  }
  .md-body {
    list-style-position: inside;
  }
  .preparation {
    strong: {
      font-weight: normal !important;
    }
  }

  ul {
    margin-left: 0 !important;
    list-style-position: inside;
    li {
      // font-size: 16px!important;
      // span {
      // font-size: 16px!important;
      // }
    }
  }
}
</style>
