<script setup>
// #region Imports
import { ref, computed, onUpdated } from 'vue'
import { useRoute } from 'vue-router'

// import 'codemirror/lib/codemirror.css'
// import '@toast-ui/editor/dist/toastui-editor.css'
// import Editor from '@toast-ui/editor'

import RichTextEditor from '@/components/RichTextEditor/RichTextEditor'

import EditAdviceSubCategoryModal from '@/components/admin/joanaAdvices/EditAdviceSubCategoryModal'
import AdviceQuestionList from '@/components/admin/joanaAdvices/AdviceQuestionList'
import ConfirmDialog from '@/components/admin/miscellaneous/confirmDialog/ConfirmDialog'
import InitLoader from '@/components/initLoader/InitLoader'

import Sortable from 'sortablejs'

import joanaAdviceCategoryService from '@/services/adminJoanaAdvice/joanaAdviceCategoryService'
import joanaAdviceSubCategoryService from '@/services/adminJoanaAdvice/joanaAdviceSubCategoryService'
// #endregion

// #region Router
const route = useRoute()
// #endregion

// #region Data
const confirm = ref(null)
// const editor = ref(null)

const isInit = ref(false)
const page = ref(1)
const totalItems = ref(0)
const itemsPerPage = ref(50)
const editAdviceSubCategoryModal = ref(false)
const adviceSubCategoryToManage = ref(null)
const adviceCategory = ref(null)
const adviceSubCategories = ref([])
const expanded = ref([])
const singleExpand = ref(true)
const sortableInstance = ref(null)
// const isExpanded = ref(false)
// const editorOptions = ref({
//   toolbar: [
//     [{ header: [1, 2, 3, 4, 5, 6, false] }],
//     ['bold', 'italic', 'underline', 'strike'],
//     [{ list: 'ordered' }, { list: 'bullet' }]
//   ]
// })
// #endregion

// #region Computed
const pageCount = computed(() => {
  const totalPages = ~~(totalItems.value / itemsPerPage.value)
  const modulo = totalItems.value % itemsPerPage.value
  return modulo === 0 ? totalPages : totalPages + 1
})
const headers = computed(() => {
  return [
    { title: '', key: 'data-table-expand' },
    { title: 'Nom', sortable: false, value: 'name', align: 'start' },
    {
      title: 'Actions',
      value: 'actions',
      sortable: false,
      width: '136px',
      fixed: true
    }
  ]
})
// #endregion

// #region Methods
const editAdviceSubCategory = (item) => {
  adviceSubCategoryToManage.value = { ...item }
  editAdviceSubCategoryModal.value = true
}
const submitEditAdviceSubCategoryModal = (data) => {
  editAdviceSubCategoryModal.value = false
  if (data.id) {
    joanaAdviceSubCategoryService
      .updateAdviceSubCategory(adviceCategory.value.id, {
        id: data.id,
        name: data.name,
        order: data.order
      })
      .then((adviceSubCategory) => {
        return joanaAdviceCategoryService
          .getAdviceCategory(adviceCategory.value.id)
          .then((adviceCategory) => {
            adviceSubCategories.value = adviceCategory.subcategories
          })
      })
      .catch((error) => {
        alert(error)
      })
  } else {
    data.order = adviceSubCategories.value.length + 1
    joanaAdviceSubCategoryService
      .createAdviceSubCategory(adviceCategory.value.id, data)
      .then((adviceSubCategory) => {
        return joanaAdviceCategoryService
          .getAdviceCategory(adviceCategory.value.id)
          .then((adviceCategory) => {
            adviceSubCategories.value = adviceCategory.subcategories
            totalItems.value = adviceSubCategories.value.length
          })
      })
      .catch((error) => {
        alert(error)
      })
  }
}
const closeEditAdviceSubCategoryModal = () => {
  editAdviceSubCategoryModal.value = false
}
const deleteAdviceSubCategory = (id) => {
  const deletedItem = adviceSubCategories.value.find((x) => x.id === id)
  confirm.value
    .open(
      'Supprimer la sous-catégorie',
      'Souhaitez-vous vraiment supprimer cette sous-catégorie ?',
      { color: 'primary' }
    )
    .then((confirm) => {
      if (confirm) {
        joanaAdviceSubCategoryService
          .deleteAdviceSubCategory(adviceCategory.value.id, id)
          .then((_) => {
            return joanaAdviceCategoryService
              .getAdviceCategory(adviceCategory.value.id)
              .then((adviceCategory) => {
                adviceSubCategories.value = adviceCategory.subcategories
                totalItems.value = adviceSubCategories.value.length
              })
          })
          .then(() => {
            const list = adviceSubCategories.value
            const newOrders = []
            for (const item of list) {
              if (item.order > deletedItem.order) {
                newOrders.push({ id: item.id, order: item.order - 1 })
              } else {
                newOrders.push({ id: item.id, order: item.order })
              }
            }
            return joanaAdviceSubCategoryService
              .updateAdviceSubCategoryOrder(adviceCategory.value.id, newOrders)
              .then((_) => {
                return joanaAdviceCategoryService
                  .getAdviceCategory(route.params.joanaAdviceCategoryId)
                  .then((adviceCategory) => {
                    adviceCategory.value = adviceCategory
                    adviceSubCategories.value = adviceCategory.subcategories
                    totalItems.value = adviceSubCategories.value.length
                  })
              })
              .catch((error) => {
                alert(error)
              })
          })
          .catch((error) => {
            alert(error)
          })
      }
    })
}
const initSortable = () => {
  const table = document.querySelector('#advice-subcategory-table tbody')
  if (!table) return
  const vueObj = this
  sortableInstance.value = Sortable.create(table, {
    group: 'adviceSubCategories',
    sort: true,
    onEnd({ newIndex, oldIndex }) {
      const data = vueObj._data.adviceSubCategories
      const adviceSubCategoryId = vueObj._data.adviceCategory.id
      const moveId = data[oldIndex].id
      const newPosition = newIndex + 1
      const oldPosition = oldIndex + 1
      const newOders = []
      for (const item of vueObj._data.adviceSubCategories) {
        if (
          moveId !== item.id &&
          item.order >= newPosition &&
          item.order < oldPosition
        ) {
          newOders.push({ id: item.id, order: item.order + 1 })
        } else if (
          moveId !== item.id &&
          item.order > oldPosition &&
          item.order <= newPosition
        ) {
          newOders.push({ id: item.id, order: item.order - 1 })
        } else if (item.id === moveId) {
          newOders.push({ id: item.id, order: newPosition })
        } else {
          newOders.push({ id: item.id, order: item.order })
        }
      }
      joanaAdviceSubCategoryService
        .updateAdviceSubCategoryOrder(adviceSubCategoryId, newOders)
        .then((adviceCategory) => {
          adviceSubCategories.value = adviceCategory.subcategories
        })
        .catch((error) => {
          alert(error)
        })
    }
  })
}
const changeLegend = (payload) => {
  if (payload.args && payload.item) {
    payload.item.legend = payload.args
  }
}
const saveLegend = (item) => {
  // if (expanded.value.length !== 1) {
  //   alert('Une erreur est survenue.')
  //   return
  // }
  const subCategory = item

  joanaAdviceSubCategoryService
    .updateAdviceSubCategory(adviceCategory.value.id, {
      id: subCategory.id,
      name: subCategory.name,
      order: subCategory.order,
      legend: item.legend
    })
    .catch((error) => {
      alert(error)
    })
}

// #endregion

// #region Updated
onUpdated(() => {
  if (expanded.value.length > 0 && sortableInstance.value) {
    sortableInstance.value.destroy()
  } else {
    initSortable()
  }
})
// #endregion

// #region Created
joanaAdviceCategoryService
  .getAdviceCategory(route.params.joanaAdviceCategoryId)
  .then((tempAdviceCategory) => {
    adviceCategory.value = tempAdviceCategory
    adviceSubCategories.value = tempAdviceCategory.subcategories
    totalItems.value = adviceSubCategories.value.length
    isInit.value = true
  })
  .catch((error) => {
    alert(error)
  })
// #endregion
</script>

<template>
  <v-container class="admin-wrapper-container" fluid>
    <v-container v-if="isInit" fluid>
      <h1 class="mb-3">Les conseils de Joana</h1>

      <v-data-table
        :headers="headers"
        :items="adviceSubCategories"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        hover
        density="compact"
        show-expand
        hide-default-footer
        :items-per-page="itemsPerPage"
        :page.sync="page"
        item-key="id"
        class="py-4"
        id="advice-subcategory-table"
      >
        <template v-slot:bottom></template>
        <template v-slot:top>
          <v-row justify="center" align="center" class="">
            <v-col cols="12" sm="5" class="py-0" align="left">
              <h2 class="ml-5">Sous catégories</h2>
            </v-col>
            <v-col cols="8" sm="6" class="py-0" align="right"> </v-col>
            <v-col cols="4" sm="1" class="py-0" align="right">
              <v-btn
                icon
                variant="text"
                color="primary"
                @click="editAdviceSubCategory()"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3 class="ml-5">{{ adviceCategory.name }}</h3>
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn style="opacity: 75%" variant="text" icon>
            <v-icon @click="editAdviceSubCategory(item)"> mdi-pencil </v-icon>
          </v-btn>
          <v-btn variant="text" style="opacity: 75%" icon>
            <v-icon @click="deleteAdviceSubCategory(item.id)">
              mdi-delete
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:expanded-row="{ columns, item }">
          <td :colspan="columns.length">
            <v-row>
              <v-col class="ml-4 mt-5" colspan="5">
                <h3>Légende</h3>
              </v-col>
            </v-row>
            <v-row :colspan="columns.length">
              <v-col class="mb-8 pl-6 pr-6" cols="12">
                <RichTextEditor
                  :content="item.legend"
                  @change="changeLegend({ args: $event, item: item })"
                />
                <!-- <QuillEditor
                  ref="editor"
                  v-model:content="item.legend"
                  contentType="html"
                  :toolbar="editorOptions.toolbar"
                  theme="snow"
                /> -->

                <!-- <Editor
                  :initialValue="item.legend"
                  :options="editorOptions"
                  ref="toastuiEditorSubCategoryLegend"
                  initialEditType="wysiwyg"
                  previewStyle="horizontal"
                /> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mb-0" align="right">
                <v-btn
                  class="mr-4"
                  variant="flat"
                  color="primary"
                  @click="saveLegend(item)"
                >
                  Enregistrer
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <AdviceQuestionList
                  :adviceSubCategory="item"
                  :joanaAdviceCategoryId="adviceCategory.id"
                >
                </AdviceQuestionList>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>

      <div class="text-center pt-2 mb-8">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="10"
          active-color="primary"
          density="comfortable"
          variant="elevated"
          opacity="100"
        ></v-pagination>
      </div>

      <EditAdviceSubCategoryModal
        v-if="editAdviceSubCategoryModal"
        :adviceCategoryToManage="adviceSubCategoryToManage"
        @close="closeEditAdviceSubCategoryModal()"
        @submitModal="(data) => submitEditAdviceSubCategoryModal(data)"
      />

      <ConfirmDialog ref="confirm" />
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped>
::v-deep .v-data-table-header__content {
  font-weight: bold;
}
</style>
