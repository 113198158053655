<script setup>
// #region Imports
import { reactive, computed, ref, defineProps, defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'

import _ from 'lodash'

import moment from 'moment'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import userService from '@/services/userService'
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Props
const props = defineProps(['data'])
// #endregion

// #region Emits
const emits = defineEmits(['update'])
// #endregion

// #region Data
const menuBirthday = ref(null)
const menuBirthdayValue = ref(false)
// #endregion

// #region Form Data
const formState = reactive({
  user: {}
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    user: {
      first_name: { required },
      last_name: { required },
      birthday: { required }
    }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Computed
const firstNameErrors = computed(() => {
  if (
    v$.value.user?.first_name.$dirty &&
    v$.value.user?.first_name.required.$invalid
  ) {
    return t('message.error.required')
  }
  return []
})
const lastNameErrors = computed(() => {
  if (
    v$.value.user?.last_name.$dirty &&
    v$.value.user?.last_name.required.$invalid
  ) {
    return t('message.error.required')
  }
  return []
})
const birthdayErrors = computed(() => {
  if (
    v$.value.user.birthday.$dirty &&
    v$.value.user.birthday.required.$invalid
  ) {
    return t('message.error.required')
  }
  return []
})
const birthdayFormatted = computed(() => {
  return formState.user.birthday
    ? moment(formState.user.birthday).format('DD/MM/YYYY')
    : null
})
// #endregion

// #region Methods
const changeDate = (date) => {
  formState.user.birthday = moment(date).format('YYYY-MM-DD')
  menuBirthdayValue.value = false
}
const save = _.debounce(() => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    userService.updateUser(formState.user).then(() => {
      emits('update', formState.user)
    })
  }
}, 1000)
// #endregion

// #region Created
formState.user = { ...props.data }
const birthdayTemp = ref(new Date(formState.user.birthday))
// #endregion
</script>

<template>
  <div>
    <v-row align="center" align-content="center">
      <v-col><label class="form-label" for="">Prénom</label></v-col>
      <v-col cols="auto" align-self="end">
        <v-text-field
          class="mt-0 pt-0 v-text-field--rounded"
          color="primary"
          :error-messages="firstNameErrors"
          v-model="formState.user.first_name"
          height="41"
          hide-details
          flat
          variant="solo"
          density="compact"
          single-line
        />
      </v-col>
    </v-row>

    <v-row align="center" align-content="center">
      <v-col><label class="form-label" for="">Nom</label></v-col>
      <v-col cols="auto" align-self="end">
        <v-text-field
          class="mt-0 pt-0 v-text-field--rounded"
          :error-messages="lastNameErrors"
          v-model="formState.user.last_name"
          height="41"
          hide-details
          flat
          variant="solo"
          density="compact"
          single-line
        />
      </v-col>
    </v-row>

    <v-row align="center" align-content="center">
      <v-col><label class="form-label" for="">Date de naissance</label></v-col>
      <v-col cols="auto" align-self="end">
        <v-menu
          ref="menuBirthday"
          v-model="menuBirthdayValue"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="204px"
        >
          <template v-slot:activator="{ props: menu }">
            <v-text-field
              v-bind="menu"
              class="mt-0 pt-0 v-text-field--rounded"
              :error-messages="birthdayErrors"
              v-model="birthdayFormatted"
              v-on="on"
              readonly
              height="41"
              hide-details
              flat
              variant="solo"
              density="compact"
            >
            </v-text-field>
          </template>
          <v-date-picker
            color="primary"
            v-model="birthdayTemp"
            :max="new Date().toISOString().substr(0, 10)"
            @update:modelValue="changeDate"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row align="center" align-content="center">
      <v-col><label class="form-label" for="">Téléphone</label></v-col>
      <v-col cols="auto" align-self="end">
        <v-text-field
          class="mt-0 pt-0 v-text-field--rounded"
          v-model="formState.user.phone_number"
          height="41"
          hide-details
          flat
          variant="solo"
          density="compact"
          single-line
        />
      </v-col>
    </v-row>

    <v-row justify="end" class="mb-8">
      <v-col cols="auto">
        <v-btn flat @click="save()" text class="link boutonPrincipales mt-4">
          Enregistrer
        </v-btn>
      </v-col>
    </v-row>
  </div>

  <!-- <v-btn color="primary" text @click="$emit('close')">
        {{ $t('general.button.close') }}
    </v-btn>
    <v-btn color="primary" text @click="save" :disabled="this.$v.$invalid">
        {{ $t('general.button.save') }}
    </v-btn> -->
</template>

<style scoped lang="scss">
::v-deep .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  font-size: 14px !important;
}
.v-text-field--rounded {
  border-radius: 7px;
}

.form-label {
  color: #707070;
  font-size: 14px;
}

.v-input {
  width: 204px !important;
  background-color: white;
  font-family: 'Barlow-Bold';
  font-size: 14px !important;
}
.txt-small {
  font-size: 14px !important;
}
</style>
