<template>
  <v-container class="wrapper-without-navigation">
    <v-container class="content pa-4 pt-8 pa-md-8" align="center">
      <v-container class="main-title my-5">
        <v-img
          eager
          src="@/assets/img/Joana_Horizontal_v1.png"
          style="display: block; margin-left: auto; margin-right: auto"
        >
        </v-img>
      </v-container>
      <p class="description pb-16px">{{ $t('general.description') }}</p>
      <p class="subdescription">
        <i>{{ $t('general.subDescription') }}</i>
      </p>
      <v-row justify="center">
        <v-img class="size-300px" eager src="@/assets/img/home.png"></v-img>
      </v-row>
      <v-row justify="center">
        <v-col align="center" class="pt-0">
          <v-btn
            class="boutonPrincipalesLight txt-small"
            @click.native="checkRoute({ name: 'Signup' })"
          >
            {{ $t('general.button.signup') }}
          </v-btn>
        </v-col>
        <v-col align="center" class="pt-0 txt-small">
          <v-btn
            variant="text"
            class="txt-small"
            @click.native="checkRoute({ name: 'Login' })"
          >
            {{ $t('general.button.login') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import authService from '@/services/authService'
import mixin from '@/mixins/mixin'

export default {
  name: 'HomeBase',
  mixins: [mixin],
  created() {
    authService
      .checkTokens()
      .then(() => {
        this.checkRoute({ name: 'MealPlan' })
      })
      .catch(() => {})
  }
}
</script>

<style scoped lang="scss">
.content {
  max-width: 500px !important;
}

.main-title {
  text-align: center;
  font-size: 50px;
  line-height: 41px;
  letter-spacing: -0.02em;
}

.v-image {
  max-width: 300px;
  max-height: 300px;
}

.size-300px {
  width: 300px;
  height: 300px;
}
.pb-16px {
  padding-bottom: 16px;
}
p {
  text-align: center;
}

.v-btn {
  width: 300px;
  height: 50px !important;
  border-radius: 15px;
}

.description {
  font-family: Barlow-Bold;
  color: black;
}

.subdescription {
  font-family: barlow_regular;
  font-size: small;
  font: smallcaption !important;
}
.txt-small {
  font-size: 14px !important;
}
::v-deep .v-btn__content {
  font-size: 14px !important;
}
</style>
