<script setup>
// #region Imports
import { reactive, computed, ref, defineProps, defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'

import moment from 'moment'

import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import adminService from '@/services/adminService'
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Props
const props = defineProps(['profileToEdit'])
// #endregion

// #region Emits
const emit = defineEmits(['close'])
// #endregion

// #region Form Data
const formState = reactive({
  profile: {}
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    profile: {
      email: { required, email },
      first_name: { required },
      last_name: { required },
      birthday: { required }
    }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Data
const dialog = ref(true)
const picker = ref(null)
const menuBirthday = ref(null)
const menuBirthdayValue = ref(false)
// #endregion

// #region Computed
const emailErrors = computed(() => {
  if (v$.value.profile.email.$dirty) {
    if (v$.value.profile.email.required.$invalid) {
      return t('message.error.required')
    } else if (v$.value.profile.email.email) {
      return t('message.error.email')
    }
  }
  return []
})
// firstNameErrors() {
//       if (
//         this.$v.profile.first_name.$dirty &&
//         !this.$v.profile.first_name.required
//       ) {
//         return this.$t('message.error.required')
//       }
//       return []
//     },
//     lastNameErrors() {
//       if (
//         this.$v.profile.last_name.$dirty &&
//         !this.$v.profile.last_name.required
//       ) {
//         return this.$t('message.error.required')
//       }
//       return []
//     },
//     birthdayErrors() {
//       if (
//         this.$v.profile.birthday.$dirty &&
//         !this.$v.profile.birthday.required
//       ) {
//         return this.$t('message.error.required')
//       }
//       return []
//     },
const birthdayFormatted = computed(() => {
  return formState.profile.birthday
    ? moment(formState.profile.birthday).format('DD/MM/YYYY')
    : null
})

// #endregion

// #region Methods
const saveProfile = () => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    formState.profile.newsletter = false

    const payload = {
      ...formState.profile,
      birthday: moment(formState.profile.birthday).format('YYYY-MM-DD')
    }

    adminService.createFreeUser(payload).then(() => {
      emit('close')
    })
  }
}
const changeDate = (date) => {
  menuBirthdayValue.value = false
}
// #endregion

// #region Created
formState.profile = props.profileToEdit
// #endregion
</script>

<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2 bg-grey-title">
        Editer le profil
      </v-card-title>
      <v-form @submit.prevent="saveProfile">
        <v-card-text>
          <v-row>
            <v-col cols="6" align="center">
              <v-text-field
                v-model="formState.profile.first_name"
                :error-messages="firstNameErrors"
                @input="v$.profile.first_name.$touch()"
                @blur="v$.profile.first_name.$touch()"
                hide-details
                variant="outlined"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Prénom
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6" align="center">
              <v-text-field
                v-model="formState.profile.last_name"
                :error-messages="lastNameErrors"
                @input="v$.profile.last_name.$touch()"
                @blur="v$.profile.last_name.$touch()"
                hide-details
                variant="outlined"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Nom
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6" align="center">
              <v-text-field
                v-model="formState.profile.email"
                :error-messages="emailErrors"
                @input="v$.profile.email.$touch()"
                @blur="v$.profile.email.$touch()"
                hide-details
                variant="outlined"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Email
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6" align="center">
              <v-menu
                ref="menuBirthday"
                v-model="menuBirthdayValue"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ props: menu }">
                  <v-text-field
                    v-bind="menu"
                    v-model="birthdayFormatted"
                    v-on="on"
                    hide-details
                    readonly
                    variant="outlined"
                    rounded
                  >
                    <template v-slot:label>
                      <span class="checkbox-label">
                        {{ $t('signup.birthday') }}
                        <span class="mandatory">*</span>
                      </span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  color="primary"
                  v-model="formState.profile.birthday"
                  locale="fr-FR"
                  :max="new Date().toISOString().substr(0, 10)"
                  @update:model-value="changeDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="emit('close')"> Fermer </v-btn>
          <v-btn
            aria-label="Valider"
            color="primary"
            type="submit"
            text
            :disabled="v$.$invalid"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.bg-grey-title {
  background-color: #e0e0e0;
}
</style>
