<template>
  <v-container class="wrapper-container pa-0 pa-md-8" fluid>
    <v-container v-show="isInit && isShoppingListInit" class="pa-0">
      <NavigationZone />
      <v-container v-if="isInit" class="pa-4 mb-md-5" fluid>
        <h1>{{ $t('shoppingList.title') }}</h1>
        <label class="label-title">{{ getLabel(beginning_date) }}</label>
        <p class="mt-4 mb-0">
          La liste de courses ci-dessous est prévue pour :
        </p>
        <span class="persons"
          >{{ $store.getters.nbOfPersons }}
          {{ $store.getters.nbOfPersons > 1 ? 'personnes' : 'personne' }}</span
        >
        <p class="mt-4">
          Tu peux modifier ce chiffre dans la rubrique profil symbolisée par le
          logo <v-icon small class="pb-1"> mdi-account </v-icon> dans le menu
          horizontal
        </p>
      </v-container>

      <ShoppingListTables
        :mealPlanId="mealPlanId"
        :fullPage="true"
        @isShoppingListInit="isShoppingListInit = true"
      />

      <v-container v-if="isInit" class="pa-4 mb-md-5" fluid>
        <v-row>
          <v-col class="px-4">
            <ShoppingListNotes></ShoppingListNotes>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <InitLoader v-if="!isInit || !isShoppingListInit" />
  </v-container>
</template>

<script>
import ShoppingListTables from '@/components/shoppingList/ShoppingListTables'
import ShoppingListNotes from '@/components/shoppingList/ShoppingListNotes'
import NavigationZone from '@/components/navigationZone/NavigationZone'
import InitLoader from '@/components/initLoader/InitLoader'
import mixin from '@/mixins/mixin'
import mealPlanService from '@/services/mealPlanService'

export default {
  name: 'ShoppingList',
  props: ['mealPlanId'],
  components: {
    ShoppingListTables,
    ShoppingListNotes,
    NavigationZone,
    InitLoader
  },
  mixins: [mixin],
  data: () => ({
    isInit: false,
    isShoppingListInit: false,
    beginning_date: ''
  }),
  created() {
    if (!this.mealPlanId) this.checkRoute({ name: 'MealPlan' })
    mealPlanService
      .getMealPlan(this.mealPlanId)
      .then((response) => {
        this.beginning_date = response.data.beginning_date
        this.isInit = true
      })
      .catch(() => {})
  }
}
</script>

<style scoped lang="scss">
p {
  color: #050505;
  font-size: 14px;
  line-height: 17px;
}

.persons {
  color: #eb5c43;
  font-size: 14px !important;
  font-weight: bold;
}
</style>
