import axios from 'axios'

export default {
  getConfig() {
    return {
      headers: {
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('tokens'))?.access
      }
    }
  },
  createAdviceSubCategory(adviceCategoryId, adviceSubCategory) {
    return axios
      .post(
        process.env.VUE_APP_API_URL +
        '/administration/advice/category/' +
        adviceCategoryId +
        '/subcategory/',
        adviceSubCategory,
        this.getConfig()
      )
      .then((response) => {
        return response.data
      })
  },
  updateAdviceSubCategory(adviceCategoryId, adviceSubCategory) {
    return axios
      .put(
        process.env.VUE_APP_API_URL +
        '/administration/advice/category/' +
        adviceCategoryId +
        '/subcategory/' +
        adviceSubCategory.id +
        '/',
        adviceSubCategory,
        this.getConfig()
      )
      .then((response) => {
        return response.data
      })
  },
  updateAdviceSubCategoryOrder(adviceCategoryId, newOrders) {
    return axios.put(
      process.env.VUE_APP_API_URL +
      '/administration/advice/category/' +
      adviceCategoryId +
      '/subcategory/reorder/',
      newOrders,
      this.getConfig()
    )
  },
  deleteAdviceSubCategory(adviceCategoryId, adviceSubCategoryId) {
    return axios
      .delete(
        process.env.VUE_APP_API_URL +
        '/administration/advice/category/' +
        adviceCategoryId +
        '/subcategory/' +
        adviceSubCategoryId +
        '/',
        this.getConfig()
      )
      .then((response) => {
        return response.data
      })
  }
}
