<script setup>
// #region Imports
import { reactive, computed } from 'vue'
import { useRouter } from 'vue-router'

import _ from 'lodash'

import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import authService from '@/services/authService'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Form Data
const formState = reactive({
  email: ''
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    email: { required, email }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Computed
const emailErrors = computed(() => {
  if (v$.email?.$dirty) {
    if (!v$.email.required) return this.$t('message.error.required')
    else if (!v$.email.email) return this.$t('message.error.email')
  }
  return []
})
// #endregion

// #region Methods
const save = _.debounce(() => {
  v$.$touch()
  if (!v$.$invalid) {
    const credentials = {
      email: formState.email
    }
    authService.resetEmail(credentials).then(() => {
      checkRoute({ name: 'ResetEmailRedirection' })
    })
  }
}, 1000)
const checkRoute = (name) => {
  router.push(name)
}
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0">
    <v-container class="content fill-height">
      <v-row justify="center" class="ma-1">
        <v-col cols="12" sm="12" align="center">
          <v-container>
            <v-img
              src="@/assets/img/Joana_Horizontal_v1.png"
              style="display: block; margin-left: auto; margin-right: auto"
            >
            </v-img>
          </v-container>
        </v-col>
        <v-col cols="12" align="center">
          <h2>{{ $t('resetEmail.title') }}</h2>
        </v-col>
        <v-col cols="12" align="center">
          <v-text-field
            v-model="formState.email"
            :error-messages="emailErrors"
            type="email"
            @blur="v$.email.$touch()"
            @input="v$.email.$touch()"
            outlined
            rounded
          >
            <template v-slot:label>
              <span class="checkbox-label">
                {{ $t('resetEmail.email') }}
                <span class="mandatory">*</span>
              </span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" align="center">
          <v-btn
            class="confirm-button"
            color="primary"
            @click="save"
            :disabled="v$.$invalid"
          >
            {{ $t('resetEmail.button') }}
          </v-btn>
        </v-col>
        <v-col cols="12" align="center">
          <span class="cancel-button" @click="$router.go(-1)">
            {{ $t('resetPassword.cancel') }}
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped lang="scss">
.content {
  max-width: 500px !important;
}
.main-title {
  font-size: 40px;
  line-height: 41px;
  letter-spacing: -0.02em;
}
.v-input {
  width: 300px;
}
.confirm-button {
  width: 300px;
  height: 50px !important;
  border-radius: 15px;
}
.cancel-button {
  cursor: pointer;
}
</style>
