<script setup>
// #region Imports
import { ref, computed, watch } from 'vue'

import _ from 'lodash'

import { STRIPE_STATUS } from './stripe-status'

import moment from 'moment'

import InitLoader from '@/components/initLoader/InitLoader'
import ConfirmDialog from '@/components/admin/miscellaneous/confirmDialog/ConfirmDialog'
import EditProfileModal from '@/components/admin/profiles/EditProfileModal'
import EditProfileStripeModal from '@/components/admin/profiles/EditProfileStripeModal'
import EditProfileMealPlansModal from '@/components/admin/profiles/EditProfileMealPlansModal'

import adminService from '@/services/adminService'
import authService from '@/services/authService'
// #endregion

// #region Data
const confirm = ref(null)

const isInit = ref(false)
const profiles = ref([])
const page = ref(1)
const totalItems = ref(0)
const itemsPerPage = ref(10)
const search = ref('')
const expanded = ref([])
const editProfileModal = ref(false)
const editProfileStripeModal = ref(false)
const editProfileMealPlansModal = ref(false)
const profileToEdit = ref({})
const emailSentSnackbar = ref(false)
const stripeStatus = ref(STRIPE_STATUS)
const stripeSelectItems = ref([{ id: null, desc: 'Tous' }, ...STRIPE_STATUS])
const stripeFilter = ref(null)

const dietFilter = ref(null)
// #endregion

// #region Computed
const pageCount = computed(() => {
  const totalPages = ~~(totalItems.value / itemsPerPage.value)
  const modulo = totalItems.value % itemsPerPage.value
  return modulo === 0 ? totalPages : totalPages + 1
})
const headers = computed(() => {
  return [
    { title: '', key: 'data-table-expand' },
    { title: 'Prénom', value: 'first_name' },
    { title: 'Nom', value: 'last_name' },
    { title: 'Email', value: 'email' },
    { title: 'Anniversaire', value: 'birthday' },
    { title: 'Sexe', value: 'sex', sortable: true },
    { title: 'Régime', value: 'diet', sortiable: true },
    { title: 'Objectif', value: 'goal', sortiable: true },
    { title: 'Sport', value: 'sport_frequency', sortable: true },
    { title: 'Personnes', value: 'nb_of_persons', sortiable: true },
    {
      title: 'Status',
      value: 'icons',
      sortable: false,
      width: '120px',
      fixed: true
    },
    {
      title: 'Actions',
      value: 'actions',
      sortable: false,
      width: '150px',
      fixed: true
    }
  ]
})
// #endregion

// #region Methods
const editProfile = (item) => {
  profileToEdit.value = item ? { ...item } : {}
  editProfileModal.value = true
}
const editProfileStripe = (item) => {
  profileToEdit.value = item ? { ...item } : {}
  editProfileStripeModal.value = true
}
const editProfileMealPlans = (item) => {
  profileToEdit.value = item ? { ...item } : {}
  editProfileMealPlansModal.value = true
}
const deleteProfile = (item) => {
  confirm.value
    .open(
      'Supprimer le profil',
      'Souhaitez-vous vraiment supprimer ce profil ?',
      { color: 'primary' }
    )
    .then((confirm) => {
      if (confirm) {
        return authService.deleteUserProfile(item.id).then(() => {
          reload()
        })
      } else {
        return null
      }
    })
    .catch((error) => alert(error))
}
const resendActivationEmail = (item) => {
  confirm.value
    .open(
      'Renvoyer le mail de confirmation',
      "Souhaitez-vous vraiment renvoyer l'email de confirmation de compte à l'adresse " +
        item.email +
        '?',
      { color: 'primary' }
    )
    .then((confirm) => {
      if (confirm) {
        const credentials = {
          email: item.email
        }
        authService.resendActivationEmail(credentials).then(() => {
          emailSentSnackbar.value = true
        })
      }
    })
}
const formatDate = (date) => {
  return moment(date).format('DD/MM/YYYY')
}
const sortedMealPlans = (item) => {
  return _.orderBy(item.meal_plans, 'beginning_date')
}
const reload = _.debounce(() => {
  editProfileMealPlansModal.value = false
  editProfileModal.value = false
  editProfileStripeModal.value = false
  adminService
    .getProfiles(page.value, search.value, stripeFilter.value, dietFilter.value)
    .then((response) => {
      totalItems.value = response.data.count
      profiles.value = response.data.results.map((profile) => {
        for (const prop in profile.user) {
          if (prop !== 'id') {
            profile[prop] = profile.user[prop]
          }
        }
        return profile
      })
    })
}, 500)
// #endregion

// #region Watch
watch(page, () => {
  reload()
})
watch(search, () => {
  page.value = 1
  reload()
})
watch(stripeFilter, () => {
  page.value = 1
  reload()
})
watch(dietFilter, () => {
  page.value = 1
  reload()
})
// #endregion

// #region Created
adminService
  .getProfiles()
  .then((response) => {
    totalItems.value = response.data.count
    profiles.value = response.data.results.map((profile) => {
      for (const prop in profile.user) {
        if (prop !== 'id') {
          profile[prop] = profile.user[prop]
        }
      }
      return profile
    })
    isInit.value = true
  })
  .catch(() => {
    alert('Une erreur est survenue.')
  })
// #endregion
</script>

<template>
  <v-container class="admin-wrapper-container" fluid>
    <v-container v-if="isInit" class="pa-0" fluid>
      <h1 class="mb-3">Profils</h1>
      <v-data-table
        :headers="headers"
        :items="profiles"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        class="py-4"
        hide-default-footer
        single-expand
        :expanded.sync="expanded"
        hover
        density="compact"
        item-key="id"
        show-expand
      >
        <template v-slot:bottom></template>
        <template v-slot:top>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="5" class="py-0" align="left">
              <h2 class="ml-5">Liste des profils</h2>
            </v-col>
            <v-col cols="8" sm="6" class="py-0" align="right">
              <v-text-field
                v-model="search"
                variant="outlined"
                rounded
                color="primary"
                label="Rechercher"
                class="mx-4 admin-search-bar"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="1" class="py-0" align="center">
              <v-btn
                icon
                variant="text"
                color="primary"
                style="opacity: 75%"
                @click="editProfile()"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="my-2 mx-2">
            <v-col cols="3">
              <!-- <label class="mb-2">Status stripe</label> -->
              <v-select
                v-model="stripeFilter"
                :items="stripeSelectItems"
                item-value="id"
                item-title="desc"
                label="Status stripe"
                variant="outlined"
                rounded
                color="primary"
              ></v-select>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.birthday`]="{ item }">
          {{ formatDate(item.birthday) }}
        </template>
        <template v-slot:[`item.sex`]="{ item }">
          <span v-if="item.sex === 'man'">Homme</span>
          <span v-if="item.sex === 'woman'">Femme</span>
        </template>
        <template v-slot:[`item.goal`]="{ item }">
          <span v-if="item.goal === 'weight_loss'">Perte de poids</span>
          <span v-if="item.goal === 'weight_stability'">Maintien du poids</span>
        </template>
        <template v-slot:[`item.sport_frequency`]="{ item }">
          <span v-if="item.sport_frequency === 'more_than_2_hours'"
            >Plus de 2h</span
          >
          <span v-if="item.sport_frequency === 'less_than_2_hours'"
            >Moins de 2h</span
          >
        </template>
        <template v-slot:[`item.diet`]="{ item }">
          <span v-if="item.diet === 'vegetarian'">Végétarien</span>
          <span v-if="item.diet === 'flexitarian'">Flexitarien</span>
        </template>
        <template v-slot:[`item.icons`]="{ item }">
          <v-row no-gutters>
            <v-col cols="4" class="py-0" align="right">
              <v-tooltip text="Compte non activé">
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-if="!item.user.is_active"
                    v-bind="props"
                    color="error"
                    class="pr-2"
                    style="opacity: 75%"
                    icon
                    variant="text"
                  >
                    mdi-account-off
                  </v-icon>
                </template>
              </v-tooltip>
            </v-col>
            <v-col cols="4" class="py-0" align="right">
              <v-tooltip text="Abonné à la newsletter">
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-if="item.user.newsletter"
                    v-bind="props"
                    class="pr-2"
                    small
                  >
                    mdi-email
                  </v-icon>
                </template>
              </v-tooltip>
            </v-col>
            <v-col cols="4" class="py-0" align="right">
              <v-tooltip
                v-if="item.stripe_subscription_status"
                :text="
                  stripeStatus.find(
                    (x) => x.id === item.stripe_subscription_status
                  ).desc
                "
              >
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    :color="
                      stripeStatus.find(
                        (x) => x.id === item.stripe_subscription_status
                      ).color
                    "
                    class="pr-2"
                    small
                  >
                    {{
                      stripeStatus.find(
                        (x) => x.id === item.stripe_subscription_status
                      ).icon
                    }}
                  </v-icon>
                </template>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row no-gutters>
            <v-col cols="3" class="py-0" align="right">
              <v-tooltip v-if="!item.is_active" bottom open-delay="500">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    v-on="on"
                    style="opacity: 75%"
                    icon
                    variant="text"
                    @click="resendActivationEmail(item)"
                  >
                    <v-icon> mdi-email-send </v-icon>
                  </v-btn>
                </template>
                Renvoyer le mail de confirmation
              </v-tooltip>
            </v-col>
            <v-col cols="3" class="py-0" align="right">
              <v-tooltip v-if="item.stripe_customer_id" bottom open-delay="500">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    v-on="on"
                    style="opacity: 75%"
                    icon
                    variant="text"
                    :href="
                      'https://dashboard.stripe.com/customers/' +
                      item.stripe_customer_id
                    "
                    target="_blank"
                  >
                    <v-icon> mdi-credit-card </v-icon>
                  </v-btn>
                </template>
                Voir le compte Stripe
              </v-tooltip>
            </v-col>
            <v-col cols="3" class="py-0" align="right">
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    v-on="on"
                    style="opacity: 75%"
                    icon
                    variant="text"
                    @click="editProfileStripe(item)"
                  >
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                Modifier les informations Stripe
              </v-tooltip>
            </v-col>
            <v-col cols="3" class="py-0" align="right">
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    v-on="on"
                    style="opacity: 75%"
                    icon
                    variant="text"
                    @click="deleteProfile(item)"
                  >
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </template>
                Supprimer l'utilisateur
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
        <template v-slot:expanded-row="{ columns, item }">
          <td :colspan="columns.length" class="px-0">
            <v-card flat class="meal-card">
              <v-card-title class="pa-4 pl-6">
                <h3 class="pr-3">Programmes</h3>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      v-on="on"
                      style="opacity: 75%"
                      icon
                      variant="text"
                      @click="editProfileMealPlans(item)"
                    >
                      <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  Modifier les programmes
                </v-tooltip>
              </v-card-title>
              <v-card-text>
                <v-container
                  v-if="sortedMealPlans(item).length === 0"
                  class="pa-0"
                >
                  Aucun programme
                </v-container>
                <v-container v-else class="pa-0">
                  <v-chip
                    v-for="meal_plan in sortedMealPlans(item)"
                    :key="meal_plan.uuid"
                    class="ma-2"
                    label
                    variant="outlined"
                  >
                    {{ formatDate(meal_plan.beginning_date) }}
                  </v-chip>
                </v-container>
              </v-card-text>
            </v-card>
          </td>
        </template>
      </v-data-table>
      <div class="text-center pt-2 mb-8">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="10"
          active-color="primary"
          density="comfortable"
          variant="elevated"
          opacity="100"
        ></v-pagination>
      </div>
      <ConfirmDialog ref="confirm" />
      <v-snackbar v-model="emailSentSnackbar" top>
        Email de confirmation envoyé
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="emailSentSnackbar = false">
            {{ $t('general.button.close') }}
          </v-btn>
        </template>
      </v-snackbar>
      <EditProfileModal
        v-if="editProfileModal"
        :profileToEdit="profileToEdit"
        @close="reload()"
      />
      <EditProfileStripeModal
        v-if="editProfileStripeModal"
        :profileToEdit="profileToEdit"
        @close="reload()"
      />
      <EditProfileMealPlansModal
        v-if="editProfileMealPlansModal"
        :profileToEdit="profileToEdit"
        @close="reload()"
      />
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped lang="scss">
::v-deep .v-data-table-header__content {
  font-weight: bold;
}
.meal-card,
.meal-card > .v-card__title,
.meal-card > .v-card__text {
  border-radius: 0 !important;
}
</style>
