<script setup>
// #region Imports
import { defineProps, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import { useStore } from 'vuex'

import html2pdf from 'html2pdf.js'
import Vue3Html2pdf from 'vue3-html2pdf'
import Markdown from 'vue3-markdown-it'

import InitLoader from '@/components/initLoader/InitLoader'
import NavigationZone from '@/components/navigationZone/NavigationZone'
import DishPdf from '@/components/dish/DishPdf'

import mealPlanService from '@/services/mealPlanService'
import userService from '@/services/userService'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Vuetify
const { smAndDown } = useDisplay()
// #endregion

// #region Store
const store = useStore()
// #endregion

// #region Props
const props = defineProps(['dishId'])
// #endregion

// #region Store Data
const nbOfPersons = computed(() => store.getters.nbOfPersons)
// #endregion

// #region Data
// const html2pdf = ref(null)
// const pdfToPrint = ref(null)
const isInit = ref(false)
const dish = ref({})
const isFav = ref(false)
let quantitiesMultiplicator = 1
// #endregion

// #region Computed
const getPictureUrl = computed(() => {
  let url = ''
  if (dish.value.pictures.length > 0) {
    url = process.env.VUE_APP_API_URL + dish.value.pictures[0].uri
  }
  return url
})
const getAlternativeText = computed(() => {
  return dish.value.pictures[0]?.alternative_text || ''
})
// #endregion

// #region Methods
const getIngredientPictureUrl = (ingredient) => {
  let url = ''
  if (ingredient.pictures?.length > 0) {
    url = process.env.VUE_APP_API_URL + ingredient.pictures[0].uri
  }
  return url
}
const getTagPictureUrl = (uri) => {
  return process.env.VUE_APP_API_URL + '/' + uri
}
const getIngredientName = (ingredient) => {
  let name = ingredient.ingredient.name
  if (ingredient.quantity > 1 && ingredient.ingredient.plural_name) {
    name = ingredient.ingredient.plural_name
  }
  return name.charAt(0).toUpperCase() + name.slice(1)
}
const getFav = () => {
  userService
    .getFav()
    .then((response) => {
      const favs = response.data || []
      isFav.value = favs.some((x) => x.dish.uuid === props.dishId)
    })
    .catch((error) => {
      if (error.response.status === 404) {
        checkRoute({ name: 'MealPlan' })
      }
    })
}
const changeFav = () => {
  if (isFav.value) {
    userService
      .deleteFav(props.dishId)
      .then(() => {
        getFav()
      })
      .catch((error) => {
        if (error.response?.status === 404) getFav()
      })
  } else {
    userService
      .addFav(props.dishId)
      .then(() => {
        getFav()
      })
      .catch((error) => {
        if (error.response?.status === 409) getFav()
      })
  }
}
const printDish = () => {
  const element = document.getElementById('pdfToPrint').innerHTML
  const opt = {
    margin: 1,
    filename: `${dish.value.name.replace(' ', '_')}.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
  }
  html2pdf().set(opt).from(element).save()
}
const checkRoute = (name) => {
  router.push(name)
}
// #endregion

// #region Created
if (!props.dishId) checkRoute({ name: 'MealPlan' })
mealPlanService
  .getDish(props.dishId)
  .then((response) => {
    dish.value = response.data
    getFav()
  }).then(() => mealPlanService.getDishFrequency(dish.value.uuid)).then((data) => {
    // quantitiesMultiplicator = (nbOfPersons.value * data.cpt) / dish.value.portions
    quantitiesMultiplicator = nbOfPersons.value
    isInit.value = true
  })
  .catch(() => {
    alert('Une erreur est survenue.')
  })
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0" fluid>
    <v-container v-if="isInit" class="pa-0" fluid>
      <v-img
        class="main-pic"
        :height="smAndDown ? 300 : 200"
        :src="getPictureUrl"
        :alt="getAlternativeText"
      >
        <NavigationZone :dark="true" />
      </v-img>
      <v-card class="main-card mt-n4" flat>
        <v-row justify="center" class="tags bg-base" no-gutters>
          <v-col cols="7">
            <v-avatar
              v-for="tag in dish.tags"
              :key="tag.name"
              size="35"
              class="tag mx-1"
            >
              <img
                style="width: inherit !important"
                :src="getTagPictureUrl(tag.picture_uri)"
                :alt="tag.picture_alternative_text"
              />
            </v-avatar>
          </v-col>

          <v-col cols="5" align="right">
            <div style="display: flex; justify-content: end">
              <v-btn
                icon
                class="mx-2 icon-btn"
                fab
                color="#272727"
                @click="printDish()"
              >
                <v-icon dark> mdi-printer </v-icon>
              </v-btn>
              <v-btn
                class="mx-2 icon-btn"
                fab
                icon
                dark
                small
                :color="isFav ? 'primary' : '#272727'"
                @click="changeFav()"
              >
                <v-icon dark> mdi-heart </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-card-title class="pt-8 pb-1 bg-base">
          <h1
            class="capitalize"
            style="white-space: normal !important; text-align: left"
          >
            {{ dish.name }}
          </h1>
        </v-card-title>
        <v-card-text class="bg-base">
          <v-container class="pt-0 mb-3 pl-0 txt-small" fluid>
            <span class="txt-small">{{ dish.portions * quantitiesMultiplicator }}</span>
            <span class="sublabel ml-1 pr-0">{{
              $t('dish.portion', dish.portions)
            }}</span>
            <span class="ml-2 mr-2">|</span>
            <v-icon class="mt-n1 mr-2" style="color: rgba(0, 0, 0, 0.54)"
              >mdi-chef-hat</v-icon
            >
            <span class="txt-small">{{ dish.preparation_time }}</span>
            <span class="sublabel ml-1 pr-0">{{ $t('dish.time') }}</span>
            <span class="ml-2 mr-2">|</span>
            <v-icon class="mt-n1 mr-2" style="color: rgba(0, 0, 0, 0.54)"
              >mdi-pot-steam</v-icon
            >
            <span class="txt-small">{{ dish.cooking_time }}</span>
            <span class="sublabel ml-1">{{ $t('dish.time') }}</span>
          </v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-card class="pa-0">
                <v-card-title style="padding: 16px; font-weight: normal">
                  <h2>{{ $t('dish.ingredients') }}</h2>
                </v-card-title>
                <v-card-text>
                  <v-list density="compact">
                    <v-list-item
                      v-for="ingredient in dish.ingredients"
                      :key="
                        ingredient.ingredient.name +
                        ingredient.quantity +
                        ingredient.unit_of_measure.symbol
                      "
                      class="pa-0"
                    >
                      <template v-slot:prepend>
                        <v-list-item-avatar align="center">
                          <img
                            :src="
                              getIngredientPictureUrl(ingredient.ingredient)
                            "
                            class="pic"
                          />
                        </v-list-item-avatar>
                      </template>

                      <template v-slot:title>
                        <div class="space-between">
                          <v-list-item-content class="ml-6">
                            {{ getIngredientName(ingredient) }}
                          </v-list-item-content>
                          <v-list-item-action align="center">
                            <v-list-item-action-text v-if="ingredient.quantity">
                              <span
                                v-if="ingredient.quantity > 0"
                                class="quantity txt-small"
                                :class="
                                  ingredient.unit_of_measure.name === 'unit'
                                    ? 'mr-2'
                                    : ''
                                "
                              >
                                {{ ingredient.quantity * quantitiesMultiplicator }}
                              </span>
                              <span
                                v-if="ingredient.unit_of_measure.name != 'unit'"
                                class="unit_of_measure mr-2"
                              >
                                <span
                                  class="txt-small"
                                  v-if="
                                    ingredient.quantity > 1 &&
                                    ingredient.unit_of_measure.symbol_plural
                                  "
                                >
                                  {{
                                    '&nbsp;' +
                                    ingredient.unit_of_measure.symbol_plural
                                  }}
                                </span>
                                <span class="txt-small" v-else>
                                  {{
                                    '&nbsp;' + ingredient.unit_of_measure.symbol
                                  }}
                                </span>
                              </span>
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </div>
                      </template>
                      <template v-slot:actions> </template>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <v-card class="pa-0">
                <v-card-title style="padding: 16px">
                  <h2>{{ $t('dish.preparation') }}</h2>
                </v-card-title>
                <v-card-text class="pa-6 pt-2 pl-8">
                  <Markdown class="md-body" :source="dish.instructions" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row class="pa-6">
        <v-col class="pa-0" cols="12" sm="6" md="4">
          <v-card>
            <template v-slot:title>
              <v-card-title style="padding: 4px" class="bold">
                <h2 style="white-space: normal !important; text-align: left">
                  Cette recette a été proposée dans les repas suivants :
                </h2>
              </v-card-title>
            </template>

            <v-card-text>
              <ul>
                <li
                  v-for="(item, key, index) in dish.similar_dishes"
                  :key="index"
                >
                  <v-card class="pa-4 mb-5" color="#f3f3f3">
                    <v-card-text>
                      <ul>
                        <li
                          style="text-align: center"
                          v-for="(d, key, index) in item.meal.dishes"
                          :key="index"
                        >
                          {{ d.dish.name }}
                        </li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <DishPdf
        id="pdfToPrint"
        style="display: none"
        ref="pdfToPrint"
        :dish="dish"
      ></DishPdf>
    </v-container>

    <InitLoader v-if="!isInit" />

    <vue3-html2pdf
      v-if="isInit"
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="dish.name.replace(' ', '_')"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
    >
      <template v-slot:pdf-content>
        <DishPdf :dish="dish"></DishPdf>
      </template>
    </vue3-html2pdf>
  </v-container>
</template>

<style scoped lang="scss">
.space-between {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::v-deep .v-img__img--contain {
  object-fit: cover !important;
}
.main-pic:before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;

  background-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 60%,
    rgba(0, 0, 0, 0.3) 70%,
    rgba(0, 0, 0, 0.4) 80%,
    rgba(0, 0, 0, 0.5) 90%,
    rgba(0, 0, 0, 0.6) 95%,
    rgba(0, 0, 0, 0.7) 100%
  );
}

.main-pic {
  object-fit: cover !important;
}

.tags {
  position: absolute;
  margin: -15px 40px 0px 40px !important;
  width: calc(100% - 80px);
  background-color: transparent !important;
}

.main-card {
  overflow: visible;
  border-radius: 20px 20px 0px 0px !important;

  h1 {
    word-break: normal;
  }
}

.bg-base {
  background-color: #f6f6f6;
}

.pic {
  object-fit: cover;
  width: 30px;
  height: 30px;
}

::v-deep .v-list-item__avatar {
  margin-top: 3px;
  margin-bottom: 3px;
}

::v-deep .v-list-item__action-text {
  width: 80px;
  text-align: right;
}

.sublabel {
  font-size: 14px !important;
  padding-right: 15px;
  border-right: solid 1px var(--v-grey-darken-1-base);
}

.sublabel:last-of-type {
  border-right: none;
}

.quantity {
  color: #eb5c43;
}

.unit_of_measure {
  color: var(--v-grey-darken-1-base);
  font-size: 14px !important;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h2 {
  word-wrap: normal;
}
.icon-btn {
  width: 40px !important;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.txt-small {
  font-size: 14px !important;
}
::v-deep .md-body {
  color: #707070;
  list-style-position: outside;
  li {
    padding-left: 8px !important;
    margin-bottom: 0px !important;
  }
}
</style>
