<script setup>
// #region Imports
import { useDisplay } from 'vuetify'
// #endregion

// #region Vuetify
const { mdAndUp } = useDisplay()
// #endregion
</script>

<template>
  <ul>
    <li v-if="mdAndUp" class="mt-2 mb-2">
      Ouvre un navigateur et rejoins le site
      <a href="https://app.joanaetvous.com">https://app.joanaetvous.com</a>
    </li>
    <li li class="mt-2 mb-2">
      Clique sur les trois petits points en haut à droite
      <v-row class="mt-2 mb-2">
        <v-col
          ><v-img
            width="300"
            :src="require('@/assets/img/android1.png')"
          ></v-img
        ></v-col>
      </v-row>
    </li>
    <li class="mt-2">
      Sélectionne «Ajouter à l’écran d’accueil» ou «Installer l'application»
      <v-row class="mt-2 mb-2">
        <v-col
          ><v-img
            width="300"
            :src="require('@/assets/img/android2.png')"
          ></v-img
        ></v-col>
      </v-row>
    </li>
    <li class="mt-2">
      La pastille Joana&Vous vient d’être ajoutée sur ton téléphone
      <v-row align-content="center">
        <v-col align="center">
          <v-img width="50" class="mt-2" src="@/assets/logo.png"></v-img>
        </v-col>
      </v-row>
    </li>
  </ul>
</template>

<srtyle scoped lang="scss">
p,
ul {
  color: #323232;
}

h2 {
  font-weight: 400 !important;
}

h3 {
  font-size: 18px;
  font-weight: bold;
}

span {
  font-size: 14px !important;
  color: #707070;
}

.os-btn {
  span: {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 17px;
  }
}

.iphone-advertising {
  position: relative;
  background-color: #fec46d;
  color: white;
  border-radius: 7px;
  padding: 10px;

  img {
    width: 40px;
    top: -30px;
    height: 40px;
    position: absolute;
    right: 20px;
  }
}
</srtyle>
