<script setup>
// #region Imports
import { reactive, computed, defineProps, defineEmits, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import adminService from '@/services/adminService'
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Props
const props = defineProps(['ingredientToEdit'])
// #endregion

// #region Emits
const emit = defineEmits(['close'])
// #endregion

// #region Form Data
const formState = reactive({
  ingredient: {}
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    ingredient: {
      name: { required },
      category: { required }
    }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Data
const dialog = ref(true)

const categories = ref([])
// const categoriesLoaded = ref([])
const isLoadingCategories = ref(false)
const searchCategories = ref('')
const picturesLoaded = ref([])
const isLoadingPictures = ref(false)
const searchPictures = ref('')
// #endregion

// #region Computed
const nameErrors = computed(() => {
  if (
    v$.value.ingredient.name.$dirty &&
    v$.value.ingredient.name.required.$invalid
  ) {
    return t('message.error.required')
  }
  return []
})

const categoryErrors = computed(() => {
  if (
    v$.value.ingredient.category.$dirty &&
    v$.value.ingredient.category.required.$invalid
  ) {
    return t('message.error.required')
  }

  return []
})

// #endregion

// #region Methods
const remove = (item) => {
  formState.ingredient.pictures = formState.ingredient.pictures.filter(
    (x) => x.id !== item.id
  )
}
const getPictureUrl = (uri) => {
  return process.env.VUE_APP_API_URL + '/' + uri
}
const saveIngredient = () => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    if (formState.ingredient.id) {
      const ingredientToSave = {
        id: formState.ingredient.id,
        name: formState.ingredient.name,
        plural_name: formState.ingredient.plural_name,
        category: formState.ingredient.category.id,
        pictures: []
      }

      if (formState.ingredient.pictures) {
        for (const picture of formState.ingredient.pictures) {
          ingredientToSave.pictures.push(picture.id)
        }
      }
      adminService.updateIngredient(ingredientToSave).then(() => {
        emit('close')
      })
    } else {
      const ingredientToSave = {
        name: formState.ingredient.name,
        plural_name: formState.ingredient.plural_name,
        category: formState.ingredient.category.id,
        pictures: []
      }

      if (formState.ingredient.pictures) {
        for (const picture of formState.ingredient.pictures) {
          ingredientToSave.pictures.push(picture.id)
        }
      }

      adminService.createIngredient(ingredientToSave).then(() => {
        emit('close')
      })
    }
  }
}
// #endregion

// #region Watch
watch(searchCategories, () => {
  isLoadingCategories.value = true
  adminService
    .getIngredientsCategories(null, searchCategories.value)
    .then((response) => {
      categories.value = response.data.results
    })
    .finally(() => (isLoadingCategories.value = false))
})

watch(searchPictures, (newPictures, oldPictures) => {
  isLoadingPictures.value = true
  adminService
    .getPictures(null, searchPictures.value)
    .then((response) => {
      picturesLoaded.value = response.data.results
    })
    .finally(() => (isLoadingPictures.value = false))
})

// #endregion

// #region Created
formState.ingredient = props.ingredientToEdit

isLoadingPictures.value = true
adminService
  .getPictures(null)
  .then((response) => {
    picturesLoaded.value = response.data.results
  })
  .finally(() => (isLoadingPictures.value = false))

isLoadingCategories.value = true
adminService
  .getIngredientsCategories(null)
  .then((response) => {
    categories.value = response.data.results
  })
  .finally(() => (isLoadingCategories.value = false))

// #endregion
</script>

<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2 bg-grey-title">
        Editer ingrédient
      </v-card-title>
      <v-form @submit.prevent="saveIngredient">
        <v-card-text>
          <v-row>
            <v-col cols="12" align="center">
              <v-text-field
                v-model="formState.ingredient.name"
                :error-messages="nameErrors"
                @input="v$.ingredient.name.$touch()"
                @blur="v$.ingredient.name.$touch()"
                hide-details
                variant="outlined"
                color="primary"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Nom
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" align="center">
              <v-text-field
                v-model="formState.ingredient.plural_name"
                label="Pluriel"
                hide-details
                variant="outlined"
                color="primary"
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="12" align="center">
              <v-autocomplete
                v-model="formState.ingredient.category"
                :items="categories"
                item-title="name"
                v-model:search="searchCategories"
                :error-messages="categoryErrors"
                @input="v$.ingredient.category.$touch()"
                @blur="v$.ingredient.category.$touch()"
                return-object
                hide-details
                variant="outlined"
                color="primary"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Catégorie
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="formState.ingredient.pictures"
                :items="picturesLoaded"
                item-title="name"
                v-model:search="searchPictures"
                label="Photo"
                return-object
                multiple
                hide-details
                variant="outlined"
                rounded
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                    v-bind="item"
                    :input-value="item.selected"
                    @click="item.select"
                    @click:close="remove(item.raw)"
                    closable
                  >
                    <v-avatar left>
                      <v-img
                        style="height: 48px"
                        :src="getPictureUrl(item.uri)"
                      ></v-img>
                      {{ item.title }}
                    </v-avatar>
                  </v-chip>

                  <!-- <v-chip
                    v-bind="item"
                    :input-value="item.selected"
                    close
                    @click="item.select"
                    @click:close="remove(item.item)"
                  >
                    <v-avatar left>
                      <v-img :src="getPictureUrl(item.uri)"></v-img>
                      {{ item }}
                    </v-avatar>
                    {{ item.name }}
                  </v-chip> -->
                </template>
                <template v-slot:item="{ props, item }">
                  <!-- <template v-if="typeof item !== 'object'">
                    <v-list-item-content
                      v-text="item.raw"
                    ></v-list-item-content>
                  </template> -->
                  <v-list-item v-bind="props">
                    <template v-slot:prepend>
                      <v-list-item-avatar>
                        <img
                          :width="48"
                          :height="48"
                          :src="getPictureUrl(item.raw.uri)"
                        />
                      </v-list-item-avatar>
                    </template>

                    <template v-slot:title>
                      <v-list-item-title class="ml-4"
                        ><span class="ml-4 font-bold">
                          {{ item.raw.name }}
                        </span></v-list-item-title
                      >
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('close')"> Fermer </v-btn>
          <v-btn
            aria-label="Valider"
            color="primary"
            type="submit"
            text
            :disabled="v$.$invalid"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped>
::v-deep .v-avatar.v-avatar--density-default {
  height: calc(var(--v-avatar-height) + 0px);
  width: auto;
}
.bg-grey-title {
  background-color: #e0e0e0;
}
.font-bold {
  font-weight: bold;
}
</style>
