import axios from 'axios'

export default {
  getConfig() {
    return {
      headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('tokens'))?.access }
    }
  },
  getMealPlan(mealPlanId) {
    const param = mealPlanId ? mealPlanId + '/' : ''
    return axios.get(process.env.VUE_APP_API_URL + '/meal-plan/' + param, this.getConfig())
  },
  getShoppingList(mealPlanId) {
    return axios.get(process.env.VUE_APP_API_URL + '/shopping-list/' + mealPlanId + '/', this.getConfig())
  },
  getHistory() {
    return axios.get(process.env.VUE_APP_API_URL + '/meal-plan-history/', this.getConfig())
  },
  getWeekday(weekdayId) {
    return axios.get(process.env.VUE_APP_API_URL + '/weekday/' + weekdayId + '/', this.getConfig())
  },
  getDish(dishId) {
    return axios.get(process.env.VUE_APP_API_URL + '/dish/' + dishId + '/', this.getConfig())
  },
  getDishFrequency(dishUuid) {
    return axios.get(process.env.VUE_APP_API_URL + '/dish-frequency/', this.getConfig()).then((response) => response.data.find((x) => x.uuid === dishUuid))
  },
  // getDishDetails(dishId) {
  //   return axios.get(process.env.VUE_APP_API_URL + '/dish/' + dishId + '/', this.getConfig())
  // },
  getFullMealPlan(mealPlanId) {
    return axios.get(process.env.VUE_APP_API_URL + '/meal-plan/details/' + mealPlanId + '/', this.getConfig())
  },
  getAdvices() {
    return new Promise(resolve => {
      resolve([
        {
          title: 'Les équivalences',
          adv: [
            {
              title: 'Les fruits',
              text: '*Les quantités de fruits suivantes sont toutes équivalentes. Tu peux remplacer un fruit par un autre dans le programme en suivant ces équivalences.*\n' +
                '- 1⁄4 d’ananas\n' +
                '- 1⁄4 de pastèque\n' +
                '- 1⁄2 de melon\n' +
                '- 1⁄2 mangue\n' +
                '- 1 pomme\n' +
                '- 1 poire\n' +
                '- 1 orange\n' +
                '- 1 pêche\n' +
                '- 1 nectarine\n' +
                '- 1 brugnon\n' +
                '- 1 banane\n' +
                '- 1 pamplemousse\n' +
                '- 1 papaye\n' +
                '- 1 goyave\n' +
                '- 2 clémentines\n' +
                '- 2 kiwis\n' +
                '- 2 figues fraiches\n' +
                '- 3 prunes\n' +
                '- 3 abricots\n' +
                '- 6 litchis\n' +
                '- 15 raisins\n' +
                '- 12 cerises\n' +
                '- 250 g de fraises\n' +
                '- 250 g de framboises\n' +
                '- 250 g de myrtilles\n' +
                '- 250 g de groseilles\n' +
                '- 30g de fruits secs : raisins, abricots, pruneaux...'
            },
            {
              title: 'Les féculents',
              text: '*Pour les féculents on parle d’équivalence entre le poids cru (avant cuisson) et le poids cuit (après cuisson)*\n\n' +
                'Le poids cuit = 3x le poids cru.\n\n' +
                'Ex : 50g de pâtes crues = 150g de pâtes cuites (soit 50x3 = 150g)'
            },
            {
              title: 'Les légumes',
              text: '*Il est possible de remplacer un légume frais par un légume surgelé. Dans ce cas, voici une idée d’équivalence entre le poids d’un légumes frais et celui d’un légume surgelé.*\n\n' +
                '- 500g d’épinards surgelés = 850g d’épinards frais\n' +
                '- 500g de carottes surgelées = 850g de carottes fraiches (avec la peau)\n' +
                '- 500g de courges surgelées = 850g de courges fraiches (avec la peau)\n' +
                '- 500g de choux-fleurs surgelés = 1200g de choux-fleurs frais (avec la tige)\n' +
                '- 500g de petits pois surgelés = 1200g de petits pois frais (avec les coques)\n' +
                '- 500g d’haricots verts surgelés = 650g d’haricots verts frais (avec les tiges)'
            },
            {
              title: 'Les protéines',
              text: '*Il est possible de remplacer une source de protéines par une autre.*\n\n' +
                'Voici les équivalences : 100g de viande = 100g de poisson = 100g d’œuf = 100g de soja = 60g de légumineuses crues\n\n' +
                'Voici quelques exemples de légumineuses : pois chiche, haricots rouges, haricots de lima, flageolets, fèves, lentilles...\n\n' +
                'Les légumineuses proposées en conserve sont cuites. 60g de légumineuses crues = 180g de légumineuses cuites.'
            },
            {
              title: 'Les laitages',
              text: '*Il est possible de remplacer un laitage par un autre en fonction de tes goûts.*\n\n' +
                'Tous les laitages sont autorisés à condition de respecter cette règle :\n' +
                '- Lipides : taux inférieur ou égal à 3g pour 100g soit 3% de matières grasses\n' +
                '- Glucides : taux inférieur ou égal à 13g pour 100g soir 12% de sucres\n\n' +
                'L’idéal est de consommer des laitages natures que tu viens sucrer toi-même si besoin avec 1 cuillère à café bombée de sucre roux, sirop d’agave, miel, sirop d’érable, crème de marron, confiture, compote...'
            },
            {
              title: 'Les pesées',
              text: 'Dans le programme, toutes les quantités sont proposées en gramme (g) et/ou millilitre (ml). Pour faire simple, tu peux considérer que : 10 ml = 10g pour tous les aliments.\n\n' +
                'Exemple : 200g de crème fraîche = 200ml de crème fraîche\n\n' +
                'Parfois, sur les paquets, la quantité est notée en centilitre (cl). Dans ce cas, il suffit de rajouter un 0 à la fin du chiffre pour avoir la mesure en ml : 10 cl = 100ml\n\n' +
                'Exemple : 20 cl de crème fraiche = 200ml de crème fraîche'
            }
          ]
        },
        {
          title: 'Liste de courses',
          adv: [
            {
              title: 'Utilisation',
              text: 'La liste de courses te permet de connaître tous les ingrédients dont tu as besoin pour suivre le menu de la semaine. Tu n\'as pas besoin de tout acheter, si tu as déjà des choses à la maison. Raye de ta liste de courses, les aliments qui sont déjà dans tes placards/frigo. Exemple : beurre, huile, épices, aromates etc...'
            }
          ]
        },
        {
          title: 'Les conseils pratiques',
          adv: [
            {
              title: 'Conseils',
              text: 'Pour amasser un max de motivation et de conseils, rejoins nos deux communautés : Facebook & Instagram.\n\n' +
                'Rejoins la [communauté Facebook ici](https://www.facebook.com/groups/2053302144706012/) afin de pouvoir poser toutes tes questions. Ecris un message privé à Joana sur instagram [@joanaetvous](https://www.instagram.com/joanaetvous/) en précisant ton nom, prénom et le souhait de rejoindre la communauté "amis proches" pour avoir accès aux stories privées de Joana sur instagram.\n\n' +
                'En attendant, voici quelques infos. Pour éviter le gaspillage, je te conseille de congeler certains produits afin de les conserver plus longtemps.\n' +
                '- Le pain: la congélation se fait en tranches dans un sac plastique hermétique. La décongélation se fait au grille-pain ou au four en 3 minutes sinon à température ambiante dans un torchon propre en 30 minutes à 1 heure. Ce fonctionnement est efficace aussi pour les pancakes, gaufres etc...\n' +
                '- Les aromates comme le basilic, la ciboulette peut être congelée propre et coupée dans un sac plastique hermétique ou un bocal en verre. La décongélation se fait instantanément dans la préparation.\n' +
                '- Les restes peuvent être congelés à la seule condition qu’aucun aliment de la préparation n’est été congelé au préalable.'
            }
          ]
        },
        {
          title: 'Comment personnaliser le programme ?',
          adv: [
            {
              title: 'Les goûts et les couleurs',
              text: 'Un des objectifs du programme est de t\'aider à renouer avec certains aliments que tu crois ne pas aimer. Pour cela, je te propose des plats gourmands cuisinés différemment. *Je t\'encourage donc à regoûter tous les aliments que tu penses ne pas aimer au moins deux fois avant d\'affirmer définitivement que tu ne les aimes pas !*\n\n' +
                'Sinon, tu peux remplacer n\'importe quel aliment par un autre de la même catégorie.\n\n' +
                'Ex : fromage de chèvre vs mozzarella ou brocolis vs haricots verts.\n\n' +
                'Si tu as un doute, tu peux poser la question sur le [groupe Facebook](https://www.facebook.com/groups/2053302144706012/)'
            },
            {
              title: 'Les fruits et légumes de saison',
              text: 'Les fruits et légumes que je te propose dans le programme sont tous de saison. C\'est une valeur importante pour moi. Ecologiquement c\'est mieux mais aussi ils sont bien plus riches en vitamines et minéraux. L’objectif numéro un du programme c’est de t’aider à améliorer ton alimentation. Si c’est quelque chose de difficile à suivre pour toi, tu peux remplacer certains légumes par d’autres même s’ils ne sont pas de saison. Chaque chose en son temps. Tu trouveras plein de recettes hors saison sur [mon site internet](https://joanaetvous.com/)'
            },
            {
              title: 'Echanger un repas',
              text: 'Tu peux échanger un repas avec un autre à une condition : ne pas échanger un déjeuner avec un diner car les besoins de notre corps sont différents à ces deux moments de la journée. Tu peux donc échanger les diners avec les diners et les déjeuners avec les déjeuners.'
            },
            {
              title: 'Le petit déjeuner',
              text: 'Dans le programme, je te propose des idées de petit dej\' équilibré pour te faire varier les apports et t\'en faire découvrir de nouveaux. L’idéal c’est d\'alterner entre deux petits déjeuner. Si tu le souhaites, tu peux adapter les petits déjeuner à tes goûts en fonction de tes découvertes. Tu peux donc consommer les petits dej\' des semaines précédentes si tu les préfères.'
            },
            {
              title: 'Le déjeuner au travail',
              text: 'Si tu as un objectif d\'amincissement, je t\'encourage à consommer les déjeuners prévus au programme même sur ton lieu de travail. Si tu n\'as pas le choix et que tu déjeunes à l\'extérieur au quotidien (self, restaurants etc..) essaye de respecter les catégories d\'aliments proposés dans le programme : protéines (viande, poisson, œuf) + féculents + légumes + dessert (fruit ou laitage). N\'oublie pas de modifier la liste de courses. Pour cela, raye de ta liste de courses les aliments qui composent les repas du midi.'
            },
            {
              title: 'La collation',
              text: 'En tant qu\'adulte, notre organisme n\'a pas nécessairement besoin d\'une collation. Si tu en ressens le besoin, tu peux en consommer une. Pour te guider, j\'ai fait une vidéo à ce sujet que tu peux regarder en replay dans le groupe Facebook.\n' +
                'Pour le retrouver clique sur le dossier "annonces" situé en haut de la [page du groupe](https://www.facebook.com/groups/2053302144706012/)'
            },
            {
              title: 'Les repas libres',
              text: 'Chaque semaine, il y a deux repas libres. Ils sont faits pour que tu puisses consommer un repas sans "cadre" mais ce ne sont pas obligatoirement des « cheat meal ». J’aimerais que tu déconstruises cette croyance qui dit qu’on doit se restreindre toute la semaine et faire un repas « plaisir » où on consomme énormément de calories. En suivant le programme, tu n’es plus au régime mais en train d\'adopter une alimentation équilibrée et gourmande. Tu n\'as donc plus besoin de consommer des aliments gras et caloriques à chaque repas libre puisque tu n\'en es pas privée au cours de la semaine. Les repas libres te donnent l’occasion de cuisiner tes plats fétiches, de refaire tes recettes coup de cœur, d’aller au restaurant, chez des amis, de faire un fast food de temps en temps etc...'
            },
            {
              title: 'Comment suivre le programme à plusieurs ?',
              text: 'Le programme est créé pour 1 personne !\n\n' +
                'Pour adapter les quantités à la famille, il te suffit de multiplier les quantités de la liste de courses et des recettes par le nombre de personnes présentes dans ta famille.\n\n' +
                'Certaines recettes sont proposées pour 2 portions ou + cela **ne veut pas** dire qu\'elles sont pour 2 personnes ou +. Cela signifie que tu vas consommer cette recette au cours de deux repas différents dans la semaine. Pour l\'adapter à la famille, tu dois quand même multiplier les quantités de ces recettes.\n\n' +
                'Je t’encourage à suivre le programme en couple et/ou en famille car cela va te faciliter la vie.\n\n' +
                'Pour ton homme, il faut légèrement adapter les quantités selon deux possibilités :\n' +
                '- Il a un objectif de perte de poids. Dans ce cas, il suit le programme à la lettre en ajoutant uniquement 30 à 40g de féculents crus à la portion dès qu’il y en a au menu et une collation s’il en ressent le besoin.\n' +
                '- Il n’a pas d’objectif de perte de poids. Dans ce cas, il peut suivre le programme en ajoutant 40g à 50g de féculents crus à la portion dès qu’il y en a au menu. Je lui conseille aussi d’ajouter une collation et éventuellement une portion de féculents au diner s’il a un métier physique (ou très faim).'
            }
          ]
        }
      ])
    })
  }
}
