<script setup>
// #region Imports
import { ref, computed, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import _ from 'lodash'

import { useVuelidate } from '@vuelidate/core'
import { sameAs } from '@vuelidate/validators'

import authService from '@/services/authService'
// #endregion

// #region Router
const route = useRoute()
const router = useRouter()
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Data
const tac = ref('')
// #endregion

// #region Form Data
const formState = reactive({
  validateTAC: false
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    validateTAC: { checked: sameAs(true) }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Computed
const validateTACErrors = computed(() => {
  if (v$.value.validateTAC?.$dirty && v$.validateTAC?.checked.$invalid) {
    return t('message.error.required')
  }
  return []
})
// #endregion

// #region Created
authService.getTAC().then((response) => {
  tac.value = response.data.body
})
// #endregion

// #region Methods
const validateAccount = _.debounce(() => {
  const credentials = {
    uid: route.params.uuid,
    token: route.params.token,
    agreement: true
  }
  authService
    .activateFreeAccount(credentials)
    .then(() => {
      setTimeout(() => checkRoute({ name: 'Login' }), 5000)
    })
    .catch(() => {
      checkRoute({
        name: 'Login',
        params: {
          messages: [{ type: 'error', text: t('signup.activateAccount.error') }]
        }
      })
    })
}, 1000)
const checkRoute = (name) => {
  router.push(name)
}
// #endregion
</script>

<template>
  <v-container class="pa-0">
    <v-container class="content fill-height">
      <v-row justify="center" class="ma-1">
        <v-col cols="12" sm="12" align="center">
          <v-container class="main-title text-uppercase">{{
            $t('general.title')
          }}</v-container>
        </v-col>
        <v-col cols="12" align="center">
          <span>{{ $t('signup.activateFreeAccount') }}</span>
        </v-col>
        <v-col cols="12" align="center">
          <v-card class="pa-md-6" elevation="0">
            <v-card-text>
              <markdown-it-vue class="md-body" :content="tac" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" align="center" class="py-0">
          <v-checkbox
            v-model="formState.validateTAC"
            class="mt-3"
            :error-messages="validateTACErrors"
            @input="v$.validateTAC.$touch()"
            @blur="v$.validateTAC.$touch()"
            hide-details
          >
            <template v-slot:label>
              <span>
                <span class="checkbox-label mr-1">{{
                  $t('signup.tac.validateTAC') + ' ' + $t('signup.tac.TAC')
                }}</span>
                <span class="checkbox-label mandatory">*</span>
              </span>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" align="center">
          <v-btn
            class="confirm-button mt-3"
            color="primary"
            @click="validateAccount"
            :disabled="v$.$invalid"
          >
            {{ $t('signup.button') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped lang="scss">
.content {
  max-width: 800px !important;
}
.main-title {
  font-size: 40px;
  line-height: 41px;
  letter-spacing: -0.02em;
}

.v-card__text {
  height: 300px;
  overflow: auto;
}
</style>
