<script setup>
// #region Imports
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import moment from 'moment'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Props
const props = defineProps(['history', 'currentMealPlanId'])
// #endregion

// #region Methods
const isCurrentMealPlan = (mealPlan) => {
  if (props.currentMealPlanId && mealPlan.uuid === props.currentMealPlanId) {
    return 'current-mealPlan'
  } else return ''
}
const getTitle = (mealPlan) => {
  const mealPlanDate = moment(mealPlan.beginning_date)
  const firstDayOfCurrentWeek = moment().weekday(0)
  const currentYear = moment().year()
  const mealplanYear = moment(mealPlan.beginning_date).year()
  if (
    mealPlanDate.format('w') === firstDayOfCurrentWeek.format('w') &&
    currentYear === mealplanYear
  ) {
    return t('history.currentWeek')
  } else if (mealPlanDate.isAfter(firstDayOfCurrentWeek, 'day')) {
    return t('history.nextWeek')
  }
}
const checkRoute = (name) => {
  router.push(name)
}
const getLabel = (date) => {
  const firstDayMonth = moment(date).format('MMMM')
  const firstDay = moment(date).format('DD')
  const lastDayMonth = moment(date).add(6, 'd').format('MMMM')
  const lastDay = moment(date).add(6, 'd').format('DD')
  if (firstDayMonth !== lastDayMonth) {
    return t('history.periodDifferentMonth', {
      startDay: firstDay,
      startMonth: firstDayMonth,
      endDay: lastDay,
      endMonth: lastDayMonth
    })
  } else {
    return t('history.periodSameMonth', {
      start: firstDay,
      end: lastDay,
      month: firstDayMonth
    })
  }
}
// #endregion
</script>

<template>
  <v-list density="compact" class="history-list pa-0">
    <v-list-item
      class="pa-0"
      v-for="(mealPlan, i) in history"
      :key="i"
      @click="
        checkRoute({ name: 'MealPlan', params: { mealPlanId: mealPlan.uuid } })
      "
    >
      <template v-slot:prepend>
        <v-list-item-icon>
          <v-container class="pa-1">
            <div class="icon-wrapper">
              <img
                style="width: 50px"
                :src="require('@/assets/img/simple-' + (i + 1) + '.png')"
              />
            </div>
          </v-container>
        </v-list-item-icon>
      </template>
      <template v-slot:title>
        <v-list-item-title
          :class="isCurrentMealPlan(mealPlan)"
          class="pb-1 txt-gray txt-small"
          >{{ getTitle(mealPlan) }}
          <span class="txt-gray txt-small">{{
            getLabel(mealPlan.beginning_date)
          }}</span></v-list-item-title
        >
      </template>
      <template v-slot:subtitle>
        <v-list-item-subtitle
          :class="isCurrentMealPlan(mealPlan)"
          class="meal-subtitle"
        >
          <span
            :class="[
              mealPlan.diet === 'vegetarian' ? 'vegetarian' : 'flexitarian'
            ]"
            >{{
              mealPlan.diet === 'vegetarian' ? 'Végétarien' : 'Flexitarien'
            }}</span
          >
        </v-list-item-subtitle>
      </template>
    </v-list-item>
    <!-- <v-list-item
      v-for="(mealPlan, i) in history"
      :key="i"
      class="pa-0"
      @click="
        checkRoute({ name: 'MealPlan', params: { mealPlanId: mealPlan.uuid } })
      "
    >
      <v-list-item-icon>
        <v-container class="pa-1">
          <div class="icon-wrapper">
            <img :src="require('@/assets/img/simple-' + (i + 1) + '.png')" />
          </div>
        </v-container>
      </v-list-item-icon>
      <v-list-item-content :class="isCurrentMealPlan(mealPlan)">
        <v-list-item-title class="pb-1">{{
          getTitle(mealPlan)
        }}</v-list-item-title>
        <v-list-item-subtitle class="meal-subtitle">
          <span>{{ getLabel(mealPlan.beginning_date) }}</span
          ><br />
          <span
            :class="[
              mealPlan.diet === 'vegetarian' ? 'vegetarian' : 'flexitarian'
            ]"
            >{{
              mealPlan.diet === 'vegetarian' ? 'Végétarien' : 'Flexitarien'
            }}</span
          >
        </v-list-item-subtitle>
      </v-list-item-content> -->
    <!-- </v-list-item> -->
  </v-list>
</template>

<style scoped lang="scss">
.v-list {
  border-radius: 0px 0px 20px 20px !important;
}

.v-list-item {
  border-bottom: solid 1px var(--v-background-base);
}

.v-list-item:last-child {
  border-radius: 0px 0px 20px 20px;
  border-bottom: none;
}

.v-list-item__icon {
  height: 60px !important;
  width: 60px !important;
  border-radius: 0px 20px 20px 0px;
  margin: 0px 20px 0px 0px !important;

  img {
    width: 50px;
    height: 50px;
  }
}

.v-list-item:last-child .v-list-item__icon {
  border-radius: 0px 20px 20px 20px;
}

.v-list-item:last-child .v-list-item__content {
  border-radius: 0px 0px 20px 0px;
}

.current-mealPlan > .v-list-item__title,
.current-mealPlan > .v-list-item__subtitle {
  color: var(--v-primary-base) !important;
}

.meal-subtitle {
  height: 24px;
  .vegetarian {
    background-color: #41553d;
    padding: 2px 3px;
    border-radius: 5px;
    color: white;
    width: 68px;
    height: 20px;
    font-size: 10px !important;
    font-family: 'made-gentle';

    display: block;

    text-align: center;
  }

  .flexitarian {
    background-color: #fec46d;
    padding: 2px 3px;
    border-radius: 5px;
    color: white;
    width: 68px;
    height: 20px;
    font-size: 10px !important;
    font-family: 'made-gentle';
    display: block;
    text-align: center;
  }
}

.icon-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.txt-gray {
  color: #707070 !important;
}
.txt-small {
  font-size: 14px !important;
}
</style>
