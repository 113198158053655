<script setup>
// #region Imports
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Props
const props = defineProps(['weekdayId', 'weekdayDate'])
// #endregion

// #region Methods
const checkRoute = (name) => {
  router.push(name)
}
// #endregion
</script>

<template>
  <v-card
    class="day mr-4 ml-1"
    elevation="3"
    @click="
      checkRoute({
        name: 'Weekday',
        params: { weekdayId: props.weekdayId }
      })
    "
  >
    <v-card-text align="center" class="pa-0">
      <img
        class="pt-3 pb-1"
        :src="
          require('@/assets/img/weekday-' +
            props.weekdayDate.isoWeekday() +
            '.png')
        "
      />
      <h2 class="capitalize">{{ props.weekdayDate.format('dddd') }}</h2>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
.day {
  height: 180px;
  width: 140px;

  img {
    width: 120px;
  }
}

.day:first-of-type {
  margin-left: 6px !important;
}

.day:last-of-type {
  margin-right: 6px !important;
}
</style>
