<script setup>
// #region Imports
import { ref, computed, onUpdated } from 'vue'
import { useRouter } from 'vue-router'

import EditAdviceCategoryModal from '@/components/admin/joanaAdvices/EditAdviceCategoryModal'
import ConfirmDialog from '@/components/admin/miscellaneous/confirmDialog/ConfirmDialog'
import InitLoader from '@/components/initLoader/InitLoader'

import Sortable from 'sortablejs'

import joanaAdviceCategoryService from '@/services/adminJoanaAdvice/joanaAdviceCategoryService'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Data
const confirm = ref(null)

const isInit = ref(false)
const editAdviceCategoryModal = ref(false)
const adviceCategoryToManage = ref(null)
const page = ref(1)
const totalItems = ref(0)
const itemsPerPage = ref(10)
const adviceCategories = ref([])
// #endregion

// #region Computed
const pageCount = computed(() => {
  const totalPages = ~~(totalItems.value / itemsPerPage.value)
  const modulo = totalItems.value % itemsPerPage.value
  return modulo === 0 ? totalPages : totalPages + 1
})
const headers = computed(() => {
  return [
    { title: 'Nom', sortable: false, value: 'name' },
    {
      title: '',
      value: 'edit',
      sortable: false,
      width: '30px',
      fixed: true
    },
    {
      title: '',
      value: 'delete',
      sortable: false,
      width: '30px',
      fixed: true
    }
  ]
})
// #endregion

// #region Methods
const editAdviceCategory = (item) => {
  adviceCategoryToManage.value = { ...item }
  editAdviceCategoryModal.value = true
}
const deleteAdviceCategory = (id) => {
  const deletedItem = adviceCategories.value.find((x) => x.id === id)

  confirm.value
    .open(
      'Supprimer la catégorie',
      'Souhaitez-vous vraiment supprimer cette catégorie ?',
      { color: 'primary' }
    )
    .then((confirm) => {
      if (confirm) {
        joanaAdviceCategoryService
          .deleteAdviceCategory(id)
          .then(() => {
            const index = adviceCategories.value.findIndex((x) => x.id === id)
            adviceCategories.value.splice(index, 1)
            totalItems.value = adviceCategories.value.length
          })
          .then(() => {
            const list = adviceCategories.value
            const newOrders = []
            for (const item of list) {
              if (item.order > deletedItem.order) {
                newOrders.push({ id: item.id, order: item.order - 1 })
              } else {
                newOrders.push({ id: item.id, order: item.order })
              }
            }
            return joanaAdviceCategoryService.updateAdviceCategoryOrder(
              newOrders
            )
          })
          .then((adviceCategories) => {
            adviceCategories.value = adviceCategories
          })
          .catch((error) => {
            alert(error)
          })
      }
    })
}
const closeModal = () => {
  editAdviceCategoryModal.value = false
}
const submitModal = (data) => {
  editAdviceCategoryModal.value = false
  if (data.id) {
    joanaAdviceCategoryService
      .updateAdviceCategory(data.id, { name: data.name, order: data.order })
      .then((data) => {
        const index = adviceCategories.value.findIndex((x) => x.id === data.id)
        const updatedList = [...adviceCategories.value]
        updatedList[index] = { ...data }
        adviceCategories.value = updatedList
      })
      .catch((error) => {
        alert(error)
      })
  } else {
    data.order = adviceCategories.value.length + 1
    joanaAdviceCategoryService
      .createAdviceCategory(data)
      .then((newAdviceCategory) => {
        if (newAdviceCategory.id) {
          adviceCategories.value.push(newAdviceCategory)
        }
        totalItems.value = adviceCategories.value.length
      })
      .catch((error) => {
        alert(error)
      })
  }
}
const initSortable = () => {
  const table = document.querySelector('tbody')
  const vueObj = this
  Sortable.create(table, {
    onEnd({ newIndex, oldIndex }) {
      const data = vueObj._data.adviceCategories
      const moveId = data[oldIndex].id
      const newPosition = newIndex + 1
      const oldPosition = oldIndex + 1
      const newOders = []
      for (const item of vueObj._data.adviceCategories) {
        if (
          moveId !== item.id &&
          item.order >= newPosition &&
          item.order < oldPosition
        ) {
          newOders.push({ id: item.id, order: item.order + 1 })
        } else if (
          moveId !== item.id &&
          item.order > oldPosition &&
          item.order <= newPosition
        ) {
          newOders.push({ id: item.id, order: item.order - 1 })
        } else if (item.id === moveId) {
          newOders.push({ id: item.id, order: newPosition })
        } else {
          newOders.push({ id: item.id, order: item.order })
        }
      }
      joanaAdviceCategoryService
        .updateAdviceCategoryOrder(newOders)
        .then(() => {})
        .catch((error) => {
          alert(error)
        })
    }
  })
}
const goToSubCategory = (event, categoryId) => {
  router.push(`/admin/joana-advices-categories/${categoryId}/sub-categories`)
}
// #endregion

// #region Updated
onUpdated(() => {
  initSortable()
})
// #endregion

// #region Created
joanaAdviceCategoryService
  .getAdviceCategories()
  .then((data) => {
    adviceCategories.value = data
    totalItems.value = adviceCategories.value.length
    isInit.value = true
  })
  .catch((error) => {
    alert(error)
  })
// #endregion
</script>

<template>
  <v-container class="admin-wrapper-container" fluid>
    <v-container v-if="isInit" class="pa-0" fluid>
      <h1 class="mb-3">Les conseils de Joana</h1>

      <v-data-table
        :headers="headers"
        :items="adviceCategories"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        :sortable="true"
        hover
        class="py-4"
        density="compact"
        no-filter
        hideDefaultFooter
      >
        <template v-slot:bottom></template>

        <template v-slot:top>
          <v-row justify="center" align="center">
            <v-col cols="8" sm="11" align="left">
              <h2 class="ml-5">Catégories de conseils</h2>
            </v-col>
            <v-col cols="4" sm="1" align="center">
              <v-btn
                icon
                variant="text"
                color="primary"
                :elevation="0"
                @click="editAdviceCategory()"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item="{ item }">
          <tr class="item">
            <td class="category-name" @click="goToSubCategory($event, item.id)">
              {{ item.name }}
            </td>
            <td>
              <v-btn
                style="opacity: 75%"
                icon
                variant="text"
                @click="editAdviceCategory(item)"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                style="opacity: 75%"
                icon
                variant="text"
                @click="deleteAdviceCategory(item.id)"
              >
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <div class="text-center pt-2 mb-8">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="10"
          active-color="primary"
          density="comfortable"
          variant="elevated"
          opacity="100"
        ></v-pagination>
      </div>

      <EditAdviceCategoryModal
        v-if="editAdviceCategoryModal"
        :adviceCategoryToManage="adviceCategoryToManage"
        @close="closeModal()"
        @submitModal="(data) => submitModal(data)"
      />

      <ConfirmDialog ref="confirm" />
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped>
.category-name {
  cursor: pointer;
}
.v-table__wrapper {
  overflow: hidden !important;
}
::v-deep .v-data-table-header__content {
  font-weight: bold;
}
</style>
