const mixin = {
  methods: {
    getLabel(date) {
      const firstDayMonth = this.$moment(date).format('MMMM')
      const firstDay = this.$moment(date).format('DD')
      const lastDayMonth = this.$moment(date).add(6, 'd').format('MMMM')
      const lastDay = this.$moment(date).add(6, 'd').format('DD')
      if (firstDayMonth !== lastDayMonth) {
        return this.$t('history.periodDifferentMonth', {
          startDay: firstDay,
          startMonth: firstDayMonth,
          endDay: lastDay,
          endMonth: lastDayMonth
        })
      } else return this.$t('history.periodSameMonth', { start: firstDay, end: lastDay, month: firstDayMonth })
    },
    getWeekNumberOfMonth(date) {
      const weekNum = this.$moment(date).week() - this.$moment(date).startOf('month').week() + 1
      let month = this.$moment(date).format('MMMM')
      month = month[0].toUpperCase() + month.slice(1)
      this.$t('mealPlan.history.weekNumber', { month: month, number: weekNum })
      return this.$t('mealPlan.history.weekNumber', { month: month, number: weekNum })
    },
    // Prevent the duplicate navigation error
    checkRoute(route) {
      this.$router.push(route).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          // console.error(err)
        }
      })
    }
  }
}

export default mixin
