<script setup>
// #region Imports
import { ref, defineProps, watch, defineEmits, computed } from 'vue'
import { useStore } from 'vuex'

import moment from 'moment'

import mealPlanService from '@/services/mealPlanService'
// #endregion

// #region Props
const props = defineProps(['mealPlanId', 'fullPage'])
// #endregion

// #region Emits
const emit = defineEmits(['isShoppingListInit'])
// #endregion

// #region Store
const store = useStore()
// #endregion

// #region Store Data
const nbOfPersons = computed(() => store.getters.nbOfPersons)
// #endregion

// #region Data
const isInit = ref(false)
const shoppingList = ref([])
const shoppingLists = ref([])

const selectedList = ref([])
// #endregion

// #region Watch
watch(
  () => selectedList,
  (newValue, oldValue) => {
    if (isInit.value) {
      shoppingLists.value.lists.find(
        (x) => x.mealPlanId === props.mealPlanId
      ).lastUpdate = moment()
      shoppingLists.value.lists.find(
        (x) => x.mealPlanId === props.mealPlanId
      ).selected = selectedList.value
      localStorage.setItem(
        'shoppingLists',
        JSON.stringify({
          lastPurge: shoppingLists.value.lastPurge,
          lists: shoppingLists.value.lists
        })
      )
    }
  },
  { deep: true }
)
watch(isInit, () => {
  if (isInit.value) {
    emit('isShoppingListInit')
  }
})
// #endregion

// #region Methods
const createShoppingLists = () => {
  mealPlanService
    .getShoppingList(props.mealPlanId)
    .then((response) => {
      shoppingList.value = response.data
      shoppingLists.value = {
        lastPurge: moment(),
        lists: [
          {
            mealPlanId: props.mealPlanId,
            lastUpdate: moment(),
            list: shoppingList.value,
            selected: []
          }
        ]
      }
      localStorage.setItem('shoppingLists', JSON.stringify(shoppingLists.value))
    })
    .catch(() => {})
    .finally(() => {
      isInit.value = true
    })
}
const addShoppingList = () => {
  mealPlanService
    .getShoppingList(props.mealPlanId)
    .then((response) => {
      shoppingList.value = response.data
      shoppingLists.value.lists.push({
        mealPlanId: props.mealPlanId,
        lastUpdate: moment(),
        list: shoppingList.value,
        selected: []
      })
      localStorage.setItem('shoppingLists', JSON.stringify(shoppingLists.value))
    })
    .catch(() => {})
    .finally(() => {
      isInit.value = true
    })
}
const purgeShoppingLists = () => {
  if (moment().diff(shoppingLists.value.lastPurge, 'days') >= 25) {
    shoppingLists.value.lists = shoppingLists.value.lists.filter(
      (x) => moment().diff(x.lastUpdate, 'days') < 25
    )
    localStorage.setItem(
      'shoppingLists',
      JSON.stringify({
        lastPurge: moment(),
        lists: shoppingLists.value.lists
      })
    )
  }
}
const isIngredientSelected = (item, i) => {
  const category = selectedList.value.find((x) => x.category === i)
  if (category) {
    const items = selectedList.value.find(
      (x) => x.category === i && x.ingredients
    ).ingredients
    if (items.length > 0) {
      const result = items.find(
        (y) =>
          y.name === item.name &&
          y.quantity === item.quantity &&
          y.unit_of_measure.name === item.unit_of_measure.name
      )
      if (result) return true
    } else return false
  } else return false
}
const selectedIngredient = (item, i) => {
  if (isIngredientSelected(item, i)) {
    let items = selectedList.value.find(
      (x) => x.category === i && x.ingredients
    ).ingredients
    items = items.filter(
      (y) =>
        y.name !== item.name ||
        y.quantity !== item.quantity ||
        y.unit_of_measure.name !== item.unit_of_measure.name
    )
    selectedList.value.find((x) => x.category === i).ingredients = items
  } else {
    if (selectedList.value.find((x) => x.category === i)) {
      selectedList.value.find((x) => x.category === i).ingredients.push(item)
    } else {
      selectedList.value.push({ category: i, ingredients: [item] })
    }
  }
}
const getIngredientName = (ingredient) => {
  if (ingredient.quantity > 1 && ingredient.plural_name) {
    return ingredient.plural_name
  } else {
    return ingredient.name
  }
}
const getPictureUrl = (item) => {
  let url = ''
  if (item.picture) url = process.env.VUE_APP_API_URL + item.picture.uri
  return url
}
// #endregion

// #region Created
shoppingLists.value = JSON.parse(localStorage.getItem('shoppingLists'))
if (!shoppingLists.value) createShoppingLists()
else {
  if (
    !shoppingLists.value.lists.find((x) => x.mealPlanId === props.mealPlanId)
  ) {
    addShoppingList()
  } else {
    const listFound = shoppingLists.value.lists.find(
      (x) => x.mealPlanId === props.mealPlanId
    )
    shoppingList.value = listFound.list
    selectedList.value = listFound.selected
    isInit.value = true
  }
  purgeShoppingLists()
}
// #endregion
</script>

<template>
  <v-container class="pa-0" fluid>
    <v-row no-gutters>
      <v-col
        cols="12"
        :md="fullPage ? 4 : 12"
        :xl="fullPage ? 3 : 12"
        v-for="(list, i) in shoppingList"
        :key="i"
      >
        <v-container :class="fullPage ? 'pa-4 pt-0' : 'pa-0 pb-4'" fluid>
          <v-card elevation="3" class="my-5 my-md-0">
            <v-card-title>
              <h2 class="capitalize">{{ i }}</h2>
            </v-card-title>
            <v-card-text class="px-2">
              <v-data-table
                :items-per-page="1000"
                hide-headers
                class="table"
                :headers="[]"
                :items="list"
                item-key="name"
                hover
                mobile-breakpoint="200"
              >
                <template v-slot:bottom></template>
                <template v-slot:body="{ items }">
                  <tr
                    v-for="item in items"
                    :key="item.name + item.quantity + item.unit_of_measure.name"
                    class="data-row"
                    :class="
                      isIngredientSelected(item, i) ? 'data-row-selected' : ''
                    "
                    @click="selectedIngredient(item, i)"
                  >
                    <td width="40px" class="px-0">
                      <v-checkbox-btn
                        color="primary"
                        :ripple="false"
                        :model-value="isIngredientSelected(item, i)"
                        @update:modelValue="selectedIngredient(item, i)"
                      ></v-checkbox-btn>
                    </td>
                    <td width="48px">
                      <img :src="getPictureUrl(item)" class="pic" />
                    </td>
                    <td class="text-start pr-0 capitalize">
                      {{ getIngredientName(item) }}
                    </td>
                    <td class="text-end pl-0" width="60px">
                      <span
                        v-if="item.quantity > 0"
                        class="quantity"
                        :class="
                          item.unit_of_measure.name === 'unit' ? 'mr-2' : ''
                        "
                      >
                        {{ item.quantity * nbOfPersons }}
                      </span>
                      <span
                        v-if="item.unit_of_measure.name != 'unit'"
                        class="unit_of_measure mr-2"
                      >
                        <span
                          v-if="
                            item.quantity > 1 &&
                            item.unit_of_measure.symbol_plural
                          "
                        >
                          {{ '&nbsp;' + item.unit_of_measure.symbol_plural }}
                        </span>
                        <span v-else>
                          {{ '&nbsp;' + item.unit_of_measure.symbol }}
                        </span>
                      </span>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
.v-card-title {
  padding: 16px !important;
}

.pic {
  object-fit: cover;
  width: 30px;
  height: 30px;
}

.quantity {
  color: #eb5c43 !important;
}

.unit_of_measure {
  color: var(--v-grey-darken-1-base);
  font-size: 14px !important;
}

.data-row {
  cursor: pointer;
}

.data-row-selected,
.data-row:hover {
  background-color: var(--v-background-base) !important;
}

::v-deep td:nth-child(1) {
  text-align: center !important;

  .mdi-checkbox-blank-outline {
    color: var(--v-grey-lighten-2-base);
  }
}

::v-deep td:nth-child(2) {
  padding: 0px !important;
  text-align: center !important;
}

::v-deep td:nth-child(3) {
  padding-left: 8px !important;
}

::v-deep td:nth-child(4) {
  padding-right: 0px !important;
}
</style>
