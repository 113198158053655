<template>
  <v-app-bar fixed color="white" dark>
    <v-row>
      <v-col sm="5">
        <v-btn class="toolbar-btn" text value="mealPlan" @click="checkRoute({ name: 'MealPlan' })">
          {{ $t('menu.mealPlan') }}
        </v-btn>
        <v-btn class="toolbar-btn" text value="fav" @click="checkRoute({ name: 'Fav' })">
          {{ $t('menu.fav') }}
        </v-btn>
        <v-btn class="toolbar-btn" text value="history" @click="checkRoute({ name: 'History' })">
          {{ $t('menu.history') }}
        </v-btn>

        <!-- <v-btn
          value="howTo"
          @click="checkRoute({ name: 'HowTo' })"
        ><img src="@/assets/icons/Tuto_32.png" /></v-btn> -->
      </v-col>
      <v-col sm="2" align="center">
        <v-container>
          <v-img src="@/assets/img/Joana_Horizontal_v1.png" style="
                    display: block;
                    margin-left: auto;
                    margin-right: auto;">
          </v-img>
        </v-container>
      </v-col>
      <v-col sm="5" align="right">
        <v-btn class="toolbar-btn" text value="history" @click="checkRoute({ name: 'JoanaAdvices' })">
          {{ $t('joanaAdvices.joanaAdvices') }}
        </v-btn>
        <v-btn class="toolbar-btn" text value="account" @click="checkRoute({ name: 'Account' })">
          {{ $t('menu.account') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import mixin from '@/mixins/mixin'

export default {
  name: 'TopMenu',
  mixins: [mixin],
  computed: {
    feedbackEmail() {
      return process.env.VUE_APP_FEEDBACK_EMAIL
    }
  }
}
</script>

<style scoped lang="scss">
.toolbar-btn {
  height: 100% !important;
}

::v-deep span {
  font-size: 12px !important;
  color: var(--v-grey-darken-3-base);
}

.main-title {
  font-size: 30px;
  line-height: 41px;
  letter-spacing: -0.02em;
}
</style>
