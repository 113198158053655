<script setup>
// #region Imports
import { ref, defineProps, defineEmits } from 'vue'

import planMessageService from '@/services/planMessage'
// #endregion

// #region Props
const props = defineProps(['user', 'onboarding'])
// #endregion

// #region Emits
const emit = defineEmits(['change'])
// #endregion

// #region Data
const message = ref({ title: 'title', text: 'text' })
const sexItems = ref([
  { value: '', display: '' },
  { value: 'woman', display: 'Femme' },
  { value: 'man', display: 'Homme' }
])
const goalItems = ref([
  { value: '', display: '' },
  { value: 'weight_loss', display: 'Perte de poids' },
  { value: 'weight_stability', display: 'Maintien du poids' }
])
const dietItems = ref([
  { value: '', display: '' },
  { value: 'vegetarian', display: 'Végétarien' },
  { value: 'flexitarian', display: 'Flexitarien' }
])
const sportFrequencyItems = ref([
  { value: '', display: '' },
  { value: 'more_than_2_hours', display: '+ de 2h/semaine' },
  { value: 'less_than_2_hours', display: '- de 2h/semaine' }
])
const tempUser = props.user
// #endregion

// #region Created
const choices = [
  props.user.sex,
  props.user.goal,
  props.user.sport_frequency,
  props.user.nb_of_persons > 1 ? 'multi' : 'single',
  Date.now() - new Date(props.user.birthday) > 60 * 365 * 24 * 60 * 60 * 1000
    ? 'over_sixty_years_old'
    : 'under_sixty_years_old'
]
const tempMessage = planMessageService(choices)
message.value = tempMessage
// #endregion

// #region Methods
const userFormChange = () => {
  const choices = [
    props.user.sex,
    props.user.goal,
    props.user.sport_frequency,
    props.user.nb_of_persons > 1 ? 'multi' : 'single',
    Date.now() - new Date(props.user.birthday) > 60 * 365 * 24 * 60 * 60 * 1000
      ? 'over_sixty_years_old'
      : 'under_sixty_years_old'
  ]
  const tempMessage = planMessageService(choices)
  message.value = tempMessage

  emit('change', tempUser)
}
// #endregion
</script>

<template>
  <v-form
    :class="{ onboarding: props.onboarding ?? false }"
    class="mt-4"
    @change="userFormChange"
  >
    <v-row align="center">
      <v-col cols="auto">
        <v-img
          width="39"
          aspect-ratio="1"
          :src="require('@/assets/icons/008-femme.png')"
        ></v-img>
      </v-col>
      <v-col class="px-0"><label class="form-label" for="">Sexe</label></v-col>
      <v-col cols="auto">
        <v-select
          color="primary"
          variant="solo"
          flat
          density="compact"
          height="41"
          v-model="tempUser.sex"
          class="mt-0 pt-0"
          hide-details
          item-value="value"
          item-title="display"
          :items="sexItems"
          required
          @update:modelValue="userFormChange"
        >
          <template v-slot:selection="{ item }">
            <span class="selected-item">{{ item.title }}</span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="auto">
        <v-img
          width="39"
          aspect-ratio="1"
          :src="require('@/assets/icons/001-target.png')"
        ></v-img>
      </v-col>
      <v-col class="px-0"
        ><label class="form-label" for="">Objectif</label></v-col
      >
      <v-col cols="auto">
        <v-select
          height="41"
          v-model="tempUser.goal"
          class="mt-0 pt-0"
          density="compact"
          color="primary"
          variant="solo"
          flat
          hide-details
          item-value="value"
          item-title="display"
          :items="goalItems"
          @update:modelValue="userFormChange"
        >
          <template v-slot:selection="{ item }">
            <span class="selected-item">{{ item.title }}</span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="auto">
        <v-img
          width="39"
          aspect-ratio="1"
          :src="require('@/assets/icons/002-fork.png')"
        ></v-img>
      </v-col>
      <v-col class="px-0"
        ><label class="form-label" for="">Régime</label></v-col
      >
      <v-col cols="auto">
        <v-select
          height="41"
          v-model="tempUser.diet"
          class="mt-0 pt-0"
          density="compact"
          color="primary"
          variant="solo"
          flat
          hide-details
          item-value="value"
          item-title="display"
          :items="dietItems"
          @update:modelValue="userFormChange"
        >
          <template v-slot:selection="{ item }">
            <span class="selected-item">{{ item.title }}</span>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="auto">
        <v-img
          width="39"
          aspect-ratio="1"
          :src="require('@/assets/icons/haltere.png')"
        ></v-img>
      </v-col>
      <v-col class="px-0"><label class="form-label" for="">Sport</label></v-col>
      <v-col cols="auto">
        <v-select
          height="41"
          v-model="tempUser.sport_frequency"
          class="mt-0 pt-0"
          density="compact"
          color="primary"
          variant="solo"
          flat
          hide-details
          item-value="value"
          item-title="display"
          :items="sportFrequencyItems"
          @update:modelValue="userFormChange"
        >
          <template v-slot:selection="{ item }">
            <span class="selected-item">{{ item.title }}</span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="auto">
        <v-img
          width="39"
          aspect-ratio="1"
          :src="require('@/assets/icons/003-user.png')"
        ></v-img>
      </v-col>
      <v-col class="px-0"
        ><label class="form-label" for="">Nbr de personnes</label></v-col
      >
      <v-col cols="auto" align-self="end" class="nbe-field">
        <v-text-field
          v-model="tempUser.nb_of_persons"
          class="mt-0 pt-0"
          height="41"
          hide-details
          density="compact"
          single-line
          color="primary"
          variant="solo"
          flat
          type="number"
          :min="1"
          oninput="if(Number(this.value) > 8) this.value = 8;"
          @input="userFormChange"
        />
      </v-col>
    </v-row>

    <div class="message-box mt-14">
      <p v-html="message.title" class="message-title"></p>
      <div v-html="message.text" class="message-text mt-4"></div>
    </div>
  </v-form>
</template>

<style scoped lang="scss">
h2 {
  margin: 0 !important;
}

.v-card {
  p {
    font-size: 14px;
    margin: 0;
  }
}

.selected-item {
  color: #323232;
  font-size: 14px !important;
  font-family: 'Barlow-Bold';
}

.form-label {
  color: #707070;
  font-size: 14px;
}

.v-text-field--rounded {
  border-radius: 7px;
}

.links {
  font-size: 14px;
  color: #323232;
  font-weight: bold;
}

.message-box {
  .message-title {
    color: #eb5c43 !important;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Barlow-Bold';
  }

  .message-text {
    font-size: 14px;
  }
}

#logout-link {
  text-transform: uppercase;
  text-decoration: underline;
  color: #323232;
  font-weight: bold;
  font-family: 'Barlow-Bold';
}

.vege-advertising {
  color: #eb5c43;
}

.v-input {
  width: 204px !important;
  background-color: white;

  @media only screen and (min-width: 961px) {
    background-color: #f6f6f6;
  }
}

.onboarding {
  .v-input {
    @media only screen and (min-width: 961px) {
      background-color: white;
    }
  }

  .nbe-field {
    .v-input {
      width: 70px !important;

      @media only screen and (min-width: 961px) {
        background-color: white;
      }
    }
  }
}

.nbe-field {
  .v-input {
    width: 70px !important;
    font-family: 'Barlow-Bold';

    @media only screen and (min-width: 961px) {
      background-color: #f6f6f6;
    }
  }
}

.links {
  font-family: 'Barlow-Bold';
}

#account-informations-view-link {
  @media only screen and (min-width: 961px) {
    display: none;
  }
}

#account-informations {
  @media only screen and (max-width: 960px) {
    display: none;
  }
}

#program-form-wrapper {
  @media only screen and (min-width: 961px) {
    background-color: white;
    border-radius: 15px;
    padding: 15px 20px;
  }

  @media only screen and (max-width: 960px) {
    margin-top: 10px;
    margin-bottom: 50px;

    h2 {
      margin-bottom: 15px;
    }

    #program-message {
      margin-top: 50px;
    }
  }
}
::v-deep .v-input {
  border-radius: 233px !important;
}

::v-deep .v-input__control {
  border-radius: 233px !important;
}

::v-deep .v-field {
  border-radius: 233px !important;
}
</style>
