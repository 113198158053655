<script setup>
// #region Imports
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import UpdateInformationsForm from '@/components/Account/UpdateInformationsForm.vue'

import userService from '@/services/userService'
import stripeService from '@/services/stripeService'
import authService from '@/services/authService'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Data
const isInit = ref(true)
const user = ref([])
const subscriptionStatus = ref(null)
// #endregion

// #region Methods

const customerPortal = () => {
  stripeService.customerPortal().then((response) => {
    window.location.href = response.data.url
  })
}
const resetEmail = () => {
  const credentials = {
    email: user.value.email
  }
  authService.resetEmail(credentials).then(() => {
    checkRoute({ name: 'ResetEmailRedirection' })
  })
}
const resetPassword = () => {
  const credentials = {
    email: user.value.email
  }
  authService.resetPassword(credentials).then(() => {
    checkRoute({ name: 'ResetPasswordRedirection' })
  })
}
const checkRoute = (name) => {
  router.push(name)
}
// #endregion

// #region Created
userService
  .getUser()
  .then((response) => {
    user.value = response.data
    userService
      .getSubscriptionStatus()
      .then((response) => {
        subscriptionStatus.value = response.data.subscription_status
      })
      .finally(() => {
        isInit.value = true
      })
  })
  .catch(() => {})
// #endregion
</script>

<template>
  <v-container v-if="isInit" class="pa-0">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" class="px-0 pb-0">
          <h2 class="mt-1">{{ $t('account.information.title') }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mb-2 pt-0 pl-0" cols="12"
          ><span
            style="color: #707070; font-family: barlow_regular; font-size: 14px"
            ><span
              style="margin-right: 5px; #707070; font-family: barlow_regular; font-size: 14px"
              >Identifiant :</span
            >
            {{ user.email }}</span
          >
        </v-col>
      </v-row>
      <UpdateInformationsForm
        v-if="user.email"
        :data="user"
      ></UpdateInformationsForm>
      <div class="account-informations-links">
        <v-row class="mb-3">
          <span @click="resetEmail()">
            {{ $t('account.information.updateEmail') }}
          </span>
        </v-row>
        <v-row class="mb-3">
          <span @click="resetPassword()">
            {{ $t('account.information.updatePassword') }}
          </span>
        </v-row>
        <!-- <div v-if="subscriptionStatus == 'active' ||
          subscriptionStatus == 'past_due'
          ">
          <v-row class="mb-3">
            <span>
              Changer ma carte bancaire
            </span>
          </v-row>

          <v-row class="mb-3">
            <span>
              Voir mes factures
            </span>
          </v-row>
        </div> -->
        <v-row
          class="mb-3"
          v-if="
            subscriptionStatus === 'active' || subscriptionStatus === 'past_due'
          "
        >
          <span class="stripe-links" @click="customerPortal">
            Changer ma carte bancaire
          </span></v-row
        >

        <v-row
          v-if="
            subscriptionStatus === 'active' || subscriptionStatus === 'past_due'
          "
        >
          <span class="stripe-links" @click="customerPortal">
            Voir mes factures
          </span></v-row
        >

        <!-- <v-row v-if="$vuetify.breakpoint.smAndDown" class="mt-7">
          <v-col align="right">
            <v-btn v-if="subscriptionStatus == 'active' ||
              subscriptionStatus == 'past_due'
            " class="boutonPrincipales" @click="customerPortal">
              {{ $t('account.information.manageSubscription') }}
            </v-btn>
            <v-btn v-else class="boutonPrincipales" @click="checkRoute({ name: 'Checkout' })">
              {{ $t('account.information.subscribe') }}
            </v-btn>
          </v-col>
        </v-row> -->
      </div>
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped lang="scss">
.label-info,
.label {
  color: var(--v-grey-darken-1-base);
}

.pdf-content {
  width: 100%;
  margin: 20px;
  font-family: 'Roboto', sans-serif;
}

.informations-list {
  p {
    color: black;
  }

  .value {
    background-color: white;
    padding: 12px;
    border-radius: 7px;
    font-weight: bold;
    width: 204px;
    font-weight: bold !important;
    font-size: 14px !important;
    font-family: 'Barlow-Bold';
  }

  .label {
    color: var(--v-grey-darken-1-base);
    font-size: 14px !important;
  }
}

.account-informations-links {
  span {
    font-size: 14px !important;
    color: #eb5c43;
    text-decoration: underline;
    font-weight: bold;
    font-family: 'Barlow-Bold';
  }
}

.stripe-links {
  cursor: pointer;
}
</style>
