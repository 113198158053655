import axios from 'axios'

export default {
  getConfig() {
    return {
      headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('tokens'))?.access }
    }
  },
  /*
  Ingredients
   */
  getIngredients(page, search) {
    let query = ''
    if (page && search) query = '?page=' + page + '&search=' + search
    else if (page) query = '?page=' + page
    else if (search) query = '?search=' + search
    return axios.get(process.env.VUE_APP_API_URL + '/administration/ingredient/' + query, this.getConfig())
  },
  createIngredient(ingredient) {
    return axios.post(process.env.VUE_APP_API_URL + '/administration/ingredient/', ingredient, this.getConfig())
  },
  updateIngredient(ingredient) {
    return axios.put(process.env.VUE_APP_API_URL + '/administration/ingredient/' + ingredient.id + '/', ingredient, this.getConfig())
  },
  deleteIngredient(id) {
    return axios.delete(process.env.VUE_APP_API_URL + '/administration/ingredient/' + id + '/', this.getConfig())
  },
  /*
  Dishes
   */
  getDishes(page, search) {
    let query = ''
    if (page && search) query = '?page=' + page + '&search=' + search
    else if (page) query = '?page=' + page
    else if (search) query = '?search=' + search
    return axios.get(process.env.VUE_APP_API_URL + '/administration/dish/' + query, this.getConfig())
  },
  createDish(dish) {
    return axios.post(process.env.VUE_APP_API_URL + '/administration/dish/', dish, this.getConfig())
  },
  updateDish(dish) {
    return axios.put(process.env.VUE_APP_API_URL + '/administration/dish/' + dish.uuid + '/', dish, this.getConfig())
  },
  deleteDish(id) {
    return axios.delete(process.env.VUE_APP_API_URL + '/administration/dish/' + id + '/', this.getConfig())
  },
  /*
  Meal Plan
   */
  getMealPlans(page, search) {
    let query = ''
    if (page && search) query = '?page=' + page + '&search=' + search
    else if (page) query = '?page=' + page
    else if (search) query = '?search=' + search
    return axios.get(process.env.VUE_APP_API_URL + '/administration/meal-plan/' + query, this.getConfig())
  },
  createMealPlan(mealPlan) {
    return axios.post(process.env.VUE_APP_API_URL + '/administration/meal-plan/', mealPlan, this.getConfig())
  },
  updateMealPlan(mealPlan, formData) {
    return axios.put(process.env.VUE_APP_API_URL + '/administration/meal-plan/' + mealPlan.uuid + '/', formData, this.getConfig())
  },
  publishMealPlan(mealPlan) {
    return axios.put(process.env.VUE_APP_API_URL + '/administration/meal-plan-publish/', mealPlan, this.getConfig())
  },
  deleteMealPlan(id) {
    return axios.delete(process.env.VUE_APP_API_URL + '/administration/meal-plan/' + id + '/', this.getConfig())
  },
  /*
  Weekday
 */
  updateWeekday(weekday) {
    return axios.put(process.env.VUE_APP_API_URL + '/administration/weekday/' + weekday.uuid + '/', weekday, this.getConfig())
  },
  /*
  Meal
 */
  createMeal(meal) {
    return axios.post(process.env.VUE_APP_API_URL + '/administration/meal/', meal, this.getConfig())
  },
  updateMeal(meal) {
    return axios.put(process.env.VUE_APP_API_URL + '/administration/meal/' + meal.id + '/', meal, this.getConfig())
  },
  /*
  Profiles
   */
  getProfiles(page, search, stripStatus, diet) {
    const config = this.getConfig()
    let params = {}
    if (search) {
      params = { search: search, ...params }
    }
    if (page) {
      params = { page: page, ...params }
    }
    if (stripStatus && stripStatus === 'trialing') {
      params = { stripe_subscription_status__isnull: true, ...params }
    } else if (stripStatus) {
      params = { stripe_subscription_status: stripStatus, ...params }
    }
    if (diet) {
      params = { diet: diet, ...params }
    }
    config.params = params
    return axios.get(process.env.VUE_APP_API_URL + '/administration/profile/', config)
  },
  createProfile(profile) {
    return axios.post(process.env.VUE_APP_API_URL + '/administration/profile/', profile, this.getConfig())
  },
  updateProfile(profile) {
    return axios.put(process.env.VUE_APP_API_URL + '/administration/profile/' + profile.id + '/', profile, this.getConfig())
  },
  deleteProfile(id) {
    return axios.delete(process.env.VUE_APP_API_URL + '/administration/profile/' + id + '/', this.getConfig())
  },
  updateProfileMealPlans(payload) {
    return axios.put(process.env.VUE_APP_API_URL + '/administration/update-profile-meal-plans/', payload, this.getConfig())
  },
  createFreeUser(profile) {
    return axios.post(process.env.VUE_APP_API_URL + '/administration/create-free-user/', profile, this.getConfig())
  },
  /*
  Pictures
   */
  getPictures(page, search) {
    let query = ''
    if (page && search) query = '?page=' + page + '&search=' + search
    else if (page) query = '?page=' + page
    else if (search) query = '?search=' + search
    return axios.get(process.env.VUE_APP_API_URL + '/administration/picture/' + query, this.getConfig())
  },
  createPicture(formData) {
    return axios.post(process.env.VUE_APP_API_URL + '/administration/picture/', formData, this.getConfig())
  },
  updatePicture(id, formData) {
    return axios.put(process.env.VUE_APP_API_URL + '/administration/picture/' + id + '/', formData, this.getConfig())
  },
  deletePicture(id) {
    return axios.delete(process.env.VUE_APP_API_URL + '/administration/picture/' + id + '/', this.getConfig())
  },
  /*
  Tags
   */
  getTags(page, search) {
    let query = ''
    if (page && search) query = '?page=' + page + '&search=' + search
    else if (page) query = '?page=' + page
    else if (search) query = '?search=' + search
    return axios.get(process.env.VUE_APP_API_URL + '/administration/tag/' + query, this.getConfig())
  },
  createTag(formData) {
    return axios.post(process.env.VUE_APP_API_URL + '/administration/tag/', formData, this.getConfig())
  },
  updateTag(id, formData) {
    return axios.put(process.env.VUE_APP_API_URL + '/administration/tag/' + id + '/', formData, this.getConfig())
  },
  deleteTag(id) {
    return axios.delete(process.env.VUE_APP_API_URL + '/administration/tag/' + id + '/', this.getConfig())
  },
  /*
  IngredientCategories
   */
  getIngredientCategories(page, search) {
    let query = ''
    if (page && search) query = '?page=' + page + '&search=' + search
    else if (page) query = '?page=' + page
    else if (search) query = '?search=' + search
    return axios.get(process.env.VUE_APP_API_URL + '/administration/ingredient-category/' + query, this.getConfig())
  },
  createIngredientCategory(ingredientCategory) {
    return axios.post(process.env.VUE_APP_API_URL + '/administration/ingredient-category/', ingredientCategory, this.getConfig())
  },
  updateIngredientCategory(id, ingredientCategory) {
    return axios.put(process.env.VUE_APP_API_URL + '/administration/ingredient-category/' + id + '/', ingredientCategory, this.getConfig())
  },
  deleteIngredientCategory(id) {
    return axios.delete(process.env.VUE_APP_API_URL + '/administration/ingredient-category/' + id + '/', this.getConfig())
  },
  /*
  UnitsOfMeasure
   */
  getUnitsOfMeasure(page, search) {
    let query = ''
    if (page && search) query = '?page=' + page + '&search=' + search
    else if (page) query = '?page=' + page
    else if (search) query = '?search=' + search
    return axios.get(process.env.VUE_APP_API_URL + '/administration/unit-of-measure/' + query, this.getConfig())
  },
  createUnitOfMeasure(unit) {
    return axios.post(process.env.VUE_APP_API_URL + '/administration/unit-of-measure/', unit, this.getConfig())
  },
  updateUnitOfMeasure(unit) {
    return axios.put(process.env.VUE_APP_API_URL + '/administration/unit-of-measure/' + unit.id + '/', unit, this.getConfig())
  },
  /*
  TAC
   */
  getTAC(page) {
    let query = ''
    if (page) query = '?page=' + page
    return axios.get(process.env.VUE_APP_API_URL + '/administration/terms-and-conditions/' + query, this.getConfig())
  },
  createTAC(tac) {
    return axios.post(process.env.VUE_APP_API_URL + '/administration/terms-and-conditions/', tac, this.getConfig())
  },
  updateTAC(tac) {
    return axios.put(process.env.VUE_APP_API_URL + '/administration/terms-and-conditions/' + tac.id + '/', tac, this.getConfig())
  },
  deleteTAC(id) {
    return axios.delete(process.env.VUE_APP_API_URL + '/administration/terms-and-conditions/' + id + '/', this.getConfig())
  },
  /*
  Miscellaneous
   */
  getIngredientsCategories(page, search) {
    let query = ''
    if (page && search) query = '?page=' + page + '&search=' + search
    else if (page) query = '?page=' + page
    else if (search) query = '?search=' + search
    return axios.get(process.env.VUE_APP_API_URL + '/administration/ingredient-category/' + query, this.getConfig())
  },
  getPictureTypes(page, search) {
    let query = ''
    if (page && search) query = '?page=' + page + '&search=' + search
    else if (page) query = '?page=' + page
    else if (search) query = '?search=' + search
    return axios.get(process.env.VUE_APP_API_URL + '/administration/picture-type/' + query, this.getConfig())
  },
  getMealCategories(page, search) {
    let query = ''
    if (page && search) query = '?page=' + page + '&search=' + search
    else if (page) query = '?page=' + page
    else if (search) query = '?search=' + search
    return axios.get(process.env.VUE_APP_API_URL + '/administration/meal-category/' + query, this.getConfig())
  }
}
