import axios from 'axios'
import moment from 'moment'

export default {
  getConfig() {
    return {
      headers: {
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('tokens'))?.access
      }
    }
  },
  createTokenUrl() {
    return process.env.VUE_APP_API_URL + '/auth/jwt/create/'
  },
  refreshTokensUrl() {
    return process.env.VUE_APP_API_URL + '/auth/jwt/refresh/'
  },

  logIn(credentials) {
    return axios.post(this.createTokenUrl(), credentials)
  },
  refreshTokens(token) {
    return axios.post(this.refreshTokensUrl(), token)
  },
  checkTokens() {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem('tokens')) {
        const tokens = JSON.parse(localStorage.getItem('tokens'))
        if (tokens.access || tokens.refresh) {
          if (
            tokens.access &&
            moment().isSameOrBefore(
              moment.unix(JSON.parse(atob(tokens.access.split('.')[1])).exp)
            )
          ) {
            resolve(true)
          } else if (
            tokens.refresh &&
            moment().isSameOrBefore(
              moment.unix(JSON.parse(atob(tokens.refresh.split('.')[1])).exp)
            )
          ) {
            this.refreshTokens({ refresh: tokens.refresh })
              .then((response) => {
                localStorage.setItem(
                  'tokens',
                  JSON.stringify({
                    access: response.data.access,
                    refresh: response.data.refresh
                  })
                )
                resolve(true)
              })
              .catch(() => {
                reject(new Error())
              })
          } else reject(new Error())
        }
      } else reject(new Error())
    })
  },
  signUp(credentials) {
    return axios.post(process.env.VUE_APP_API_URL + '/auth/users/', credentials)
  },
  resendActivationEmail(credentials) {
    return axios.post(
      process.env.VUE_APP_API_URL + '/auth/users/resend_activation/',
      credentials
    )
  },
  activateAccount(credentials) {
    return axios.post(
      process.env.VUE_APP_API_URL + '/auth/users/activation/',
      credentials
    )
  },
  activateFreeAccount(credentials) {
    return axios.post(
      process.env.VUE_APP_API_URL + '/auth/users/free/activation/',
      credentials
    )
  },
  resetEmail(credentials) {
    return axios.post(
      process.env.VUE_APP_API_URL + '/auth/users/reset_email/',
      credentials
    )
  },
  resetEmailConfirmation(credentials) {
    return axios.post(
      process.env.VUE_APP_API_URL + '/auth/users/reset_email_confirm/',
      credentials
    )
  },
  resetPassword(credentials) {
    return axios.post(
      process.env.VUE_APP_API_URL + '/auth/users/reset_password/',
      credentials
    )
  },
  resetPasswordConfirmation(credentials) {
    return axios.post(
      process.env.VUE_APP_API_URL + '/auth/users/reset_password_confirm/',
      credentials
    )
  },
  getTAC() {
    return axios.get(process.env.VUE_APP_API_URL + '/terms-and-conditions/')
  },
  updateUserProfile(data) {
    return axios.put(
      process.env.VUE_APP_API_URL + '/auth/users/me/',
      data,
      this.getConfig()
    )
  },
  deleteUserProfile(id) {
    return axios.delete(`${process.env.VUE_APP_API_URL}/administration/profile/${id}/`, this.getConfig())
  }
}
