import axios from 'axios'

export default {
  getConfig() {
    return {
      headers: {
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('tokens'))?.access
      }
    }
  },
  getAdviceQuestions(joanaAdviceCategoryId, adviceSubCategoryId) {
    return axios
      .get(
        process.env.VUE_APP_API_URL +
          `/administration/advice/category/${joanaAdviceCategoryId}/subcategory/${adviceSubCategoryId}/advice/`,
        this.getConfig()
      )
      .then((response) => {
        const advices = response.data.results
        advices.sort((a, b) => a.order - b.order)
        advices.map((advice) => {
          advice.isOnboarding = advice.is_onboarding
          delete advice.is_onboarding
          return advice
        })
        return advices
      })
  },
  createAdviceQuestion(joanaAdviceCategoryId, adviceSubCategoryId, formData) {
    return axios
      .post(
        process.env.VUE_APP_API_URL +
          `/administration/advice/category/${joanaAdviceCategoryId}/subcategory/${adviceSubCategoryId}/advice/`,
        formData,
        this.getConfig()
      )
      .then((response) => {
        return response.data
      })
  },
  updateAdviceQuestion(
    joanaAdviceCategoryId,
    adviceSubCategoryId,
    adviceQuestionId,
    formData
  ) {
    return axios
      .put(
        process.env.VUE_APP_API_URL +
          `/administration/advice/category/${joanaAdviceCategoryId}/subcategory/${adviceSubCategoryId}/advice/${adviceQuestionId}/`,
        formData,
        this.getConfig()
      )
      .then((response) => {
        const advice = response.data
        advice.isOnboarding = advice.is_onboarding
        delete advice.is_onboarding
        return advice
      })
  },
  deleteAdviceQuestion(
    joanaAdviceCategoryId,
    adviceSubCategoryId,
    adviceQuestionId
  ) {
    return axios
      .delete(
        process.env.VUE_APP_API_URL +
          `/administration/advice/category/${joanaAdviceCategoryId}/subcategory/${adviceSubCategoryId}/advice/${adviceQuestionId}/`,
        this.getConfig()
      )
      .then((response) => {
        return response
      })
  },
  updateAdviceQuestionOrder(
    joanaAdviceCategoryId,
    adviceSubCategoryId,
    newOrders
  ) {
    return axios
      .put(
        process.env.VUE_APP_API_URL +
          `/administration/advice/category/${joanaAdviceCategoryId}/subcategory/${adviceSubCategoryId}/advice/reorder/`,
        newOrders,
        this.getConfig()
      )
      .then((_) =>
        axios
          .get(
            process.env.VUE_APP_API_URL +
              `/administration/advice/category/${joanaAdviceCategoryId}/subcategory/${adviceSubCategoryId}/advice/`,
            this.getConfig()
          )
          .then((response) => {
            return response.data.results
          })
      )
  }
}
