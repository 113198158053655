<script setup>
// #region Imports
import { reactive, computed, defineProps, defineEmits, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { required, numeric } from '@vuelidate/validators'
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor'
import adminService from '@/services/adminService'
import _ from 'lodash'
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Props
const props = defineProps(['dishToEdit'])
// #endregion

// #region Emits
const emit = defineEmits(['close'])
// #endregion

// #region Form Data
const formState = reactive({
  dish: {}
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    dish: {
      name: { required },
      portions: { required, numeric },
      cooking_time: { required, numeric },
      preparation_time: { required, numeric }
    }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Data
const dialog = ref(true)

const tagsLoaded = ref([])
const searchTags = ref('')
const isLoadingTags = ref(false)
const searchIngredients = ref('')
const isLoadingIngredients = ref(false)
const ingredientsLoaded = ref([])
const ingredientToAdd = ref({})
const picturesLoaded = ref([])
const searchPictures = ref('')
// const isLoadingPictures = ref(true)
const unitsOfMeasureLoaded = ref([])
const isLoadingUnitsOfMeasure = ref(false)
const searchUnitsOfMeasure = ref('')
// #endregion

// #region Computed
const nameErrors = computed(() => {
  if (v$.value.dish.name.$dirty && v$.value.dish.name.required.$invalid) {
    return t('message.error.required')
  }
  return []
})
const portionsErrors = computed(() => {
  if (v$.value.dish.portions.$dirty) {
    if (v$.value.dish.portions.required.$invalid) {
      return t('message.error.required')
    } else if (v$.value.dish.portions.numeric.$invalid) {
      return t('message.error.decimal')
    }
  }
  return []
})
const cookingTimeErrors = computed(() => {
  if (v$.value.dish.cooking_time.$dirty) {
    if (v$.value.dish.cooking_time.required.$invalid) {
      return t('message.error.required')
    } else if (v$.value.dish.cooking_time.numeric.$invalid) {
      return t('message.error.decimal')
    }
  }
  return []
})
const preparationTimeErrors = computed(() => {
  if (v$.value.dish.preparation_time.$dirty) {
    if (v$.value.dish.preparation_time.required.$invalid) {
      return t('message.error.required')
    } else if (v$.value.dish.preparation_time.numeric.$invalid) {
      return t('message.error.decimal')
    }
  }
  return []
})
const formattedData = computed(() => {
  let i = 0
  const dishToSave = { ...formState.dish }
  dishToSave.ingredients = []
  if (formState.dish.ingredients) {
    for (const ingredient of formState.dish.ingredients) {
      const newVal = {
        ingredient: ingredient.ingredient.id,
        quantity: ingredient.quantity,
        unit_of_measure: ingredient.unit_of_measure.id
      }
      dishToSave.ingredients[i] = newVal
      i++
    }
  }
  dishToSave.tags = []
  if (formState.dish.tags) {
    for (const tag of formState.dish.tags) {
      dishToSave.tags.push(tag.id)
    }
  }
  dishToSave.pictures = []
  if (formState.dish.pictures) {
    for (const picture of formState.dish.pictures) {
      dishToSave.pictures.push(picture.id)
    }
  }
  if (!dishToSave.instructions) dishToSave.instructions = null
  if (!formState.dish.display) dishToSave.display = false
  return dishToSave
})
// #endregion

// #region Methods
const removeTag = (item) => {
  formState.dish.tags = formState.dish.tags.filter((x) => x.id !== item.id)
}
const removePicture = (item) => {
  formState.dish.pictures = formState.dish.pictures.filter(
    (x) => x.id !== item.id
  )
}
const changeInstructions = (e) => {
  formState.dish.instructions = e
}
const addIngredient = () => {
  if (
    ingredientToAdd.value?.ingredient &&
    ingredientToAdd.value?.quantity &&
    ingredientToAdd.value?.unit_of_measure
  ) {
    if (formState.dish.ingredients) {
      const foundIng = formState.dish.ingredients.find(
        (x) => x.ingredient.id === ingredientToAdd.value.ingredient.id
      )
      if (
        !foundIng ||
        (foundIng &&
          foundIng.unit_of_measure.id !==
            ingredientToAdd.value.unit_of_measure.id) ||
        (foundIng && foundIng.quantity !== ingredientToAdd.value.quantity)
      ) {
        formState.dish.ingredients.push({ ...ingredientToAdd.value })
        ingredientToAdd.value = {}
      }
    } else {
      formState.dish.ingredients = [{ ...ingredientToAdd.value }]
      ingredientToAdd.value = {}
    }
  }
}
const deleteIngredient = (item) => {
  let index = 0
  for (const ingredient of formState.dish.ingredients) {
    if (
      ingredient.ingredient.id === item.ingredient.id &&
      ingredient.unit_of_measure.id === item.unit_of_measure.id &&
      ingredient.quantity === item.quantity
    ) {
      break
    }
    index++
  }
  formState.dish.ingredients.splice(index, 1)
}
const getPictureUrl = (uri) => {
  return process.env.VUE_APP_API_URL + '/' + uri
}
const saveDish = () => {
  v$.value.$touch()
  if (!v$.value.$invalid && formState.dish.ingredients.length > 0) {
    if (formattedData.value.id) {
      adminService.updateDish(formattedData.value).then(() => {
        emit('close')
      })
    } else {
      adminService.createDish(formattedData.value).then(() => {
        emit('close')
      })
    }
  }
}
// #endregion

// #region Watch
watch(searchTags, () => {
  isLoadingTags.value = true
  adminService
    .getTags(null)
    .then((response) => {
      tagsLoaded.value = response.data.results
    })
    .finally(() => (isLoadingTags.value = false))
})
watch(
  searchIngredients,
  _.debounce(() => {
    isLoadingIngredients.value = true
    adminService
      .getIngredients(null, searchIngredients.value)
      .then((response) => {
        ingredientsLoaded.value = response.data.results
      })
      .finally(() => (isLoadingIngredients.value = false))
  }, 500)
)
watch(searchPictures, () => {
  adminService.getPictures(null, searchPictures.value).then((response) => {
    picturesLoaded.value = response.data.results
  })
})
watch(searchUnitsOfMeasure, () => {
  isLoadingUnitsOfMeasure.value = true
  adminService
    .getUnitsOfMeasure(null)
    .then((response) => {
      unitsOfMeasureLoaded.value = response.data.results
    })
    .finally(() => (isLoadingUnitsOfMeasure.value = false))
})
// #endregion

// #region Created
formState.dish = {
  ...props.dishToEdit,
  instructions: props.dishToEdit.instructions
}

if (!formState.dish.ingredients) {
  formState.dish.ingredients = []
}
if (!formState.dish.pictures) {
  formState.dish.pictures = []
}
isLoadingTags.value = true
adminService
  .getTags(null)
  .then((response) => {
    tagsLoaded.value = response.data.results
  })
  .finally(() => (isLoadingTags.value = false))

isLoadingIngredients.value = true
adminService
  .getIngredients(null)
  .then((response) => {
    ingredientsLoaded.value = response.data.results
  })
  .finally(() => (isLoadingIngredients.value = false))
adminService.getPictures(null).then((response) => {
  picturesLoaded.value = response.data.results
})
isLoadingUnitsOfMeasure.value = true
adminService
  .getUnitsOfMeasure(null)
  .then((response) => {
    unitsOfMeasureLoaded.value = response.data.results
  })
  .finally(() => (isLoadingUnitsOfMeasure.value = false))
// #endregion
</script>

<template>
  <v-dialog v-model="dialog" width="1100" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2 bg-grey-title">
        Editer recette
      </v-card-title>
      <v-card-text>
        <h3 class="mt-5 mb-3 ml-4">Général</h3>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="formState.dish.name"
              :error-messages="nameErrors"
              @input="v$.dish.name.$touch()"
              @blur="v$.dish.name.$touch()"
              hide-details
              variant="outlined"
              color="primary"
              rounded
            >
              <template v-slot:label>
                <span class="checkbox-label">
                  Nom
                  <span class="mandatory">*</span>
                </span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="formState.dish.portions"
              suffix="portion"
              :error-messages="portionsErrors"
              @input="v$.dish.portions.$touch()"
              @blur="v$.dish.portions.$touch()"
              hide-details
              variant="outlined"
              color="primary"
              rounded
            >
              <template v-slot:label>
                <span class="checkbox-label">
                  Portion
                  <span class="mandatory">*</span>
                </span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="formState.dish.preparation_time"
              suffix="min"
              :error-messages="preparationTimeErrors"
              @input="v$.dish.preparation_time.$touch()"
              @blur="v$.dish.preparation_time.$touch()"
              hide-details
              variant="outlined"
              color="primary"
              rounded
            >
              <template v-slot:label>
                <span class="checkbox-label">
                  Préparation
                  <span class="mandatory">*</span>
                </span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="formState.dish.cooking_time"
              suffix="min"
              :error-messages="cookingTimeErrors"
              @input="v$.dish.cooking_time.$touch()"
              @blur="v$.dish.cooking_time.$touch()"
              hide-details
              variant="outlined"
              color="primary"
              rounded
            >
              <template v-slot:label>
                <span class="checkbox-label">
                  Cuisson
                  <span class="mandatory">*</span>
                </span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
            <h3 class="mt-5 mb-3 ml-4">Tags</h3>
            <v-autocomplete
              v-model="formState.dish.tags"
              :items="tagsLoaded"
              item-title="name"
              v-model:search="searchTags"
              return-object
              hide-details
              multiple
              variant="outlined"
              color="primary"
              rounded
            >
              <template v-slot:selection="{ item }">
                <v-chip
                  class="chip"
                  v-bind="item"
                  :input-value="item.selected"
                  closable
                  @click="item.select"
                  @click:close="removeTag(item.raw)"
                >
                  <v-avatar left>
                    <!-- <v-img
                      class="img-resized"
                      style="width: 48px"
                      :src="getPictureUrl(item.picture_uri)"
                    ></v-img> -->
                    {{ item.title }}
                  </v-avatar>
                </v-chip>
              </template>
              <template v-slot:item="{ props, item }">
                <!-- <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
    <template v-else> -->
                <v-list-item v-bind="props">
                  <template v-slot:prepend>
                    <v-list-item-avatar>
                      <img
                        class="mr-2"
                        :width="30"
                        :src="getPictureUrl(item.raw.picture_uri)"
                      />
                    </v-list-item-avatar>
                  </template>
                </v-list-item>
              </template>
              <!-- </template> -->
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
            <h3 class="mt-5 mb-3 ml-4">Ingrédients</h3>
            <v-card class="mt-6" flat variant="outlined">
              <v-card-text>
                <v-row>
                  <v-col sm="4">
                    <h4 class="mt-5 mb-3 ml-4">Ajouter un ingrédient</h4>
                    <v-row class="admin-divider-vertical-right pr-4">
                      <v-col cols="12" align="center">
                        <v-autocomplete
                          v-model="ingredientToAdd.ingredient"
                          :items="ingredientsLoaded"
                          item-title="name"
                          v-model:search="searchIngredients"
                          return-object
                          hide-details
                          variant="outlined"
                          color="primary"
                          rounded
                        >
                          <template v-slot:label>
                            <span class="checkbox-label">
                              Ingrédient
                              <span class="mandatory">*</span>
                            </span>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="ingredientToAdd.quantity"
                          hide-details
                          variant="outlined"
                          color="primary"
                          rounded
                        >
                          <template v-slot:label>
                            <span class="checkbox-label">
                              Quantité
                              <span class="mandatory">*</span>
                            </span>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="ingredientToAdd.unit_of_measure"
                          :items="unitsOfMeasureLoaded"
                          item-title="name"
                          v-model:search="searchUnitsOfMeasure"
                          return-object
                          hide-details
                          variant="outlined"
                          color="primary"
                          rounded
                        >
                          <template v-slot:label>
                            <span class="checkbox-label">
                              Unité
                              <span class="mandatory">*</span>
                            </span>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" align="center">
                        <v-btn
                          variant="text"
                          color="primary"
                          @click="addIngredient()"
                          >Ajouter
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col sm="8" class="pl-4">
                    <h4 class="mt-5 mb-3 ml-4">Aperçu des ingrédients</h4>
                    <v-row>
                      <v-col cols="12">
                        <v-list class="pa-0" dense>
                          <v-list-item
                            v-for="ingredient in formState.dish.ingredients"
                            :key="
                              ingredient.ingredient.id +
                              ingredient.quantity +
                              ingredient.unit_of_measure.name
                            "
                            class="admin-expanded-list-dense"
                          >
                            <template v-slot:title>
                              <div class="flex">
                                <div>
                                  <v-list-item-content class="pa-0">{{
                                    ingredient.ingredient.name
                                  }}</v-list-item-content>
                                </div>
                                <div>
                                  <v-list-item-action-text>
                                    <span
                                      v-if="ingredient.quantity"
                                      class="quantity"
                                    >
                                      {{ ingredient.quantity }}
                                    </span>
                                    <span
                                      v-if="ingredient.unit_of_measure"
                                      class="unit_of_measure mr-2"
                                    >
                                      {{
                                        '&nbsp;' +
                                        ingredient.unit_of_measure.name
                                      }}
                                    </span>
                                    <v-btn
                                      class="ma-0"
                                      style="opacity: 75%"
                                      icon
                                      variant="text"
                                      color="primary"
                                      @click="deleteIngredient(ingredient)"
                                    >
                                      <v-icon small class="pb-1">
                                        mdi-minus
                                      </v-icon>
                                    </v-btn>
                                  </v-list-item-action-text>
                                </div>
                              </div>
                            </template>

                            <v-list-item-action align="center">
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
            <h3 class="mt-5 mb-3 ml-4">Instructions</h3>

            <RichTextEditor
              :isDish="true"
              :content="dishToEdit.instructions"
              @change="changeInstructions($event)"
            />
          </v-col>
          <v-col cols="6">
            <v-divider></v-divider>
            <h3 class="mt-5 mb-3 ml-4">Photo</h3>
            <v-autocomplete
              v-model="formState.dish.pictures"
              :items="picturesLoaded"
              item-title="name"
              v-model:search="searchPictures"
              return-object
              hide-details
              multiple
              variant="outlined"
              rounded
            >
              <template v-slot:selection="{ item }">
                <v-chip
                  class="chip"
                  v-bind="item"
                  :input-value="item.selected"
                  closable
                  @click="item.select"
                  @click:close="removePicture(item.raw)"
                >
                  <v-avatar left>
                    <!-- <v-img :src="getPictureUrl(item.uri)"></v-img> -->
                    {{ item.title }}
                  </v-avatar>
                </v-chip>
              </template>
              <template v-slot:item="{ props, item }">
                <!-- <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template> -->
                <v-list-item v-bind="props">
                  <template v-slot:prepend>
                    <v-list-item-avatar>
                      <img
                        :width="48"
                        :height="48"
                        :src="getPictureUrl(item.raw.uri)"
                      />
                    </v-list-item-avatar>
                  </template>

                  <template v-slot:title>
                    <v-list-item-title
                      ><span class="ml-4 font-bold">
                        {{ item.raw.name }}
                      </span></v-list-item-title
                    >
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-divider></v-divider>
            <h3 class="mt-5 mb-3 ml-4">Affichage</h3>
            <v-checkbox
              v-model="formState.dish.display"
              class="ml-3"
              label="Afficher comme une recette pour les utilisateurs ?"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="emit('close')"> Fermer </v-btn>
        <v-btn
          aria-label="Valider"
          color="primary"
          type="submit"
          text
          @click="saveDish"
          :disabled="v$.$invalid || formState.dish.ingredients.length == 0"
        >
          Valider
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
:deep(.tui-editor-contents) {
  min-height: 250px !important;
  height: 250px !important;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
}

::v-deep .v-avatar.v-avatar--density-default {
  height: calc(var(--v-avatar-height) + 0px);
  width: auto;
}

.bg-grey-title {
  background-color: #e0e0e0;
}

::v-deep .v-list {
  overflow: hidden;
}

::v-deep .v-img__img--contain {
  object-fit: cover !important;
}

::v-deep .v-card--variant-outlined {
  border-color: #e0e0e0;
}

.font-bold {
  font-weight: bold;
}
</style>
