<script setup>
// #region Imports
import { ref, computed, watch } from 'vue'

import EditIngredientCategoryModal from '@/components/admin/ingredientCategories/EditIngredientCategoryModal'
import ConfirmDialog from '@/components/admin/miscellaneous/confirmDialog/ConfirmDialog'
import InitLoader from '@/components/initLoader/InitLoader'

import _ from 'lodash'

import adminService from '@/services/adminService'
// #endregion

// #region Data
const confirm = ref(null)

const isInit = ref(false)
const ingredientCategory = ref([])
const page = ref(1)
const totalItems = ref(0)
const itemsPerPage = ref(10)
const search = ref('')
const editIngredientCategoryModal = ref(false)
const ingredientCategoryToEdit = ref({})
// #endregion

// #region Computed
const pageCount = computed(() => {
  const totalPages = ~~(totalItems.value / itemsPerPage.value)
  const modulo = totalItems.value % itemsPerPage.value
  return modulo === 0 ? totalPages : totalPages + 1
})
const headers = computed(() => {
  return [
    { title: 'Nom', value: 'name' },
    {
      title: 'Actions',
      value: 'actions',
      sortable: false,
      width: '150px',
      fixed: true
    }
  ]
})
// #endregion

// #region Methods
const editIngredientCategory = (item) => {
  ingredientCategoryToEdit.value = item ? { ...item } : {}
  editIngredientCategoryModal.value = true
}
const deleteIngredientCategory = (id) => {
  confirm.value
    .open(
      'Supprimer la catégorie',
      'Souhaitez-vous vraiment supprimer cette catégorie ?',
      { color: 'primary' }
    )
    .then((confirm) => {
      if (confirm) {
        adminService.deleteIngredientCategory(id).then(() => {
          reload()
        })
      }
    })
}
const reload = _.debounce(() => {
  editIngredientCategoryModal.value = false
  adminService
    .getIngredientCategories(page.value, search.value)
    .then((response) => {
      totalItems.value = response.data.count
      ingredientCategory.value = response.data.results
    })
}, 500)
// #endregion

// #region Watch
watch(page, () => {
  reload()
})
watch(search, () => {
  page.value = 1
  reload()
})
// #endregion

// #region Created
adminService
  .getIngredientCategories()
  .then((response) => {
    totalItems.value = response.data.count
    ingredientCategory.value = response.data.results
    isInit.value = true
  })
  .catch(() => {})
// #endregion
</script>

<template>
  <v-container class="admin-wrapper-container" fluid>
    <v-container v-if="isInit" class="pa-0" fluid>
      <h1 class="mb-3">IngredientCategories</h1>
      <v-data-table
        :headers="headers"
        :items="ingredientCategory"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        class="py-4"
        hover
        density="compact"
        hide-default-footer
      >
        <template v-slot:bottom></template>
        <template v-slot:top>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="5" class="py-0" align="left">
              <h2 class="ml-5">Liste des catégories d'ingrédient</h2>
            </v-col>
            <v-col cols="8" sm="6" class="py-0" align="right">
              <v-text-field
                v-model="search"
                variant="outlined"
                rounded
                color="primary"
                label="Rechercher"
                class="mx-4 admin-search-bar"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="1" class="py-0" align="center">
              <v-btn
                style="opacity: 75%"
                icon
                variant="text"
                color="primary"
                @click="editIngredientCategory()"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            style="opacity: 75%"
            icon
            variant="text"
            @click="editIngredientCategory(item)"
          >
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            style="opacity: 75%"
            icon
            variant="text"
            @click="deleteIngredientCategory(item.id)"
          >
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <div class="text-center pt-2 mb-8">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="10"
          active-color="primary"
          density="comfortable"
          variant="elevated"
          opacity="100"
        ></v-pagination>
      </div>
      <EditIngredientCategoryModal
        v-if="editIngredientCategoryModal"
        :ingredientCategoryToEdit="ingredientCategoryToEdit"
        @close="reload()"
      />
      <ConfirmDialog ref="confirm" />
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped>
::v-deep .v-data-table-header__content {
  font-weight: bold;
}
</style>
