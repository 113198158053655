<script setup>
// #region Imports
import { ref } from 'vue'

import InitLoader from '@/components/initLoader/InitLoader'
import NavigationZone from '@/components/navigationZone/NavigationZone'
// #endregion

// #region Data
const isInit = ref(true)
const os = ref('Android')
// #endregion

// #region Methods
const toggleOs = () => {
  if (os.value === 'Android') {
    os.value = 'Apple'
  } else {
    os.value = 'Android'
  }
}
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0 pa-md-8 pb-md-0" fluid>
    <v-container v-if="isInit" class="pa-0" fluid>
      <NavigationZone />
      <v-container style="max-width: 700px" class="pa-4" fluid>
        <h1>{{ $t('joanaAdvices.joanaAdvices') }}</h1>
        <h2 class="mt-4">Installe ton espace client sur ton téléphone</h2>
        <p class="mt-4">Cela te permettra de retrouver facilement tes menus.</p>
        <v-row class="mt-11" no-gutters>
          <v-col align-self="center" cols="2"
            ><v-img
              width="43"
              aspect-ratio="1"
              :src="require('@/assets/img/smartphone.png')"
            ></v-img
          ></v-col>
          <v-col align-self="center">
            <span>Système d’exploitation</span>
          </v-col>
          <v-col align="end" align-self="center"
            ><v-btn color="white" class="os-btn" @click="toggleOs()"
              ><span>{{ os }}</span></v-btn
            ></v-col
          >
        </v-row>

        <h3 class="mt-10">Guide d'installation</h3>

        <div class="mt-10" v-if="os === 'Android'">
          <ul>
            <li li class="mt-2 mb-2">
              Clique sur les trois petits points en haut à droite
              <v-row class="mt-2 mb-2">
                <v-col>
                  <div class="img-center">
                    <v-img
                      class="img-width"
                      :max-width="300"
                      :width="300"
                      :src="require('@/assets/img/android1.png')"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
            </li>
            <li class="mt-2">
              Sélectionne «Ajouter à l’écran d’accueil» ou «Installer
              l'application»
              <v-row class="mt-2 mb-2">
                <v-col
                  ><div class="img-center">
                    <v-img
                      :max-width="300"
                      :src="require('@/assets/img/android2.png')"
                    ></v-img></div
                ></v-col>
              </v-row>
            </li>
            <li class="mt-2">
              La pastille Joana&Vous vient d’être ajoutée sur ton téléphone
              <v-row align-content="center">
                <v-col align="center">
                  <v-img
                    width="50"
                    class="mt-2"
                    src="@/assets/logo.png"
                  ></v-img>
                </v-col>
              </v-row>
            </li>
          </ul>
        </div>
        <div class="mt-11" v-if="os === 'Apple'">
          <div class="iphone-advertising mb-10">
            Ce guide d’installation fonctionne uniquement si tu as ouvert cette
            page via Safari. Si ce n’est pas le cas, tu peux passer cette étape.
            Tu pourras retrouver le tutoriel sur ton espace client ensuite
            <img :src="require('@/assets/icons/warning.png')" alt="" />
          </div>
          <ul>
            <li class="mt-2 mb-2">
              Clique sur l’onglet «Télécharger» en bas de page
              <v-row class="mt-2 mb-2">
                <v-col
                  ><div class="img-center">
                    <v-img
                      :max-width="300"
                      width="300"
                      :src="require('@/assets/img/apple1.png')"
                    ></v-img></div
                ></v-col>
              </v-row>
            </li>
            <li class="mt-2 mb-2">
              Sélectionne «Sur l’écran d’accueil»
              <v-row class="mt-2 mb-2">
                <v-col
                  ><div class="img-center">
                    <v-img
                      :max-width="300"
                      width="300"
                      height="46"
                      :src="require('@/assets/img/apple2.png')"
                    ></v-img></div
                ></v-col>
              </v-row>
            </li>
            <li class="mt-2">
              La pastille Joana&Vous vient d’être ajoutée sur ton téléphone
              <v-row align-content="center">
                <v-col align="center">
                  <v-img
                    width="50"
                    class="mt-2"
                    :src="require('@/assets/logo.png')"
                  ></v-img>
                </v-col>
              </v-row>
            </li>
          </ul>
        </div>
      </v-container>
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped lang="scss">
p,
ul {
  color: #323232;
}

h2 {
  font-weight: 400 !important;
}
h3 {
  font-size: 18px;
  font-weight: bold;
}

span {
  font-size: 14px !important;
  color: #707070;
}

.os-btn {
  span: {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 17px;
  }
}

.iphone-advertising {
  position: relative;
  background-color: #fec46d;
  color: white;
  border-radius: 7px;
  padding: 10px;
  font-size: 14px;
  line-height: 17px;

  img {
    width: 40px;
    top: -30px;
    height: 40px;
    position: absolute;
    right: 20px;
  }
}
ul {
  list-style-position: inside;
}
.img-center {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
