<template>
  <v-container class="wrapper-container pa-0">
    <v-container class="pa-0 pa-md-8">
      <NavigationZone />
      <v-container class="pa-4 pa-md-4">
        <h1>{{ $t('account.title') }}</h1>
        <AccountInformations></AccountInformations>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import NavigationZone from '@/components/navigationZone/NavigationZone'
import mixin from '@/mixins/mixin'
import AccountInformations from '@/components/Account/AccountInformations.vue'

export default {
  name: 'AccountInformationsView',
  mixins: [mixin],
  components: {
    NavigationZone,
    AccountInformations
  }
}
</script>

<style scoped lang="scss"></style>
