<template>
  <v-container
    class="wrapper-container pa-0 pa-md-8"
    fluid
  >
    <NavigationZone/>
    <v-container
      class="pa-4 mb-md-5"
      fluid
    >
      <h1>Oups !</h1>
      <p>Il semble que cette page n'existe pas.</p>
    </v-container>
  </v-container>
</template>

<script>
import NavigationZone from '@/components/navigationZone/NavigationZone'

export default {
  name: 'PageNotFound',
  components: {
    NavigationZone
  }
}
</script>

<style scoped lang="scss">

</style>
