<script setup>
// #region Imports
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'

import Markdown from 'vue3-markdown-it'

import InitLoader from '@/components/initLoader/InitLoader'
import NavigationZone from '@/components/navigationZone/NavigationZone'

import joanaAdviceCategoryService from '@/services/adminJoanaAdvice/joanaAdviceCategoryService'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Vuetify
const { smAndDown } = useDisplay()
// #endregion

// #region Data
const isInit = ref(false)
const adviceCategories = ref(null)
const expandedAdvices = ref([])
// #endregion

// #region Methods
const getPictureUrl = (uri) => {
  if (uri) {
    return process.env.VUE_APP_API_URL + '/' + uri
  }
}
const isExpanded = (id) => {
  return expandedAdvices.value.findIndex((x) => x === id) >= 0
}
const toggleExpandAdvice = (id) => {
  if (expandedAdvices.value.findIndex((x) => x === id) < 0) {
    expandedAdvices.value.push(id)
  } else {
    const index = expandedAdvices.value.findIndex((x) => x === id)
    expandedAdvices.value.splice(index, 1)
  }
}
const checkRoute = (name) => {
  router.push(name)
}
// #endregion

// #region Created
joanaAdviceCategoryService
  .getAdvicePublicCategories()
  .then((data) => {
    adviceCategories.value = data
    isInit.value = true
  })
  .catch((error) => {
    alert(error)
  })
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0 pa-md-8 pb-md-0" fluid>
    <v-container v-if="isInit" class="pa-0" fluid>
      <NavigationZone />
      <v-container style="max-width: 1200px" class="pa-4" fluid>
        <h1>{{ $t('joanaAdvices.joanaAdvices') }}</h1>
        <v-card
          v-if="smAndDown"
          align="left"
          height="85"
          @click="checkRoute({ name: 'HowTo' })"
        >
          <v-row align="center" class="fill-height px-2 py-3" no-gutters>
            <v-col>
              <v-row no-gutters>
                <h3>Revoir le tutoriel</h3>
              </v-row>
              <v-row no-gutters>
                <p>
                  Comprendre le fonctionnement de l’espace client pour atteindre
                  tes objectifs
                </p>
              </v-row>
            </v-col>
            <v-col align-self="center" cols="auto"
              ><v-img
                width="43"
                aspect-ratio="1"
                :src="require('@/assets/img/appuyez-sur-play.png')"
              ></v-img
            ></v-col>
          </v-row>
        </v-card>
        <v-card
          v-if="smAndDown"
          class="mt-6"
          height="85"
          @click="checkRoute({ name: 'InstallationInstructions' })"
        >
          <v-row align="center" class="fill-height px-2 py-3" no-gutters>
            <v-col>
              <h3>Installe ton espace client <br />sur le téléphone</h3>
            </v-col>
            <v-col align-self="center" cols="auto"
              ><v-img
                width="43"
                aspect-ratio="1"
                :src="require('@/assets/img/smartphone.png')"
              ></v-img
            ></v-col>
          </v-row>
        </v-card>

        <h3 class="mt-10 mb-0">Retrouve les réponses à toutes tes questions</h3>

        <v-row>
          <v-col
            class="my-0 px-md-6 py-0"
            cols="12"
            md="6"
            v-for="adviceCategory in adviceCategories"
            :key="adviceCategory.id"
          >
            <h2 class="mt-12">{{ adviceCategory.name }}</h2>
            <ul>
              <li
                class="mb-10"
                v-for="adviceSubCategory in adviceCategory.subcategories"
                :key="adviceSubCategory.id"
              >
                <h3 class="mt-2">{{ adviceSubCategory.name }}</h3>

                <Markdown
                  v-if="adviceSubCategory.legend"
                  class="md-body mt-2 advice-legend"
                  :source="adviceSubCategory.legend"
                />

                <ul>
                  <li
                    class="mt-4"
                    v-for="advice in adviceSubCategory.advices"
                    :key="advice.id"
                  >
                    <v-card v-if="advice" class="pa-3">
                      <v-row
                        class="mb-"
                        no-gutters
                        @click="toggleExpandAdvice(advice.id)"
                      >
                        <v-col align-self="center" cols="2">
                          <v-img
                            v-if="advice.icon"
                            width="40"
                            height="40"
                            aspect-ration="1"
                            :src="getPictureUrl(advice.icon)"
                          ></v-img>
                        </v-col>
                        <v-col align-self="center">
                          <p
                            :class="{ expanded: isExpanded(advice.id) }"
                            class="advice-question"
                          >
                            {{ advice.question }}
                          </p>
                        </v-col>
                        <v-col cols="1" align-self="center">
                          <v-icon width="40" v-if="!isExpanded(advice.id)"
                            >mdi-chevron-down</v-icon
                          >
                          <v-icon v-if="isExpanded(advice.id)"
                            >mdi-chevron-up</v-icon
                          >
                        </v-col>
                      </v-row>
                      <v-expand-transition>
                        <div class="pt-5" v-show="isExpanded(advice.id)">
                          <Markdown
                            v-if="advice.response"
                            class="md-body advice-response"
                            :source="advice.response"
                          />

                          <v-img
                            class="mt-5"
                            v-if="advice.photo"
                            aspect-ration="1"
                            :src="getPictureUrl(advice.photo)"
                          ></v-img>
                        </div>
                      </v-expand-transition>
                    </v-card>
                  </li>
                </ul>
              </li>
            </ul>
          </v-col>
        </v-row>

        <!-- <v-row><v-col v-for="adviceCategory in adviceCategories" :key="adviceCategory.id" cols="6">
            test
          </v-col></v-row> -->
      </v-container>
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped lang="scss">
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h2 {
  color: #eb5c43;
  font-family: made-gentle !important;
  font-weight: normal;
}

h3 {
  font-size: 16px;
}

.v-card {
  border-radius: 7px !important;

  p {
    // color: #707070;
    font-size: 14px;
    margin: 0;
  }
}

.md-body {
  list-style-position: inside !important;
}
.advice-legend {
  list-style-position: inside !important;

  ol {
    list-style-position: inside !important;
  }
  ul {
    list-style-position: inside !important;
  }
  .markdown-body {
    ul {
      list-style-position: inside !important;
    }
    p {
      color: #323232 !important;
    }
  }
}

.advice-question {
  color: #323232;
  font-weight: bold;
  font-family: Barlow-Bold;

  &.expanded {
    color: #eb5c43;
  }
}

.advice-response {
  color: #323232;
}
.txt-gray {
  color: #707070 !important;
}

.txt-small {
  font-size: 14px !important;
}

.txt-primary {
  color: #eb5c43 !important;
}
</style>
