<template>
  <v-card class="my-5">
    <v-card-title>
      <h2 class="capitalize mb-0">Notes</h2>
    </v-card-title>
    <v-card-text>
      <v-textarea
        v-if="user"
        class="m-0 p-0"
        no-resize
        variant="flat"
        name="notes"
        v-model="user.shopping_note"
      ></v-textarea>
    </v-card-text>
    <v-card-actions>
      <v-row justify="end" class="mb-3 mr-4">
        <v-col cols="auto">
          <v-btn @click="saveNotes()" text class="link boutonPrincipales mt-4">
            Enregistrer
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css'
import userService from '@/services/userService'

export default {
  name: 'ShoppingListNotes',
  data: () => ({
    user: {},
    isInit: false
  }),
  created() {
    userService
      .getUser()
      .then((response) => {
        this.user = response.data
      })
      .catch(() => {})
  },
  methods: {
    saveNotes() {
      userService.updateUser(this.user).catch((_) => {})
    }
  }
}
</script>

<style scoped lang="scss">
.v-card-title {
  padding: 16px !important;
}
</style>
