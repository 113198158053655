<script setup>
import { ref } from 'vue'

import adminService from '@/services/adminService'

// #region Data
const programsCount = ref({ loading: true, count: 0 })
const dishesCount = ref({ loading: true, count: 0 })
const ingredientsCount = ref({ loading: true, count: 0 })
const usersCount = ref({ loading: true, count: 0 })
const imagesCount = ref({ loading: true, count: 0 })
const tagsCount = ref({ loading: true, count: 0 })
const unitsCount = ref({ loading: true, count: 0 })
const categoriesCount = ref({ loading: true, count: 0 })
const cgvCount = ref({ loading: true, count: 0 })

// #endregion

// #region Methods
const getProgramsCount = () => {
  adminService.getMealPlans(null).then((response) => {
    programsCount.value = { loading: false, count: response.data.count }
  })
}
const getDishesCount = () => {
  adminService.getDishes(null).then((response) => {
    dishesCount.value = { loading: false, count: response.data.count }
  })
}
const getIngredientsCount = () => {
  adminService.getIngredients(null).then((response) => {
    ingredientsCount.value = { loading: false, count: response.data.count }
  })
}
const getUsersCount = () => {
  adminService.getProfiles(null).then((response) => {
    usersCount.value = { loading: false, count: response.data.count }
  })
}
const getImagesCount = () => {
  adminService.getPictures(null).then((response) => {
    imagesCount.value = { loading: false, count: response.data.count }
  })
}
const getTagsCount = () => {
  adminService.getTags(null).then((response) => {
    tagsCount.value = { loading: false, count: response.data.count }
  })
}
const getUnitsCount = () => {
  adminService.getUnitsOfMeasure(null).then((response) => {
    unitsCount.value = { loading: false, count: response.data.count }
  })
}
const getCategoriesCount = () => {
  adminService.getIngredientCategories(null).then((response) => {
    categoriesCount.value = { loading: false, count: response.data.count }
  })
}
const getCgvCount = () => {
  adminService.getTAC(null).then((response) => {
    cgvCount.value = { loading: false, count: response.data.count }
  })
}

// #endregion

// #region Created
getProgramsCount()
getDishesCount()
getIngredientsCount()
getUsersCount()
getImagesCount()
getTagsCount()
getUnitsCount()
getCategoriesCount()
getCgvCount()
// #endregion
</script>

<template>
  <v-container class="admin-wrapper-container" fluid>
    <v-row>
      <v-col>
        <h1 class="title">Dashboard Admin</h1>
        <p class="semibold">
          Surveillez l’évolution de votre application grâce à un tableau de bord
          unique, où vous pouvez visualiser les données et statistiques
          essentielles.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="no-grow">
        <v-sheet
          :elevation="1"
          :height="200"
          :width="200"
          :max-width="200"
          rounded
        >
          <v-container>
            <v-row>
              <v-col class="centerFlex">
                <v-avatar color="primary" size="80">
                  <v-icon icon="mdi-calendar" size="x-large"></v-icon>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="centerFlex">
                <div class="flexCols">
                  <v-skeleton-loader
                    v-if="programsCount.loading"
                    width="100"
                    type="text"
                  ></v-skeleton-loader>
                  <div v-if="!programsCount.loading" class="unit">
                    {{ programsCount.count }}
                  </div>
                  <div class="titleUnit">Programmes</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
      <v-col class="no-grow">
        <v-sheet :elevation="1" :height="200" :width="200" rounded>
          <v-container>
            <v-row>
              <v-col class="centerFlex">
                <v-avatar color="primary" size="80">
                  <v-icon icon="mdi-noodles" size="x-large"></v-icon>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="centerFlex">
                <div class="flexCols">
                  <v-skeleton-loader
                    v-if="dishesCount.loading"
                    width="100"
                    type="text"
                  ></v-skeleton-loader>
                  <div v-if="!dishesCount.loading" class="unit">
                    {{ dishesCount.count }}
                  </div>
                  <div class="titleUnit">Recettes</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
      <v-col class="no-grow">
        <v-sheet :elevation="1" :height="200" :width="200" rounded>
          <v-container>
            <v-row>
              <v-col class="centerFlex">
                <v-avatar color="primary" size="80">
                  <v-icon icon="mdi-food-apple" size="x-large"></v-icon>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="centerFlex">
                <div class="flexCols">
                  <v-skeleton-loader
                    v-if="ingredientsCount.loading"
                    width="100"
                    type="text"
                  ></v-skeleton-loader>
                  <div v-if="!ingredientsCount.loading" class="unit">
                    {{ ingredientsCount.count }}
                  </div>
                  <div class="titleUnit">Ingrédients</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
      <v-col class="no-grow">
        <v-sheet :elevation="1" :height="200" :width="200" rounded>
          <v-container>
            <v-row>
              <v-col class="centerFlex">
                <v-avatar color="primary" size="80">
                  <v-icon icon="mdi-account" size="x-large"></v-icon>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="centerFlex">
                <div class="flexCols">
                  <v-skeleton-loader
                    v-if="usersCount.loading"
                    width="100"
                    type="text"
                  ></v-skeleton-loader>
                  <div v-if="!usersCount.loading" class="unit">
                    {{ usersCount.count }}
                  </div>
                  <div class="titleUnit">Utilisateurs</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
      <v-col class="no-grow">
        <v-sheet :elevation="1" :height="200" :width="200" rounded>
          <v-container>
            <v-row>
              <v-col class="centerFlex">
                <v-avatar color="primary" size="80">
                  <v-icon icon="mdi-camera" size="x-large"></v-icon>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="centerFlex">
                <div class="flexCols">
                  <v-skeleton-loader
                    v-if="imagesCount.loading"
                    width="100"
                    type="text"
                  ></v-skeleton-loader>
                  <div v-if="!imagesCount.loading" class="unit">
                    {{ imagesCount.count }}
                  </div>
                  <div class="titleUnit">Images</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>

      <v-col class="no-grow">
        <v-sheet :elevation="1" :height="200" :width="200" rounded>
          <v-container>
            <v-row>
              <v-col class="centerFlex">
                <v-avatar color="primary" size="80">
                  <v-icon icon="mdi-tag" size="x-large"></v-icon>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="centerFlex">
                <div class="flexCols">
                  <v-skeleton-loader
                    v-if="tagsCount.loading"
                    width="100"
                    type="text"
                  ></v-skeleton-loader>
                  <div v-if="!tagsCount.loading" class="unit">
                    {{ tagsCount.count }}
                  </div>
                  <div class="titleUnit">Tags</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
      <v-col class="no-grow">
        <v-sheet :elevation="1" :height="200" :width="200" rounded>
          <v-container>
            <v-row>
              <v-col class="centerFlex">
                <v-avatar color="primary" size="80">
                  <v-icon icon="mdi-weight-gram" size="x-large"></v-icon>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="centerFlex">
                <div class="flexCols">
                  <v-skeleton-loader
                    v-if="unitsCount.loading"
                    width="100"
                    type="text"
                  ></v-skeleton-loader>
                  <div v-if="!unitsCount.loading" class="unit">
                    {{ unitsCount.count }}
                  </div>
                  <div class="titleUnit">Unités de mesure</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
      <v-col class="no-grow">
        <v-sheet :elevation="1" :height="200" :width="200" rounded>
          <v-container>
            <v-row>
              <v-col class="centerFlex">
                <v-avatar color="primary" size="80">
                  <v-icon icon="mdi-shape" size="x-large"></v-icon>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="centerFlex">
                <div class="flexCols">
                  <v-skeleton-loader
                    v-if="categoriesCount.loading"
                    width="100"
                    type="text"
                  ></v-skeleton-loader>
                  <div v-if="!categoriesCount.loading" class="unit">
                    {{ categoriesCount.count }}
                  </div>
                  <div class="titleUnit">Catégorie d'ingrédients</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
      <v-col class="no-grow">
        <v-sheet :elevation="1" :height="200" :width="200" rounded>
          <v-container>
            <v-row>
              <v-col class="centerFlex">
                <v-avatar color="primary" size="80">
                  <v-icon icon="mdi-file-document" size="x-large"></v-icon>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="centerFlex">
                <div class="flexCols">
                  <v-skeleton-loader
                    v-if="cgvCount.loading"
                    width="100"
                    type="text"
                  ></v-skeleton-loader>
                  <div v-if="!cgvCount.loading" class="unit">
                    {{ cgvCount.count }}
                  </div>
                  <div class="titleUnit">Conditions générales</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col>1</v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.title {
  font-family: 'made-gentle';
}
.semibold {
  font-weight: bolder;
  color: #707070 !important;
  max-width: 768px;
}
.centerFlex {
  display: flex;
  justify-content: center;
}
.flexCols {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.unit {
  font-family: 'made-gentle' !important;
  line-height: initial;
  font-size: xx-large;
  font-weight: bold;
}
.titleUnit {
  color: #707070 !important;
  line-height: initial;
  font-size: medium;
  font-weight: bold;
}
.no-grow {
  flex-grow: revert !important;
}
</style>
