<script setup>
// #region Imports
import { reactive, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import { useI18n } from 'vue-i18n'

import _ from 'lodash'

import moment from 'moment'

import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, sameAs } from '@vuelidate/validators'

import TAC from '@/components/tac/TAC'

import authService from '@/services/authService'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Vuetify
const { smAndDown } = useDisplay()
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Data
const picker = ref(null)
const menuBirthday = ref(null)
const menuBirthdayValue = ref(false)

const dialogTAC = ref(false)
const emailAlreadyUsedSnackbar = ref(false)
// #endregion

// #region Form Data
const formState = reactive({
  user: {
    firstName: '',
    lastName: '',
    email: '',
    birthday: null,
    phone: '',
    password: '',
    validateTAC: false,
    newsletter: false
  },
  passwordConfirmation: ''
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    user: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      birthday: { required },
      password: {
        required,
        minLength: minLength(9),
        containsUppercase: (value) => {
          return /[A-Z]/.test(value)
        },
        containsLowercase: (value) => {
          return /[a-z]/.test(value)
        },
        containsNumber: (value) => {
          return /[0-9]/.test(value)
        },
        containsSpecial: (value) => {
          return /[#?!@$%^&*-]/.test(value)
        }
      },
      validateTAC: { checked: sameAs(true) }
    },
    passwordConfirmation: {
      required,
      sameAs: sameAs(computed(() => formState.user.password))
    }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Computed
const firstNameErrors = computed(() => {
  if (
    v$.value.user?.firstName.$dirty &&
    v$.value.user?.firstName.required.$invalid
  ) {
    return t('message.error.required')
  }
  return []
})
const lastNameErrors = computed(() => {
  if (
    v$.value.user?.lastName.$dirty &&
    v$.value.user?.lastName.required.$invalid
  ) {
    return t('message.error.required')
  }
  return []
})
const birthdayErrors = computed(() => {
  if (
    v$.value.user.birthday.$dirty &&
    v$.value.user.birthday.required.$invalid
  ) {
    return t('message.error.required')
  }
  return []
})
const emailErrors = computed(() => {
  if (v$.value.user?.email.$dirty) {
    if (v$.value.user?.email.required.$invalid) {
      return t('message.error.required')
    } else if (v$.value.user?.email.email.$invalid) {
      return t('message.error.email')
    }
  }
  return []
})
const passwordErrors = computed(() => {
  if (v$.value.user?.password.$dirty) {
    if (v$.value.user?.password.required.$invalid) {
      return t('message.error.required')
    } else if (v$.value.user?.password.containsUppercase.$invalid) {
      return t('message.error.containsUppercase')
    } else if (v$.value.user?.password.containsLowercase.$invalid) {
      return t('message.error.containsLowercase')
    } else if (v$.value.user?.password.containsNumber.$invalid) {
      return t('message.error.containsNumber')
    } else if (v$.value.user?.password.containsSpecial.$invalid) {
      return t('message.error.containsSpecial')
    } else if (v$.value.user?.password.minLength.$invalid) {
      return t('message.error.minLength')
    }
  }
  return []
})
const validateTACErrors = computed(() => {
  if (
    v$.value.user?.validateTAC?.$dirty &&
    v$.value.user?.validateTAC?.checked.$invalid
  ) {
    return t('message.error.required')
  }
  return []
})
const passwordConfirmationErrors = computed(() => {
  if (v$.value.passwordConfirmation?.$dirty) {
    if (v$.value.passwordConfirmation.required.$invalid) {
      return t('message.error.required')
    } else if (v$.value.passwordConfirmation.sameAs.$invalid) {
      return t('message.error.sameAs')
    }
  }
  return []
})
const birthdayFormatted = computed(() => {
  return formState.user.birthday
    ? moment(formState.user.birthday).format('DD/MM/YYYY')
    : null
})
// #endregion

// #region Methods
const changeDate = (date) => {
  menuBirthdayValue.value = false
}
const signup = _.debounce(() => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    const credentials = {
      email: formState.user.email,
      password: formState.user.password,
      first_name: formState.user.firstName,
      last_name: formState.user.lastName,
      birthday: moment(formState.user.birthday).format('YYYY-MM-DD'),
      terms_and_conditions: formState.user.validateTAC,
      newsletter: formState.user.newsletter
    }
    if (formState.user.phone === '') {
      credentials.phone_number = null
    } else {
      credentials.phone_number = formState.user.phone
    }
    authService
      .signUp(credentials)
      .then(() => {
        checkRoute({ name: 'SignupRedirection' })
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          emailAlreadyUsedSnackbar.value = true
        }
      })
  }
}, 1000)
const checkRoute = (name) => {
  router.push(name)
}
// #endregion
</script>

<template>
  <v-container class="wrapper-without-navigation">
    <v-container class="content">
      <v-row>
        <v-col cols="12" sm="12" align="center">
          <v-container class="main-title my-5">
            <v-img
              src="@/assets/img/Joana_Horizontal_v1.png"
              style="display: block; margin-left: auto; margin-right: auto"
            >
            </v-img>
          </v-container>
        </v-col>
      </v-row>
      <v-row v-if="formState && formState.user">
        <v-col cols="6" align="center" class="pb-0">
          <v-text-field
            v-model="formState.user.firstName"
            :error-messages="firstNameErrors"
            @input="v$.user.firstName.$touch()"
            @blur="v$.user.firstName.$touch()"
            variant="outlined"
            rounded
          >
            <template v-slot:label>
              <span class="checkbox-label">
                {{ $t('signup.firstName') }}
                <span class="mandatory">*</span>
              </span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6" align="center" class="pb-0">
          <v-text-field
            v-model="formState.user.lastName"
            :error-messages="lastNameErrors"
            @input="v$.user.lastName.$touch()"
            @blur="v$.user.lastName.$touch()"
            variant="outlined"
            rounded
          >
            <template v-slot:label>
              <span class="checkbox-label">
                {{ $t('signup.lastName') }}
                <span class="mandatory">*</span>
              </span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" align="center" class="py-0">
          <v-menu
            ref="menuBirthday"
            v-model="menuBirthdayValue"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ props: menu }">
              <v-text-field
                v-bind="menu"
                class="datepicker"
                v-model="birthdayFormatted"
                :error-messages="birthdayErrors"
                v-on="on"
                readonly
                variant="outlined"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    {{ $t('signup.birthday') }}
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              class="datepicker"
              color="primary"
              ref="picker"
              v-model="formState.user.birthday"
              :max="new Date().toISOString().substr(0, 10)"
              @update:model-value="changeDate"
            ></v-date-picker>
            <!-- <v-date-picker
              ref="picker"

              locale="fr-FR"
            ></v-date-picker> -->
          </v-menu>
        </v-col>
        <v-col cols="6" align="center" class="py-0">
          <v-text-field
            v-model="formState.user.phone"
            :label="$t('signup.phone')"
            variant="outlined"
            rounded
          >
            <template v-slot:label>
              <span class="checkbox-label">{{ $t('signup.phone') }}</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" align="center" class="py-0">
          <v-text-field
            v-model="formState.user.email"
            :error-messages="emailErrors"
            @input="v$.user.email.$touch()"
            @blur="v$.user.email.$touch()"
            variant="outlined"
            rounded
          >
            <template v-slot:label>
              <span class="checkbox-label">
                {{ $t('signup.email') }}
                <span class="mandatory">*</span>
              </span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" align="center" class="py-0">
          <v-text-field
            v-model="formState.user.password"
            type="password"
            :error-messages="passwordErrors"
            @input="v$.user.password.$touch()"
            @blur="v$.user.password.$touch()"
            variant="outlined"
            rounded
          >
            <template v-slot:label>
              <span class="checkbox-label">
                {{ $t('signup.password') }}
                <span class="mandatory">*</span>
              </span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" align="center" class="py-0">
          <v-text-field
            v-model="formState.passwordConfirmation"
            type="password"
            :error-messages="passwordConfirmationErrors"
            @input="v$.passwordConfirmation.$touch()"
            @blur="v$.passwordConfirmation.$touch()"
            variant="outlined"
            rounded
          >
            <template v-slot:label>
              <span class="checkbox-label">
                {{ $t('signup.passwordConfirmation') }}
                <span class="mandatory">*</span>
              </span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col class="py-0">
          <v-select
            label="Régime"
            v-model="user.diet"
            item-value="value"
            item-text="display"
            :items="dietItems"
            outlined
            rounded
          >
            <template v-slot:label>
              <span>
                Régime
                <span class="mandatory">*</span>
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span class="selected-item">{{ item.display }}</span>
            </template>
          </v-select>
        </v-col>
      </v-row> -->
      <!-- <v-row class="px-3 mb-5" v-if="vegeAdvertising">
        <p class="vege-advertising">{{ vegeAdvertising }}</p>
      </v-row> -->
      <v-row class="mt-0">
        <v-col cols="12" sm="12" align="center" class="py-0">
          <v-checkbox
            color="primary"
            v-model="formState.user.validateTAC"
            class="mt-0"
            :error-messages="validateTACErrors"
            @input="v$.user.validateTAC.$touch()"
            @blur="v$.user.validateTAC.$touch()"
            hide-details
          >
            <template v-slot:label>
              <span class="txt-left">
                <span class="checkbox-label mr-1">{{
                  $t('signup.tac.validateTAC')
                }}</span>
                <span
                  class="checkbox-label signup mr-1"
                  @click="dialogTAC = true"
                >
                  {{ $t('signup.tac.TAC') }}
                </span>
                <span class="checkbox-label mandatory">*</span>
              </span>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center" class="pt-0" cols="12" sm="12">
          <v-checkbox
            color="primary"
            v-model="formState.user.newsletter"
            :label="$t('signup.newsletter')"
            hide-details
          >
            <template v-slot:label>
              <span class="checkbox-label txt-left">{{
                $t('signup.newsletter')
              }}</span>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" align="center">
          <v-btn
            class="confirm-button txt-small mt-3"
            color="primary"
            @click="signup"
            :disabled="v$.$invalid"
          >
            {{ $t('signup.button') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" align="center">
          <span
            class="login txt-small txt-gray"
            @click="checkRoute({ name: 'Login' })"
          >
            {{ $t('signup.login') }}
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="emailAlreadyUsedSnackbar"
      timeout="10000"
      color="error"
      dark
      top
    >
      {{ $t('signup.emailAlreadyUsed') }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="emailAlreadyUsedSnackbar = false">
          {{ $t('general.button.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogTAC"
      width="800"
      :fullscreen="smAndDown"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="primary" v-if="smAndDown">
          <v-btn icon dark @click="dialogTAC = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('signup.tac.title') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-title v-else>{{ $t('signup.tac.title') }}</v-card-title>
        <v-card-text class="pt-5">
          <TAC />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped lang="scss">
.content {
  max-width: 500px !important;
}

.main-title {
  font-size: 40px;
  line-height: 41px;
  letter-spacing: -0.02em;
}

.confirm-button {
  width: 300px;
  height: 50px !important;
  border-radius: 15px;
}

.confirm-button.v-btn--disabled {
  background-color: #d8d8d8 !important;
}

.confirm-button.v-btn__overlay {
  opacity: 0% !important;
}

::v-deep .confirm-button {
  color: rgba(0, 0, 0, 0.26) !important;
  font-size: 14px !important;
}

::v-deep .confirm-button.v-btn:not(.v-btn--disabled) span {
  color: white !important;
}

.login {
  cursor: pointer;
}

::v-deep .checkbox-label {
  font-size: 14px !important;
}

.signup {
  color: #eb5c43 !important;
}

.signup {
  color: var(--v-primary-base);
}

.mandatory {
  color: #eb5c43 !important;
}

.vege-advertising {
  color: #eb5c43;
}

::v-deep .v-input__details {
  margin-bottom: 8px;
}
::v-deep .v-messages__message {
  text-align: left;
}

::v-deep .v-label {
  opacity: 100%;
  color: #707070;
}

.txt-gray {
  color: #707070 !important;
}

.txt-small {
  font-size: 14px !important;
}

.txt-left {
  text-align: left;
}
</style>
