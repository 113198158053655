<script setup>
// #region Imports
import { useRouter } from 'vue-router'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Methods
const checkRoute = (route) => {
  router.push(route)
}
// #endregion
</script>

<template>
  <v-navigation-drawer absolute permanent rail color="primary">
    <v-list nav theme="dark">
      <v-list-item link @click="checkRoute({ name: 'MealPlansList' })">
        <template v-slot:prepend>
          <v-list-item-icon>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-icon>
        </template>
        <v-list-item-title>Programmes</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="checkRoute({ name: 'DishesList' })">
        <template v-slot:prepend>
          <v-list-item-icon>
            <v-icon>mdi-noodles</v-icon>
          </v-list-item-icon>
        </template>

        <v-list-item-title>Recettes</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="checkRoute({ name: 'IngredientsList' })">
        <template v-slot:prepend>
          <v-list-item-icon>
            <v-icon>mdi-food-apple</v-icon>
          </v-list-item-icon>
        </template>
        <v-list-item-title>Ingrédients</v-list-item-title>
      </v-list-item>
      <v-divider style="margin-bottom: 4px"></v-divider>
      <v-list-item link @click="checkRoute({ name: 'ProfilesList' })">
        <template v-slot:prepend>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
        </template>
        <v-list-item-title>Users</v-list-item-title>
      </v-list-item>
      <v-divider style="margin-bottom: 4px"></v-divider>
      <v-list-item link @click="checkRoute({ name: 'PicturesList' })">
        <template v-slot:prepend>
          <v-list-item-icon>
            <v-icon>mdi-camera</v-icon>
          </v-list-item-icon>
        </template>
        <v-list-item-title>Pictures</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="checkRoute({ name: 'TagsList' })">
        <template v-slot:prepend>
          <v-list-item-icon>
            <v-icon>mdi-tag</v-icon>
          </v-list-item-icon>
        </template>
        <v-list-item-title>Pictures</v-list-item-title>
      </v-list-item>
      <v-divider style="margin-bottom: 4px"></v-divider>
      <v-list-item link @click="checkRoute({ name: 'UnitsOfMeasureList' })">
        <template v-slot:prepend>
          <v-list-item-icon>
            <v-icon>mdi-weight-gram</v-icon>
          </v-list-item-icon>
        </template>
        <v-list-item-title>UnitsOfMeasure</v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        @click="checkRoute({ name: 'IngredientCategoriesList' })"
      >
        <template v-slot:prepend>
          <v-list-item-icon>
            <v-icon>mdi-shape</v-icon>
          </v-list-item-icon>
        </template>
        <v-list-item-title>Pictures</v-list-item-title>
      </v-list-item>
      <v-divider style="margin-bottom: 4px"></v-divider>
      <v-list-item link @click="checkRoute({ name: 'TACList' })">
        <template v-slot:prepend>
          <v-list-item-icon>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-icon>
        </template>
        <v-list-item-title>TACList</v-list-item-title>
      </v-list-item>
      <v-divider style="margin-bottom: 4px"></v-divider>
      <v-list-item link @click="checkRoute({ name: 'JoanaAdvicesCategories' })">
        <template v-slot:prepend>
          <v-list-item-icon>
            <v-icon>mdi-lightbulb-on</v-icon>
          </v-list-item-icon>
        </template>
        <v-list-item-title>JoanaAdvicesCategories</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<style scoped></style>
