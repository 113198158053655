<template>
  <v-bottom-navigation
    v-model="activeButton"
    color="primary"
    class="gray-light-base"
    grow
    fixed
  >
    <v-btn
      @click="checkRoute({ name: 'MealPlan' })"
      :aria-label="$t('menu.mealPlan')"
    >
      <v-icon>mdi-silverware-fork-knife</v-icon>
    </v-btn>
    <v-btn @click="checkRoute({ name: 'Fav' })" :aria-label="$t('menu.fav')">
      <IconCook></IconCook>
    </v-btn>
    <v-btn
      @click="checkRoute({ name: 'JoanaAdvices' })"
      :aria-label="$t('menu.joanaAdvices')"
    >
      <v-icon>mdi-help-circle</v-icon>
    </v-btn>
    <v-btn
      @click="checkRoute({ name: 'Account' })"
      :aria-label="$t('menu.account')"
    >
      <v-icon>mdi-account</v-icon>
    </v-btn>
    <!--<v-btn
      :href="'mailto:' + feedbackEmail"
      aria-label="Feedback"
    >
      <v-icon>mdi-email</v-icon>
    </v-btn>-->
  </v-bottom-navigation>
</template>

<script>
import mixin from '@/mixins/mixin'
import IconCook from '../icons/IconCook.vue'

export default {
  name: 'BottomMenu',
  mixins: [mixin],
  data: () => ({
    activeButton: '',
    contactEmail: 'root@example.com'
  }),
  computed: {
    feedbackEmail() {
      return process.env.VUE_APP_FEEDBACK_EMAIL
    }
  },
  created() {
    this.setActiveButton()
  },
  watch: {
    $route() {
      this.setActiveButton()
    }
  },
  methods: {
    setActiveButton() {
      switch (this.$route.name) {
        case 'MealPlan':
          this.activeButton = 0
          break
        case 'Fav':
          this.activeButton = 1
          break
        case 'JoanaAdvices':
          this.activeButton = 2
          break
        case 'Account':
          this.activeButton = 3
          break
      }
    }
  },
  components: { IconCook }
}
</script>

<style scoped lang="scss">
.v-btn {
  height: 100% !important;
}

::v-deep .v-btn:not(.v-btn--active) span {
  color: #adadad !important;
}
::v-deep .v-btn__overlay {
  background-color: transparent !important;
}
.gray-light-base {
  background-color: #f5f5f5 !important;
}
</style>
