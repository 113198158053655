<script setup>
// #region Imports
import { ref, computed, watch } from 'vue'
import { useDisplay } from 'vuetify'
import Markdown from 'vue3-markdown-it'

import EditTACModal from '@/components/admin/tac/EditTACModal'
import ConfirmDialog from '@/components/admin/miscellaneous/confirmDialog/ConfirmDialog'
import InitLoader from '@/components/initLoader/InitLoader'

import moment from 'moment'

import _ from 'lodash'

import adminService from '@/services/adminService'
// #endregion

// #region Vuetify
const { smAndDown } = useDisplay()
// #endregion

// #region Data
const confirm = ref(null)

const isInit = ref(false)
const tac = ref([])
const page = ref(1)
const totalItems = ref(0)
const itemsPerPage = ref(10)
const editTACModal = ref(false)
const displayTACModal = ref(false)
const tacToManage = ref({})
// #endregion

// #region Computed
const pageCount = computed(() => {
  const totalPages = ~~(totalItems.value / itemsPerPage.value)
  const modulo = totalItems.value % itemsPerPage.value
  return modulo === 0 ? totalPages : totalPages + 1
})
const headers = computed(() => {
  return [
    { title: 'Date publication', value: 'publication_date' },
    {
      title: 'Actions',
      value: 'actions',
      sortable: false,
      width: '150px',
      fixed: true
    }
  ]
})
// #endregion

// #region Methods
const formattedPublicationDate = (date) => {
  return moment(date).format('DD/MM/YYYY')
}
const displayTAC = (item) => {
  tacToManage.value = item ? { ...item } : { body: '' }
  displayTACModal.value = true
}
const editTAC = (item) => {
  tacToManage.value = item ? { ...item } : { body: '' }
  editTACModal.value = true
}
const deleteTAC = (id) => {
  confirm.value
    .open(
      'Supprimer les conditions générales',
      'Souhaitez-vous vraiment supprimer ces conditions générales ?',
      { color: 'primary' }
    )
    .then((confirm) => {
      if (confirm) {
        adminService.deleteTAC(id).then(() => {
          reload()
        })
      }
    })
}
const reload = _.debounce(() => {
  editTACModal.value = false
  adminService.getTAC(page.value).then((response) => {
    totalItems.value = response.data.count
    tac.value = response.data.results
  })
}, 500)
// #endregion

// #region Watch
watch(page, () => {
  reload()
})
// #endregion

// #region Created
adminService
  .getTAC()
  .then((response) => {
    totalItems.value = response.data.count
    tac.value = response.data.results
    isInit.value = true
  })
  .catch(() => {})
// #endregion
</script>

<template>
  <v-container class="admin-wrapper-container" fluid>
    <v-container v-if="isInit" class="pa-0" fluid>
      <h1 class="mb-3">Conditions générales</h1>
      <v-data-table
        :headers="headers"
        :items="tac"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        hover
        density="compact"
        class="py-4"
        hide-default-footer
      >
        <template v-slot:bottom></template>
        <template v-slot:top>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="11" align="left">
              <h2 class="ml-5">Liste des conditions générales</h2>
            </v-col>
            <v-col cols="4" sm="1" align="center">
              <v-btn icon variant="text" color="primary" @click="editTAC()">
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.publication_date`]="{ item }">
          {{ formattedPublicationDate(item.publication_date) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="flex">
            <v-btn
              style="opacity: 75%"
              icon
              variant="text"
              @click="displayTAC(item)"
            >
              <v-icon> mdi-eye </v-icon>
            </v-btn>
            <v-btn
              style="opacity: 75%"
              icon
              variant="text"
              @click="editTAC(item)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              style="opacity: 75%"
              icon
              variant="text"
              @click="deleteTAC(item.id)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <div class="text-center pt-2 mb-8">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="10"
          active-color="primary"
          density="comfortable"
          variant="elevated"
          opacity="100"
        ></v-pagination>
      </div>
      <EditTACModal
        v-if="editTACModal"
        :tacToManage="tacToManage"
        @close="reload()"
      />
      <ConfirmDialog ref="confirm" />
    </v-container>
    <InitLoader v-if="!isInit" />
    <v-dialog
      v-model="displayTACModal"
      width="800"
      :fullscreen="smAndDown"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="primary" v-if="smAndDown">
          <v-btn icon dark @click="displayTACModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            $t('signup.tac.title') +
            ' - ' +
            formattedPublicationDate(tacToManage.publication_date)
          }}</v-toolbar-title>
        </v-toolbar>
        <v-card-title v-else>{{
          $t('signup.tac.title') +
          ' - ' +
          formattedPublicationDate(tacToManage.publication_date)
        }}</v-card-title>
        <v-card-text class="pt-5">
          <v-card class="pa-md-6" elevation="0">
            <section class="pdf-item">
              <Markdown class="md-body" :source="tacToManage.body" />
            </section>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
::v-deep .v-data-table-header__content {
  font-weight: bold;
}
.flex {
  display: flex;
}
</style>
