import 'vuetify/styles'
import { createVuetify as _createVuetify } from 'vuetify'
import { fr } from 'vuetify/locale'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

export const createVuetify = () => {
  return _createVuetify({
    components,
    directives,
    locale: {
      locale: 'fr',
      messages: { fr }
    },
    theme: {
      defaultTheme: 'light',
      options: { customProperties: true },
      themes: {
        light: {
          colors: {
            primary: '#EB5C43',
            grey: '#ADADAD',
            'grey-lighten-2': '#DADADA',
            'grey-lighten-1': '#BCBCBC',
            'grey-darken-1': '#AFAFAF',
            'grey-darken-2': '#717171',
            'grey-darken-3': '#3E3E3E',
            'grey-darken-4': '#242424',
            yellow: '#F9EDC5',
            orange: '#FFDAB9',
            red: '#FDDBDC',
            green: '#E2F2C5',
            background: '#F6F6F6',
            'color-1': '#A6A435',
            'color-2': '#C6C417',
            'color-3': '#EBDB58',
            'color-4': '#F1B604',
            'color-5': '#FFA800',
            'color-6': '#F9B666',
            'color-7': '#ED6E4D',
            success: '#C6C417',
            pending: '#42a5f5',
            error: '#ED6E4D'
          }
        }
      }
    }
  })
}
