<script setup>
// #region Imports
import { useRouter } from 'vue-router'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Methods
const checkRoute = (name) => {
  router.push(name)
}
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0">
    <v-container class="content fill-height">
      <v-row justify="center" class="ma-1">
        <v-col cols="12" sm="12" align="center">
          <v-container>
            <v-img
              src="@/assets/img/Joana_Horizontal_v1.png"
              style="display: block; margin-left: auto; margin-right: auto"
            >
            </v-img>
          </v-container>
        </v-col>
        <v-col cols="12" align="center">
          <v-icon dark color="success" class="mr-2"> mdi-check-circle </v-icon>
          <span class="txt-small txt-gray">{{
            $t('resetEmail.emailSent')
          }}</span>
        </v-col>
        <v-col cols="12" align="center">
          <span
            class="link-lowercase txt-small txt-primary"
            @click="checkRoute({ name: 'Login' })"
          >
            {{ $t('resetEmail.goBack') }}
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped lang="scss">
.content {
  max-width: 500px !important;
}

.main-title {
  font-size: 40px;
  line-height: 41px;
  letter-spacing: -0.02em;
}

.txt-gray {
  color: #707070 !important;
}

.txt-small {
  font-size: 14px !important;
}

.txt-primary {
  color: #eb5c43 !important;
}
</style>
