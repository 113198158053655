<script setup>
// #region Imports
import { reactive, computed, defineProps, defineEmits, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import adminService from '@/services/adminService'
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Props
const props = defineProps(['tagToEdit'])
// #endregion

// #region Emits
const emit = defineEmits(['close'])
// #endregion

// #region Form Data
const formState = reactive({
  tag: {},
  tagFile: null
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    tag: {
      picture_alternative_text: { required },
      name: { required }
    },
    tagFile: { required }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Data
const dialog = ref(true)
// #endregion

// #region Computed
const tagFileErrors = computed(() => {
  if (v$.value.tagFile.$dirty && v$.value.tagFile.required.$invalid) {
    return t('message.error.required')
  }
  return []
})
const alternativeTextErrors = computed(() => {
  if (
    v$.value.tag.picture_alternative_text.$dirty &&
    v$.value.tag.picture_alternative_text.required.$invalid
  ) {
    return t('message.error.required')
  }
  return []
})
const nameErrors = computed(() => {
  if (v$.value.tag.name.$dirty && v$.value.tag.name.required.$invalid) {
    return t('message.error.required')
  }
  return []
})
// #endregion

// #region Methods
const saveTag = () => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    const formData = new FormData()
    formData.append('picture_uri', formState.tagFile[0])
    formData.append(
      'picture_alternative_text',
      formState.tag.picture_alternative_text
    )
    formData.append('name', formState.tag.name)
    if (formState.tag.id) {
      adminService.updateTag(formState.tag.id, formData).then(() => {
        emit('close')
      })
    } else {
      adminService.createTag(formData).then(() => {
        emit('close')
      })
    }
  }
}
// #endregion

// #region Created
formState.tag = props.tagToEdit
// #endregion
</script>

<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2 bg-grey-title">
        Editer tag
      </v-card-title>
      <v-form @submit.prevent="saveTag">
        <v-card-text>
          <v-row>
            <v-col cols="12" align="center">
              <v-text-field
                v-model="formState.tag.name"
                :error-messages="nameErrors"
                @input="v$.tag.name.$touch()"
                @blur="v$.tag.name.$touch()"
                hide-details
                variant="outlined"
                color="primary"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Nom pour la recherche
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" align="center">
              <v-text-field
                v-model="formState.tag.picture_alternative_text"
                :error-messages="alternativeTextErrors"
                @input="v$.tag.picture_alternative_text.$touch()"
                @blur="v$.tag.picture_alternative_text.$touch()"
                hide-details
                variant="outlined"
                color="primary"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Texte alternatif
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" align="center">
              <v-file-input
                v-model="formState.tagFile"
                :error-messages="tagFileErrors"
                @input="v$.tagFile.$touch()"
                @blur="v$.tagFile.$touch()"
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                accept="image/*"
                hide-details
                variant="outlined"
                color="primary"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    Photo
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="emit('close')"> Fermer </v-btn>
          <v-btn
            aria-label="Valider"
            color="primary"
            type="submit"
            text
            :disabled="v$.$invalid"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.bg-grey-title {
  background-color: #e0e0e0;
}
</style>
