<script setup>
// #region Imports
import { defineProps, defineEmits, ref } from 'vue'

import { STRIPE_STATUS } from '@/plugins/stripePlans'
import adminService from '@/services/adminService'
// #endregion

// #region Props
const props = defineProps(['profileToEdit'])
// #endregion

// #region Emits
const emit = defineEmits(['close'])
// #endregion

// #region Data
// const toastuiEditor = ref(null)

const dialog = ref(true)
const profile = ref({})
const stripeStatus = ref(STRIPE_STATUS)
// #endregion

// #region Methods
const saveProfile = () => {
  if (profile.value.stripe_customer_id === '') {
    profile.value.stripe_customer_id = null
  }

  if (profile.value.stripe_subscription_id === '') {
    profile.value.stripe_subscription_id = null
  }

  adminService.updateProfile(profile.value).then(() => {
    emit('close')
  })
}
// #endregion

// #region Created
profile.value = props.profileToEdit
// #endregion
</script>

<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2 bg-grey-title">
        Editer les informations Stripe
      </v-card-title>
      <v-form @submit.prevent="saveProfile">
        <v-card-text>
          <v-row>
            <v-col cols="6" align="center">
              <v-text-field
                v-model="profile.stripe_customer_id"
                label="Stripe customer id"
                hide-details
                variant="outlined"
                rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" align="center">
              <v-text-field
                v-model="profile.stripe_subscription_id"
                label="Stripe subscription id"
                hide-details
                variant="outlined"
                rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" align="center">
              <v-select
                v-model="profile.stripe_subscription_status"
                :items="stripeStatus"
                item-value="id"
                label="Status"
                hide-details
                variant="outlined"
                rounded
              >
                <template v-slot:selection="{ item }">
                  <v-icon :color="item.raw.color" class="pr-2" small>{{
                    item.raw.icon
                  }}</v-icon>
                  <span>{{ item.raw.id + ' - ' + item.raw.desc }}</span>
                </template>
                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props">
                    <template v-slot:prepend>
                      <v-list-item-avatar>
                        <v-icon :color="item.raw.color" class="pr-2" small>{{
                          item.raw.icon
                        }}</v-icon>
                      </v-list-item-avatar>
                    </template>
                    <template v-slot:title>
                      <v-list-item-title>
                        <span>{{
                          item.raw.id + ' - ' + item.raw.desc
                        }}</span></v-list-item-title
                      >
                    </template>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="emit('close')"> Fermer </v-btn>
          <v-btn aria-label="Valider" color="primary" type="submit" text>
            Valider
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.bg-grey-title {
  background-color: #e0e0e0;
}
</style>
