<template>
  <v-container class="wrapper-container pa-0" fluid>
    <v-container v-if="isInit" class="pa-0 pa-md-8" fluid>
      <NavigationZone />
      <v-container class="pa-4 pa-md-4" fluid>
        <h1>{{ $t('advices.title') }}</h1>
      </v-container>
      <v-container class="pa-4 pa-md-4 py-6" fluid>
        <v-container
          v-for="(advice, i) in advices"
          :key="i"
          class="pa-0 mb-8"
          fluid
        >
          <h2 class="mb-4 pl-1" align="left">{{ advice.title }}</h2>
          <v-expansion-panels>
            <v-expansion-panel v-for="(advice, i) in advice.adv" :key="i">
              <v-expansion-panel-header>
                <h3>{{ advice.title }}</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <markdown-it-vue class="md-body" :content="advice.text" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-container>
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<script>
import mealPlanService from '@/services/mealPlanService'
import InitLoader from '@/components/initLoader/InitLoader'
import NavigationZone from '@/components/navigationZone/NavigationZone'

export default {
  name: 'AdvicesBase',
  components: {
    InitLoader,
    NavigationZone
  },
  data: () => ({
    isInit: false,
    advices: []
  }),
  created() {
    mealPlanService.getAdvices().then((response) => {
      this.advices = response
      this.isInit = true
    })
  }
}
</script>

<style scoped>
::v-deep .markdown-body > h3 {
  padding-bottom: 10px;
}
</style>
