<script setup>
// #region Imports
import { reactive, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import _ from 'lodash'

import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import authService from '@/services/authService'
// #endregion

// #region Router
const route = useRoute()
const router = useRouter()
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Form Data
const formState = reactive({
  email: ''
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    email: { required, email }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Computed
const emailErrors = computed(() => {
  if (v$.value.email?.$dirty) {
    if (!v$.value.email.required) return t('message.error.required')
    else if (!v$.value.email.email) return t('message.error.email')
  }
  return []
})
// #endregion

// #region Methods
const save = _.debounce(() => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    localStorage.removeItem('tokens')
    const credentials = {
      uid: route.params.uuid,
      token: route.params.token,
      new_email: formState.email
    }
    authService
      .resetEmailConfirmation(credentials)
      .then(() => {
        checkRoute({
          name: 'Login',
          params: {
            messages: [{ type: 'success', text: t('resetEmail.success') }]
          }
        })
      })
      .catch(() => {
        checkRoute({
          name: 'Login',
          params: {
            messages: [{ type: 'error', text: t('resetEmail.error') }]
          }
        })
      })
  }
}, 1000)
const checkRoute = (name) => {
  router.push(name)
}
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0">
    <v-container class="content fill-height">
      <v-row justify="center" class="ma-1">
        <v-col cols="12" sm="12" align="center">
          <v-container>
            <v-img
              src="@/assets/img/Joana_Horizontal_v1.png"
              style="display: block; margin-left: auto; margin-right: auto"
            >
            </v-img>
          </v-container>
        </v-col>
        <v-col align="center" cols="12">
          <h2>{{ $t('resetEmail.title') }}</h2>
        </v-col>
        <v-col align="center" cols="12">
          <v-text-field
            v-model="formState.email"
            :error-messages="emailErrors"
            outlined
            rounded
            type="email"
            @blur="v$.email.$touch()"
            @input="v$.email.$touch()"
          >
            <template v-slot:label>
              <span class="checkbox-label">
                {{ $t('resetEmail.newEmail') }}
                <span class="mandatory">*</span>
              </span>
            </template>
          </v-text-field>
        </v-col>
        <v-col align="center" cols="12" sm="12">
          <v-btn
            :disabled="v$.$invalid"
            class="confirm-button"
            color="primary"
            @click="save"
          >
            {{ $t('resetEmail.button') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped lang="scss">
.content {
  max-width: 500px !important;
}
.main-title {
  font-size: 40px;
  line-height: 41px;
  letter-spacing: -0.02em;
}
.v-input {
  width: 300px;
}
.confirm-button {
  width: 300px;
  height: 50px !important;
  border-radius: 15px;
}
</style>
