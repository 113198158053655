<script setup>
/* eslint-disable no-unused-vars */
// #region Imports
import { computed, defineProps, defineEmits, ref, watch } from 'vue'

import _ from 'lodash'
import moment from 'moment'

import adminService from '@/services/adminService'
// #endregion

// #region Props
const props = defineProps(['profileToEdit'])
// #endregion

// #region Emits
const emit = defineEmits(['close'])
// #endregion

// #region Data
const dialog = ref(true)

const draftProfile = ref({})
const mealPlanToAdd = ref({})
const mealPlansForMonth = ref([])
const mealPlansAddableForMonth = ref([])
const errorMessages = ref([])
const menu = ref(false)
const menuValue = ref(false)
const month = ref(new Date())
// #endregion

// #region Computed
const displayMonth = computed(() => {
  return formatMonth(month.value)
})
const profileMealPlans = computed(() => {
  return draftProfile.value.meal_plans
})
const sortedMealPlans = computed(() => {
  return _.orderBy(draftProfile.value.meal_plans, 'beginning_date')
})
// #endregion

// #region Methods
const changeDate = (date) => {
  menuValue.value = false
}
const addMealPlanToDraftProfile = () => {
  const x = _.findIndex(draftProfile.value.meal_plans, (o) => {
    return o.uuid === mealPlanToAdd.value.uuid
  })
  if (x === -1) {
    draftProfile.value.meal_plans.push(mealPlanToAdd.value)
    mealPlanToAdd.value = {}
    changeMealPlanAddableForMonth()
  }
}
const deleteMealPlanFromDraftProfile = (mealPlan) => {
  const index = draftProfile.value.meal_plans.indexOf(mealPlan)
  if (index > -1) {
    draftProfile.value.meal_plans.splice(index, 1)
  }
  changeMealPlanAddableForMonth()
}
const updateProfile = () => {
  const payload = {
    id: draftProfile.value.id,
    meal_plans: []
  }
  draftProfile.value.meal_plans.forEach((mealPlan) => {
    payload.meal_plans.push(mealPlan.id)
  })
  adminService.updateProfileMealPlans(payload)
  emit('close')
}
const changeMealPlanAddableForMonth = () => {
  const mealPlans = draftProfile.value.meal_plans
  mealPlansAddableForMonth.value = mealPlansForMonth.value.filter((obj) => {
    return !mealPlans.some((obj2) => {
      return obj.id === obj2.id
    })
  })
}
const searchMealPlanForMonth = (date) => {
  adminService
    .getMealPlans(null, moment(date).format('YYYY-MM'))
    .then((response) => {
      mealPlansForMonth.value = response.data.results
      changeMealPlanAddableForMonth()
    })
}
const formatDate = (date) => {
  return moment(date).format('DD/MM/YYYY')
}
const formatMonth = (date) => {
  return moment(date).format('MMMM YYYY')
}
// #endregion

// #region Watch
watch(profileMealPlans, () => {
  const x = _.findIndex(draftProfile.value.meal_plans, (o) => {
    return o.uuid === mealPlanToAdd.value.uuid
  })
  if (x === -1) {
    errorMessages.value = []
  } else {
    errorMessages.value = ['Ce menu est déjà attribué']
  }
})

watch(mealPlanToAdd, (val) => {
  const x = _.findIndex(draftProfile.value.meal_plans, (o) => {
    return o.uuid === mealPlanToAdd.value.uuid
  })
  if (x === -1) {
    errorMessages.value = []
  } else {
    errorMessages.value = ['Ce menu est déjà attribué']
  }
})

watch(month, (val) => {
  searchMealPlanForMonth(val)
  errorMessages.value = []
})
// #endregion

// #region Created

draftProfile.value = props.profileToEdit
searchMealPlanForMonth(new Date().toISOString().substr(0, 7))
// #endregion
</script>

<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2 bg-grey-title">
        Editer menus
      </v-card-title>
      <v-form @submit.prevent="updateProfile">
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-menu
                ref="menu"
                v-model="menuValue"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290"
                min-width="290"
              >
                <template v-slot:activator="{ props: menu }">
                  <v-text-field
                    v-bind="menu"
                    v-model="displayMonth"
                    label="Mois"
                    prepend-icon="mdi-calendar"
                    variant="outlined"
                    rounded
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  color="primary"
                  v-model="month"
                  type="month"
                  no-title
                  scrollable
                  @update:model-value="changeDate"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="mealPlanToAdd"
                :items="mealPlansAddableForMonth"
                variant="outlined"
                rounded
                color="primary"
                label="Date de début"
                :error-messages="errorMessages"
              >
                <template v-slot:selection="{ item }">
                  {{ formatDate(item.value.beginning_date) }}
                </template>
                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props">
                    <template v-slot:title>
                      <v-list-item-title>
                        <!-- {{ item.value.diet }} -->
                        <div class="diet-wrapper">
                          <span :class="[item.value.diet === 'vegetarian' ? 'vegetarian' : 'flexitarian']"></span>
                          <span>{{
    formatDate(item.value.beginning_date)
  }}</span>
                        </div>
                      </v-list-item-title>
                    </template>
                  </v-list-item>
                </template>
                <template v-slot:append>
                  <v-btn
                    icon
                    color="primary"
                    variant="text"
                    style="opacity: 75%"
                    @click="addMealPlanToDraftProfile"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-chip
                v-for="meal_plan in sortedMealPlans"
                :key="meal_plan.uuid"
                class="ma-2"
                label
                closable
                @click:close="deleteMealPlanFromDraftProfile(meal_plan)"
              >
                {{ formatDate(meal_plan.beginning_date) }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="emit('close')"> Fermer </v-btn>
          <v-btn aria-label="Valider" color="primary" type="submit" text>
            Valider
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.bg-grey-title {
  background-color: #e0e0e0;
}

.diet-wrapper {
  display: flex;
  align-items: center;
}

.vegetarian {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #41553d;
  margin-right: 10px;
}

.flexitarian {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #fec46d;
  margin-right: 10px;

}
</style>
