<script setup>
// #region Imports
import { reactive, computed, defineProps, defineEmits, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import moment from 'moment'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import adminService from '@/services/adminService'
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Props
const props = defineProps(['mealPlanToEdit'])
// #endregion

// #region Emits
const emit = defineEmits(['close'])
// #endregion

// #region Form Data
const formState = reactive({
  mealPlan: {}
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    mealPlan: {
      beginning_date: { required },
      release_date: { required }
    }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Data
const dialog = ref(true)

const mealPlanFile = ref(null)
const pickerBeginningDate = ref(false)
const pickerReleaseDate = ref(false)
// #endregion

// #region Computed
const formattedBeginningDate = computed(() => {
  return moment(formState.mealPlan.beginning_date).format('DD/MM/YYYY')
})
const formattedReleaseDate = computed(() => {
  return moment(formState.mealPlan.release_date).format('DD/MM/YYYY')
})
const beginningDateErrors = computed(() => {
  if (
    v$.value.mealPlan.release_date.$dirty &&
    v$.value.mealPlan.release_date.required.$invalid
  ) {
    return t('message.error.required')
  }
  return []
})
// const formattedData = computed(() => {
//   const formdata = new FormData()
//   formdata.append(
//     'beginning_date',
//     moment(formState.mealPlan.beginning_date).format()
//   )
//   formdata.append(
//     'release_date',
//     moment(formState.mealPlan.release_date).hour(0).format()
//   )
//   if (mealPlanFile.value) {
//     formdata.append('pdf', mealPlanFile.value)
//   } else {
//     formdata.append('pdf', '')
//   }
//   formdata.append('diet', formState.mealPlan.diet)
//   return formdata
// })
// #endregion

// #region Methods
const saveMealPlan = () => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    if (formState.mealPlan.id) {
      const formdata = new FormData()
      formdata.append(
        'beginning_date',
        moment(formState.mealPlan.beginning_date).format()
      )
      formdata.append(
        'release_date',
        moment(formState.mealPlan.release_date).hour(0).format()
      )
      if (mealPlanFile.value) {
        formdata.append('pdf', mealPlanFile.value[0])
      } else {
        formdata.append('pdf', '')
      }
      formdata.append('diet', formState.mealPlan.diet)
      adminService.updateMealPlan(formState.mealPlan, formdata).then(() => {
        emit('close')
      })
    } else {
      const formdata = new FormData()
      formdata.append(
        'beginning_date',
        moment(formState.mealPlan.beginning_date).format()
      )
      formdata.append(
        'release_date',
        moment(formState.mealPlan.release_date).hour(0).format()
      )
      if (mealPlanFile.value) {
        formdata.append('pdf', mealPlanFile.value[0])
      } else {
        formdata.append('pdf', '')
      }
      formdata.append('diet', formState.mealPlan.diet)
      adminService.createMealPlan(formdata).then(() => {
        emit('close')
      })
    }
  }
}
// #endregion

// #region Created
formState.mealPlan = props.mealPlanToEdit
if (props.mealPlanToEdit.id) {
  formState.mealPlan.beginning_date = new Date(
    props.mealPlanToEdit.beginning_date
  )
  formState.mealPlan.release_date = new Date(props.mealPlanToEdit.release_date)
}
// #endregion
</script>

<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2 bg-grey-title">
        Editer programme
      </v-card-title>
      <v-form @submit.prevent="saveMealPlan">
        <v-card-text>
          <v-row>
            <v-col cols="6" align="center">
              <v-menu
                ref="menuBeginningDate"
                v-model="pickerBeginningDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ props: menu }">
                  <v-text-field
                    v-bind="menu"
                    v-model="formattedBeginningDate"
                    :error-messages="beginningDateErrors"
                    hide-details
                    readonly
                    variant="outlined"
                    rounded
                    v-on="on"
                  >
                    <template v-slot:label>
                      <span class="checkbox-label">
                        Date de début
                        <span class="mandatory">*</span>
                      </span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  view-mode="months"
                  v-model="formState.mealPlan.beginning_date"
                  @input="v$.mealPlan.beginning_date.$touch()"
                  @blur="v$.mealPlan.beginning_date.$touch()"
                  locale="fr"
                  :first-day-of-week="1"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" align="center">
              <v-menu
                ref="menuReleaseDate"
                v-model="pickerReleaseDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ props: menu }">
                  <v-text-field
                    v-bind="menu"
                    v-model="formattedReleaseDate"
                    :error-messages="releaseDateErrors"
                    hide-details
                    readonly
                    variant="outlined"
                    rounded
                    v-on="on"
                  >
                    <template v-slot:label>
                      <span class="checkbox-label">
                        Date de sortie
                        <span class="mandatory">*</span>
                      </span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  view-mode="months"
                  color="primary"
                  v-model="formState.mealPlan.release_date"
                  @input="v$.mealPlan.release_date.$touch()"
                  @blur="v$.mealPlan.release_date.$touch()"
                  locale="fr"
                  :first-day-of-week="1"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <strong>Type de menu</strong>

              <v-radio-group
                density="compact"
                color="primary"
                direction="horizontal"
                v-model="formState.mealPlan.diet"
              >
                <v-radio label="Végétarien" value="vegetarian"></v-radio>
                <v-radio label="Flexitarien" value="flexitarian"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" align="center">
              <v-file-input
                v-model="mealPlanFile"
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                accept="image/*"
                hide-details
                variant="outlined"
                rounded
              >
                <template v-slot:label>
                  <span class="checkbox-label">
                    document
                    <span class="mandatory">*</span>
                  </span>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('close')"> Fermer </v-btn>
          <v-btn
            aria-label="Valider"
            color="primary"
            type="submit"
            text
            :disabled="v$.$invalid"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.bg-grey-title {
  background-color: #e0e0e0;
}
</style>
