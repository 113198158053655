<script setup>
// #region Imports
import { defineProps, defineEmits, ref, watch } from 'vue'

import adminService from '@/services/adminService'
// #endregion

// #region Props
const props = defineProps(['weekdayToEdit'])
// #endregion

// #region Emits
const emit = defineEmits(['close'])
// #endregion

// #region Data
const dialog = ref(true)

const weekday = ref([])
const dishesLoaded = ref([])
const isLoadingDishes = ref(false)
const searchDishes = ref('')
const mealCategoriesLoaded = ref([])
const isLoadingMealCategories = ref(false)
const searchMealCategories = ref('')
const dishToAdd = ref({})
const loadingSave = ref(false)

// #endregion

// #region Methods
const addDish = () => {
  if (dishToAdd.value.category && dishToAdd.value.dish) {
    const meal = weekday.value.meals.find(
      (x) => x.category.name === dishToAdd.value.category.name
    )
    if (meal) {
      if (
        !meal.meal.dishes.find((x) => x.dish.name === dishToAdd.value.dish.name)
      ) {
        const val = {
          order: meal.meal.dishes.length,
          dish: dishToAdd.value.dish
        }
        weekday.value.meals
          .find((x) => x.category.name === dishToAdd.value.category.name)
          .meal.dishes.push(val)
        dishToAdd.value = {}
      }
    } else {
      const val = {
        order: weekday.value.meals.length + 1,
        category: dishToAdd.value.category,
        meal: {
          dishes: [{ order: 1, dish: dishToAdd.value.dish }]
        }
      }
      weekday.value.meals.push(val)
      dishToAdd.value = {}
    }
  }
}
const deleteDish = (mealOrder, dishOrder) => {
  weekday.value.meals.find((x) => x.order === mealOrder).meal.dishes =
    weekday.value.meals
      .find((x) => x.order === mealOrder)
      .meal.dishes.filter((x) => x.order !== dishOrder)
  if (
    weekday.value.meals.find((x) => x.order === mealOrder).meal.dishes
      .length === 0
  ) {
    weekday.value.meals = weekday.value.meals.filter(
      (x) => x.order !== mealOrder
    )
  }
  resetOrder()
}
const resetOrder = () => {
  let mealIndex = 1
  for (const meal of weekday.value.meals) {
    let dishIndex = 1
    for (const dish of meal.meal.dishes) {
      dish.order = dishIndex
      dishIndex++
    }
    meal.order = mealIndex
    mealIndex++
  }
}
const saveWeekday = async () => {
  loadingSave.value = true
  resetOrder()
  const mealSaved = {
    uuid: weekday.value.uuid,
    order: weekday.value.order,
    meal_plan: weekday.value.meal_plan,
    meals: []
  }
  for (const meal of weekday.value.meals) {
    await saveMeal(meal.meal).then((response) => {
      mealSaved.meals.push({
        meal: response.data.id,
        order: meal.order,
        category: meal.category.id
      })
    })
  }
  adminService
    .updateWeekday(mealSaved)
    .then(() => {
      emit('close')
    })
    .finally(() => {
      loadingSave.value = false
    })
}
const saveMeal = async (meal) => {
  const dishes = []
  for (const dish of meal.dishes) {
    dishes.push({ dish: dish.dish.id, order: dish.order })
  }
  if (meal.id) {
    return adminService
      .updateMeal({
        id: meal.id,
        dishes: dishes
      })
      .then((response) => {
        return response
      })
  } else {
    return adminService.createMeal({ dishes: dishes }).then((response) => {
      return response
    })
  }
}

// #endregion

// #region Watch
watch(
  searchMealCategories,
  () => {
    isLoadingMealCategories.value = true
    adminService
      .getMealCategories(null)
      .then((response) => {
        mealCategoriesLoaded.value = response.data.results
      })
      .finally(() => (isLoadingMealCategories.value = false))
  },
  500
)
watch(
  searchDishes,
  () => {
    isLoadingDishes.value = true
    adminService
      .getDishes(null, searchDishes.value)
      .then((response) => {
        dishesLoaded.value = response.data.results
      })
      .finally(() => (isLoadingDishes.value = false))
  },
  500
)

// #endregion

// #region Created
weekday.value = props.weekdayToEdit
isLoadingDishes.value = true
adminService
  .getDishes(null)
  .then((response) => {
    dishesLoaded.value = response.data.results
  })
  .finally(() => (isLoadingDishes.value = false))
isLoadingMealCategories.value = true
adminService
  .getMealCategories(null)
  .then((response) => {
    mealCategoriesLoaded.value = response.data.results
  })
  .finally(() => (isLoadingMealCategories.value = false))

// #endregion
</script>

<template>
  <v-dialog v-model="dialog" width="700" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2 bg-grey-title">
        Editer jour de la semaine
      </v-card-title>
      <v-form @submit.prevent="saveWeekday">
        <v-card-text>
          <v-row>
            <v-col sm="6">
              <h4 class="mt-5 mb-3 ml-4">Ajouter une recette</h4>
              <v-row class="admin-divider-vertical-right pr-4">
                <v-col cols="12" align="center">
                  <v-autocomplete
                    v-model="dishToAdd.dish"
                    :items="dishesLoaded"
                    item-title="name"
                    v-model:search="searchDishes"
                    return-object
                    hide-details
                    variant="outlined"
                    rounded
                  >
                    <template v-slot:label>
                      <span class="checkbox-label">
                        Recette
                        <span class="mandatory">*</span>
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="dishToAdd.category"
                    :items="mealCategoriesLoaded"
                    item-title="name"
                    v-model:search="searchMealCategories"
                    return-object
                    hide-details
                    variant="outlined"
                    rounded
                  >
                    <template v-slot:label>
                      <span class="checkbox-label">
                        Repas
                        <span class="mandatory">*</span>
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" align="center">
                  <v-btn variant="text" text color="primary" @click="addDish()"
                    >Ajouter
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="6" class="pl-4">
              <h4 class="mt-5 mb-3 ml-4">Aperçu du menu</h4>
              <v-row>
                <v-col cols="12">
                  <v-container
                    v-for="meal in weekday.meals"
                    :key="meal.category.name"
                    class="pa-0 pb-3"
                    flat
                  >
                    <div class="flex">
                      <h4 class="ml-4">{{ meal.category.name }}</h4>
                    </div>

                    <v-list class="pa-0" dense>
                      <v-list-item
                        v-for="dish in meal.meal.dishes"
                        :key="meal.category.name + dish.dish.name"
                        class="admin-expanded-list-dense"
                      >
                        <template v-slot:title>
                          <div class="flex">
                            <div>
                              <v-list-item-content class="pa-0 max-w">{{
                                dish.dish.name
                              }}</v-list-item-content>
                            </div>
                            <div>
                              <v-list-item-action-text>
                                <v-btn
                                  class="ma-0"
                                  style="opacity: 75%"
                                  icon
                                  variant="text"
                                  color="primary"
                                  @click="deleteDish(meal.order, dish.order)"
                                >
                                  <v-icon small class="pb-1">
                                    mdi-minus
                                  </v-icon>
                                </v-btn>
                              </v-list-item-action-text>
                            </div>
                          </div>
                        </template>
                      </v-list-item>
                    </v-list>
                  </v-container>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="emit('close')"> Fermer </v-btn>
          <v-btn
            aria-label="Valider"
            color="primary"
            type="submit"
            text
            :disabled="loadingSave"
          >
            <v-progress-circular
              v-if="loadingSave"
              indeterminate
              color="primary"
              :size="20"
              :width="2"
            ></v-progress-circular>
            <div v-else>Valider</div>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
  height: auto;
  overflow: hidden;
}
.max-w {
  width: 200px !important;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bg-grey-title {
  background-color: #e0e0e0;
}
::v-deep .v-list {
  overflow: hidden;
}
</style>
