<script setup>
// #region Imports
import { reactive, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import _ from 'lodash'

import { useVuelidate } from '@vuelidate/core'
import { required, minLength, sameAs } from '@vuelidate/validators'

import authService from '@/services/authService'
// #endregion

// #region Router
const route = useRoute()
const router = useRouter()
// #endregion

// #region i18n
const { t } = useI18n({ useScope: 'global' })
// #endregion

// #region Form Data
const formState = reactive({
  password: '',
  passwordConfirmation: ''
})
// #endregion

// #region Form Data Validation Rules
const rules = computed(() => {
  return {
    password: {
      required,
      minLength: minLength(9),
      containsUppercase: (value) => {
        return /[A-Z]/.test(value)
      },
      containsLowercase: (value) => {
        return /[a-z]/.test(value)
      },
      containsNumber: (value) => {
        return /[0-9]/.test(value)
      },
      containsSpecial: (value) => {
        return /[#?!@$%^&*-]/.test(value)
      }
    },
    passwordConfirmation: {
      required,
      sameAs: sameAs(computed(() => formState.password))
    }
  }
})
// #endregion

// #region Vuelidate
const v$ = useVuelidate(rules, formState)
// #endregion

// #region Computed
const passwordErrors = computed(() => {
  if (v$.value.password.$dirty) {
    if (v$.value.password.required.$invalid) {
      return t('message.error.required')
    } else if (v$.value.password.containsUppercase.$invalid) {
      return t('message.error.containsUppercase')
    } else if (v$.value.password.containsLowercase.$invalid) {
      return t('message.error.containsLowercase')
    } else if (v$.value.password.containsNumber.$invalid) {
      return t('message.error.containsNumber')
    } else if (v$.value.password.containsSpecial.$invalid) {
      return t('message.error.containsSpecial')
    } else if (v$.value.password.minLength.$invalid) {
      return t('message.error.minLength')
    }
  }
  return []
})
const passwordConfirmationErrors = computed(() => {
  if (v$.value.passwordConfirmation?.$dirty) {
    if (v$.value.passwordConfirmation.required.$invalid) {
      return t('message.error.required')
    } else if (v$.value.passwordConfirmation.sameAs.$invalid) {
      return t('message.error.sameAs')
    }
  }
  return []
})
// #endregion

// #region Methods
const save = _.debounce(() => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    localStorage.removeItem('tokens')
    const credentials = {
      uid: route.params.uuid,
      token: route.params.token,
      new_password: formState.password
    }
    authService
      .resetPasswordConfirmation(credentials)
      .then(() => {
        checkRoute({
          name: 'Login',
          params: {
            messages: [{ type: 'success', text: t('resetPassword.success') }]
          }
        })
      })
      .catch(() => {
        checkRoute({
          name: 'Login',
          params: {
            messages: [{ type: 'error', text: t('resetPassword.error') }]
          }
        })
      })
  }
}, 1000)
const checkRoute = (name) => {
  router.push(name)
}
// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0">
    <v-container class="content fill-height">
      <v-row justify="center" class="ma-1">
        <v-col cols="12" sm="12" align="center">
          <v-container>
            <v-img
              src="@/assets/img/Joana_Horizontal_v1.png"
              style="display: block; margin-left: auto; margin-right: auto"
            >
            </v-img>
          </v-container>
        </v-col>
        <v-col align="center" cols="12">
          <h2>{{ $t('resetPassword.title') }}</h2>
        </v-col>
        <v-col align="center" cols="12">
          <v-text-field
            v-model="formState.password"
            :error-messages="passwordErrors"
            variant="outlined"
            rounded
            type="password"
            @blur="v$.password.$touch()"
            @input="v$.password.$touch()"
          >
            <template v-slot:label>
              <span class="checkbox-label">
                {{ $t('resetPassword.password') }}
                <span class="mandatory">*</span>
              </span>
            </template>
          </v-text-field>
        </v-col>
        <v-col align="center" class="pt-0" cols="12">
          <v-text-field
            v-model="formState.passwordConfirmation"
            :error-messages="passwordConfirmationErrors"
            variant="outlined"
            rounded
            type="password"
            @blur="v$.passwordConfirmation.$touch()"
            @input="v$.passwordConfirmation.$touch()"
          >
            <template v-slot:label>
              <span class="checkbox-label">
                {{ $t('resetPassword.passwordConfirmation') }}
                <span class="mandatory">*</span>
              </span>
            </template>
          </v-text-field>
        </v-col>
        <v-col align="center" cols="12" sm="12">
          <v-btn
            :disabled="v$.$invalid"
            class="confirm-button"
            color="primary"
            @click="save"
          >
            {{ $t('resetPassword.button') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped lang="scss">
.content {
  max-width: 500px !important;
}
::v-deep input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232300;
}
.main-title {
  font-size: 40px;
  line-height: 41px;
  letter-spacing: -0.02em;
}
.v-input {
  width: 300px;
}
.confirm-button {
  width: 300px;
  height: 50px !important;
  border-radius: 15px;
}
::v-deep .v-messages__message {
  text-align: left;
}
</style>
