<script setup>
// #region Imports
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'

import EditDishModal from '@/components/admin/dishes/EditDishModal'
import ConfirmDialog from '@/components/admin/miscellaneous/confirmDialog/ConfirmDialog'
import InitLoader from '@/components/initLoader/InitLoader'

import _ from 'lodash'

import Markdown from 'vue3-markdown-it'

import adminService from '@/services/adminService'
// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Data
const confirm = ref(null)

const isInit = ref(false)
const dishes = ref([])
const page = ref(1)
const totalItems = ref(0)
const itemsPerPage = ref(10)
const search = ref('')
const expanded = ref([])
const editDishModal = ref(false)
const dishToEdit = ref({})
// #endregion

// #region Computed
const pageCount = computed(() => {
  const totalPages = ~~(totalItems.value / itemsPerPage.value)
  const modulo = totalItems.value % itemsPerPage.value
  return modulo === 0 ? totalPages : totalPages + 1
})
const headers = computed(() => {
  return [
    { title: '', key: 'data-table-expand' },
    {
      title: 'Image',
      value: 'pictures',
      sortable: false,
      width: '70px',
      padding: '0',
      fixed: true
    },
    { title: 'Nom', value: 'name' },
    { title: 'Portions', value: 'portions' },
    { title: 'Cuisson', value: 'cooking_time' },
    { title: 'Préparation', value: 'preparation_time' },
    { title: 'Tags', value: 'tags' },
    { value: 'display', sortable: false, width: '20px', fixed: true },
    {
      title: 'Actions',
      value: 'actions',
      sortable: false,
      width: '200px',
      fixed: true
    }
  ]
})
// #endregion

// #region Methods
const editDish = (item) => {
  dishToEdit.value = item ? { ...item } : {}
  editDishModal.value = true
}
const deleteDish = (id) => {
  confirm.value
    .open(
      'Supprimer la recette',
      'Souhaitez-vous vraiment supprimer cette recette ?',
      { color: 'primary' }
    )
    .then((confirm) => {
      if (confirm) {
        adminService.deleteDish(id).then(() => {
          reload()
        })
      }
    })
}
const reload = _.debounce(() => {
  editDishModal.value = false
  adminService.getDishes(page.value, search.value).then((response) => {
    totalItems.value = response.data.count
    dishes.value = response.data.results
  })
}, 500)
const getPictureUrl = (pictures) => {
  let url = ''
  if (pictures.length > 0) {
    url = process.env.VUE_APP_API_URL + pictures[0].uri
  }

  return url
}
const getTagPictureUrl = (uri) => {
  return process.env.VUE_APP_API_URL + '/' + uri
}
const resolveDishPath = (dishId) => {
  const route = router.resolve({
    name: 'Dish',
    params: { dishId }
  })
  return route.href
}
// #endregion

// #region Watch
watch(page, () => {
  reload()
})
watch(search, () => {
  page.value = 1
  reload()
})
// #endregion

// #region Created
adminService
  .getDishes()
  .then((response) => {
    totalItems.value = response.data.count
    dishes.value = response.data.results
    isInit.value = true
  })
  .catch(() => {})
// #endregion
</script>

<template>
  <v-container class="admin-wrapper-container" fluid>
    <v-container v-if="isInit" class="pa-0" fluid>
      <h1 class="mb-3">Recettes</h1>
      <v-data-table
        :headers="headers"
        :items="dishes"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        class="py-4"
        hide-default-footer
        single-expand
        :expanded.sync="expanded"
        item-key="uuid"
        show-expand
        hover
        density="compact"
      >
        <template v-slot:bottom></template>
        <template v-slot:top>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="5" class="py-0" align="left">
              <h2 class="ml-5">Liste des recettes</h2>
            </v-col>
            <v-col cols="8" sm="6" class="py-0" align="right">
              <v-text-field
                v-model="search"
                variant="outlined"
                rounded
                color="primary"
                label="Rechercher"
                class="mx-4 admin-search-bar"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="1" class="py-0" align="center">
              <v-btn
                style="opacity: 75%"
                icon
                variant="text"
                color="primary"
                @click="editDish()"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.pictures`]="{ item }">
          <v-img
            class="admin-list-picture"
            :src="getPictureUrl(item.pictures)"
          ></v-img>
        </template>
        <template v-slot:[`item.cooking_time`]="{ item }">
          {{ item.cooking_time + ' min' }}
        </template>
        <template v-slot:[`item.preparation_time`]="{ item }">
          {{ item.preparation_time + ' min' }}
        </template>
        <template v-slot:[`item.tags`]="{ item }">
          <v-avatar
            v-for="tag in item.tags"
            :key="tag.id"
            color="red"
            size="25"
          >
            <v-img
              :width="30"
              :height="30"
              :src="getTagPictureUrl(tag.picture_uri)"
            ></v-img>
          </v-avatar>
        </template>
        <template v-slot:[`item.display`]="{ item }">
          <v-icon
            small
            style="opacity: 75%"
            icon
            variant="text"
            v-if="item.display"
          >
            mdi-account-check
          </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            style="opacity: 75%"
            icon
            variant="text"
            :href="resolveDishPath(item.uuid)"
            target="_blank"
          >
            <v-icon> mdi-eye </v-icon>
          </v-btn>

          <v-btn
            style="opacity: 75%"
            icon
            variant="text"
            @click="editDish(item)"
          >
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            style="opacity: 75%"
            icon
            variant="text"
            @click="deleteDish(item.uuid)"
          >
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-row="{ columns, item }">
          <td :colspan="columns.length">
            <v-row>
              <v-col sm="7" class="pr-5">
                <div class="pa-4">
                  <h3 class="mb-3">Instructions</h3>
                  <div class="pa-4">
                    <Markdown
                      v-if="item.instructions"
                      class="md-body"
                      :source="item.instructions"
                    />
                  </div>
                </div>
              </v-col>
              <v-col sm="5" class="pl-5">
                <div class="pa-4">
                  <h3 class="mb-3">Ingrédients</h3>
                  <v-list class="pa-0" dense>
                    <v-list-item
                      v-for="ingredient in item.ingredients"
                      :key="
                        ingredient.ingredient.name +
                        ingredient.quantity +
                        ingredient.unit_of_measure.name
                      "
                      class="pa-0 admin-expanded-list-dense"
                    >
                      <template v-slot:title>
                        <v-list-item-title class="pa-0 txt-small">
                          <div class="flex-between">
                            <span class="">
                              {{ ingredient.ingredient.name }}
                            </span>
                            <div class="">
                              <span
                                class="quantity"
                                v-if="ingredient.quantity"
                                >{{ ingredient.quantity }}</span
                              >
                              <span
                                v-if="ingredient.quantity"
                                class="unit_of_measure"
                                >{{
                                  '&nbsp;' + ingredient.unit_of_measure.name
                                }}</span
                              >
                            </div>
                          </div>
                        </v-list-item-title>
                        <v-list-item-title> </v-list-item-title>
                      </template>
                    </v-list-item>
                  </v-list>
                </div>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
      <div class="text-center pt-2 mb-8">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="10"
          active-color="primary"
          density="comfortable"
          variant="elevated"
          opacity="100"
        ></v-pagination>
      </div>
      <EditDishModal
        v-if="editDishModal"
        :dishToEdit="dishToEdit"
        @close="reload()"
      />
      <ConfirmDialog ref="confirm" />
    </v-container>
    <InitLoader v-if="!isInit" />
  </v-container>
</template>

<style scoped>
::v-deep .v-data-table-header__content {
  font-weight: bold;
}
::v-deep .v-img__img--contain {
  object-fit: cover !important;
}
.txt-small {
  font-size: 14px !important;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
