<script setup>
// #region Imports
import { onMounted } from 'vue'

import stripeService from '@/services/stripeService'
// #endregion

// #region Methods
const customerPortal = () => {
  stripeService.customerPortal().then((response) => {
    window.location.href = response.data.url
  })
}
// #endregion

// #region Mounted
onMounted(async () => {
  customerPortal()
})
// #endregion
</script>

<template>
  <div />
</template>
