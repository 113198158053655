import { createApp } from 'vue'
import { createRouter } from './router'
import { createStore } from './store'
import { createVuetify } from './plugins/vuetify'
import { createI18n } from './i18n'
import { createMetaManager } from 'vue-meta'

import App from './App.vue'

// import * as Sentry from '@sentry/browser'
// import { Integrations } from '@sentry/tracing'

import moment from 'moment'

import axios from 'axios'
import authService from '@/services/authService'
// import { vueRouterInstrumentation } from '@sentry/vue'

// Sentry.init({
//   Vue,
//   dsn: 'https://e0f91f7020764f8d8be0a3a563f52585@o4504091359379456.ingest.sentry.io/4504091372945408',
//   autoSessionTracking: true,
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: vueRouterInstrumentation(router),
//       tracingOrigins: [
//         'localhost',
//         'app.joanaetvous.com',
//         'integration.app.joanaetvous.com',
//         'app.joanaetvous.dev',
//         /^\//
//       ]
//     })
//   ],
//   tracesSampleRate: 0.1
// })

axios.interceptors.response.use(null, (error) => {
  if (
    error.config &&
    error.response?.status === 401 &&
    error.config.url !== authService.createTokenUrl() &&
    error.config.url !== authService.refreshTokensUrl()
  ) {
    return authService
      .checkTokens()
      .then(() => {
        error.config.headers.Authorization =
          'Bearer ' + JSON.parse(localStorage.getItem('tokens')).access
        error.config.baseURL = undefined
        return axios.request(error.config)
      })
      .catch(() => {
        router
          .push({ name: 'Login', params: { redirect: error.config.url } })
          .catch((err) => {
            if (err.name !== 'NavigationDuplicated') {
              // console.error(err)
            }
          })
      })
  } else if (
    error.config &&
    error.response?.status === 403 &&
    error.config.url.startsWith(
      process.env.VUE_APP_API_URL + '/administration/'
    )
  ) {
    router.push({ name: 'Login' })
  }
  return Promise.reject(error)
})

const store = createStore()
const metaManager = createMetaManager()
const router = createRouter()
const vuetify = createVuetify()
const i18n = createI18n()

const app = createApp({ ...App })

moment.locale('fr')
app.config.globalProperties.$moment = moment

app.use(metaManager)
app.use(vuetify)
app.use(router)
app.use(store)
app.use(i18n)

router.isReady().then(() => app.mount('#app'))
