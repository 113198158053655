<script setup>
// #region Imports
import { ref } from 'vue'

import Markdown from 'vue3-markdown-it'

import authService from '@/services/authService'
// #endregion

// #region Data
const tac = ref('')
// #endregion

// #region Created
authService.getTAC().then((response) => {
  tac.value = response.data.body
})
// #endregion
</script>

<template>
  <v-card class="pa-md-6" elevation="0">
    <section class="pdf-item">
      <Markdown class="md-body" :source="tac" />
    </section>
  </v-card>
</template>

<style scoped lang="scss">
.v-card__text {
  height: 500px;
  overflow: auto;
}
.pdf-item {
  width: 100%;
  margin: 20px;
  font-family: 'Roboto', sans-serif;
}
</style>
