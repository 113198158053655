import axios from 'axios'

export default {
  getConfig() {
    return {
      headers: {
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('tokens'))?.access
      }
    }
  },
  getAdviceCategories() {
    return axios
      .get(
        process.env.VUE_APP_API_URL + '/administration/advice/category/',
        this.getConfig()
      )
      .then((response) => {
        return response.data.results.sort((a, b) => a.order - b.order)
      })
  },
  getAdvicePublicCategories() {
    return axios
      .get(
        process.env.VUE_APP_API_URL + '/advice/category/',
        this.getConfig()
      )
      .then((response) => {
        return response.data.sort((a, b) => a.order - b.order)
      })
  },
  createAdviceCategory(adviceCategory) {
    return axios
      .post(
        process.env.VUE_APP_API_URL + '/administration/advice/category/',
        adviceCategory,
        this.getConfig()
      )
      .then((response) => {
        return response.data
      })
  },
  getAdviceCategory(id) {
    return axios
      .get(
        process.env.VUE_APP_API_URL +
          '/administration/advice/category/' +
          id +
          '/',
        this.getConfig()
      )
      .then((response) => {
        const adviceCategory = response.data
        if (adviceCategory.subcategories) {
          adviceCategory.subcategories.sort((a, b) => a.order - b.order)
          adviceCategory.subcategories.map((item) =>
            item.advices.map((advice) => {
              advice.isOnboarding = advice.is_onboarding
              delete advice.is_onboarding
              return advice
            })
          )
        }
        return adviceCategory
      })
  },
  updateAdviceCategory(id, data) {
    return axios
      .put(
        process.env.VUE_APP_API_URL +
          '/administration/advice/category/' +
          id +
          '/',
        data,
        this.getConfig()
      )
      .then((response) => {
        return response.data
      })
  },
  updateAdviceCategoryOrder(newOrders) {
    return axios
      .put(
        process.env.VUE_APP_API_URL +
          '/administration/advice/category/reorder/',
        newOrders,
        this.getConfig()
      )
      .then((_) =>
        axios
          .get(
            process.env.VUE_APP_API_URL + '/administration/advice/category/',
            this.getConfig()
          )
          .then((response) => {
            return response.data.results
          })
      )
  },
  deleteAdviceCategory(id) {
    return axios.delete(
      process.env.VUE_APP_API_URL +
        '/administration/advice/category/' +
        id +
        '/',
      this.getConfig()
    )
  }
}
