const answers = new Map([
  [
    1,
    {
      title: 'Le programme est 100% fait pour toi !',
      text: 'En suivant les quantités du programme, tu vas déclencher une perte de poids saine et durable.'
    }
  ],
  [
    2,
    {
      title: 'Le programme est fait pour toi à : 95% !',
      text: `Comme tu as plus de 60 ans, je te conseille d'ajouter une activité physique douce (marche, pilates, natation, yoga...) pour que le déficit calorique soit optimal.<br> 
Cela te permettra de déclencher un amincissement rapide et durable.
`
    }
  ],
  [
    3,
    {
      title: 'Le programme est 100% fait pour toi !',
      text: 'Comme tu n\'as pas d\'objectif de perte de poids. Tu peux ajouter 10 à 20g de féculents crus par portion prévue au menu + une collation quand tu en as envie + de la gourmandise (fromage, chocolat...) dans les recettes + 1 repas libre par semaine.'
    }
  ],
  [
    4,
    {
      title: 'Le programme est 100% fait pour toi !',
      text: `En suivant les quantités du programme tu vas déclencher une perte de poids saine et durable.<br><br>
  Si tu suis le programme avec un homme, il faut légèrement adapter les quantités pour lui :<br>
  <ul>
  <li>Il a un objectif de perte de poids : Il doit ajouter 30 à 40g de féculents crus à chaque portion prévue au menu + une collation quand il en ressent le besoin.</li>
  <li>Il n’a pas d’objectif de perte de poids : Il doit ajouter 40g à 50g de féculents crus à la portion dès qu’il y en a au menu + une collation tous les jours + faire autant de repas libres qu’il le souhaite.</li>
  </ul>
  `
    }
  ],
  [
    5,
    {
      title: 'Le programme est fait pour toi à : 95% !',
      text: `Comme tu as plus de 60 ans, je te conseille d'ajouter une activité physique douce (marche, pilates, natation, yoga...) pour que le déficit calorique soit optimal.<br> 
Cela te permettra de déclencher un amincissement rapide et durable.<br><br>
Si tu suis le programme avec un homme, il faut légèrement adapter les quantités pour lui :
<ul>
<li>Il a un objectif de perte de poids : Il doit ajouter 30 à 40g de féculents crus à chaque portion prévue au menu + une collation quand il en ressent le besoin.</li>
<li>Il n’a pas d’objectif de perte de poids : Il doit ajouter 40g à 50g de féculents crus à la portion dès qu’il y en a au menu + une collation tous les jours + faire autant de repas libres qu’il le souhaite.</li>
</ul>
`
    }
  ],
  [
    6,
    {
      title: 'Le programme est 100% fait pour toi !',
      text: `Comme tu n'as pas d'objectif de perte de poids. Tu peux ajouter 10 à 20g de féculents crus par portion prévue au menu + une collation quand tu en as envie + de la gourmandise (fromage, chocolat...) dans les recettes + 1 repas libre par semaine.<br><br>
  Si tu suis le programme avec un homme, il faut légèrement adapter les quantités pour lui :<br>
  <ul>
  <li>Il a un objectif de perte de poids : Il doit ajouter 30 à 40g de féculents crus à chaque portion prévue au menu + une collation quand il en ressent le besoin.</li>
  <li>Il n’a pas d’objectif de perte de poids : Il doit ajouter 40g à 50g de féculents crus à la portion dès qu’il y en a au menu + une collation tous les jours + faire autant de repas libres qu’il le souhaite.</li>
  </ul>
  `
    }
  ],
  [
    7,
    {
      title: 'Le programme est fait pour toi à : 95% !',
      text: `Comme tu fais plus de 2 heures d'activité physique par semaine, il faut ajuster les quantités pour que le déficit calorique soit optimal.<br><br>
  Pour cela, tu peux ajouter une collation où une portion de féculents de 40g cru (à répartir comme tu veux sur la journée) les jours ou tu fais du sport. 
  `
    }
  ],
  [
    8,
    {
      title: 'Le programme est 100% fait pour toi !',
      text: 'En suivant les quantités du programme, tu vas déclencher une perte de poids saine et durable.'
    }
  ],
  [
    9,
    {
      title: 'Le programme est fait pour toi à : 90% !',
      text: `Comme tu n'as pas d'objectif de perte de poids et que tu fais plus de deux heures d’activité physique par semaine.<br>
  Tu dois ajouter :<br>
  <ul>
  <li>10 à 20g de féculents crus par portion prévue au menu tous les jours</li>
  <li>40g de féculents crus (à répartir comme tu veux sur la journée) les jours ou tu fais du sport</li>
  <li>une collation quand tu en as envie</li>
  <li>de la gourmandise (fromage, chocolat...) dans les recettes</li>
  <li>1 repas libre par semaine.</li>
  </ul>
  `
    }
  ],
  [
    10,
    {
      title: 'Le programme est fait pour toi à : 90% !',
      text: `Comme tu fais plus de 2 heures d'activité physique par semaine, il faut ajuster les quantités pour que le déficit calorique soit optimal.<br>
  Pour cela, tu peux ajouter une collation où une portion de féculents de 40g cru (à répartir comme tu veux sur la journée) les jours ou tu fais du sport.<br><br>
  Si tu suis le programme avec un homme, il faut légèrement adapter les quantités pour lui :<br>
  <ul>
  <li>Il a un objectif de perte de poids : Il doit ajouter 30 à 40g de féculents crus à chaque portion prévue au menu + une collation quand il en ressent le besoin.</li>
  <li>Il n’a pas d’objectif de perte de poids : Il doit ajouter 40g à 50g de féculents crus à la portion dès qu’il y en a au menu + une collation tous les jours + faire autant de repas libres qu’il le souhaite. </li>
  </ul>
  `
    }
  ],
  [
    11,
    {
      title: 'Le programme est fait pour toi à : 90% !',
      text: `Comme tu n'as pas d'objectif de perte de poids et que tu fais plus de deux heures d’activité physique par semaine.<br>
  Tu dois ajouter :<br>
  <ul>
  <li>10 à 20g de féculents crus par portion prévue au menu tous les jours</li>
  <li>40g de féculents crus (à répartir comme tu veux sur la journée) les jours ou tu fais du sport</li>
  <li>une collation quand tu en as envie</li>
  <li>de la gourmandise (fromage, chocolat...) dans les recettes</li>
  <li>1 repas libre par semaine.</li>
  </ul><br><br>
  Si tu suis le programme avec un homme, il faut légèrement adapter les quantités pour lui :<br>
  <ul>
  <li>Il a un objectif de perte de poids : Il doit ajouter 30 à 40g de féculents crus à chaque portion prévue au menu + une collation quand il en ressent le besoin.</li>
  <li>Il n’a pas d’objectif de perte de poids : Il doit ajouter 40g à 50g de féculents crus à la portion dès qu’il y en a au menu + une collation tous les jours + faire autant de repas libres qu’il le souhaite. </li>
  </ul>
  `
    }
  ],
  [
    12,
    {
      title: 'Le programme est 100% fait pour toi !',
      text: `Comme tu n'as pas d'objectif de perte de poids. Tu peux ajouter 10 à 20g de féculents crus par portion prévue au menu + une collation quand tu en as envie + de la gourmandise (fromage, chocolat...) dans les recettes + 1 repas libre par semaine.<br><br>
  Si tu suis le programme avec un homme, il faut légèrement adapter les quantités pour lui :<br>
  <ul>
  <li>Il a un objectif de perte de poids : Il doit ajouter 30 à 40g de féculents crus à chaque portion prévue au menu + une collation quand il en ressent le besoin.</li>
  <li>Il n’a pas d’objectif de perte de poids : Il doit ajouter 40g à 50g de féculents crus à la portion dès qu’il y en a au menu + une collation tous les jours + faire autant de repas libres qu’il le souhaite. </li>
  </ul>
  `
    }
  ],
  [
    13,
    {
      title: 'Le programme est fait pour toi à : 90% !',
      text: 'Les quantités sont calculées pour une femme.<br> Pour les adapter à ton profil, tu dois ajouter 30 à 40g de féculents crus à chaque portion prévue au menu + une collation quand tu en ressens le besoin.'
    }
  ],
  [
    14,
    {
      title: 'Le programme est fait pour toi à : 90% !',
      text: `Comme tu n'as pas d’objectif de perte de poids. Tu peux suivre le programme en ajoutant 40g à 50g de féculents crus à la portion dès qu’il y en a au menu<br>
  + une collation tous les jours<br>
  + faire autant de repas libre que tu le souhaites
  `
    }
  ],
  [
    15,
    {
      title: 'Le programme est fait pour toi à : 90% !',
      text: `Les quantités sont calculées pour une femme.<br>
  Pour les adapter à ton profil, tu dois ajouter 40 à 50g de féculents crus à chaque portion prévue au menu + une collation quand tu en ressens le besoin.
  `
    }
  ]
])

export default answers
