<script setup>
import { ref, defineProps, defineEmits } from 'vue'

import TurndownService from 'turndown'
import { marked } from 'marked'

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

// #region Props
const props = defineProps(['content', 'isDish'])
// #endregion

// #region Emits
const emits = defineEmits(['change'])
// #endregion

// #region Data
const editor = ref(null)
const content = ref(null)

const editorOptions = ref({
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }, 'link']
  ]
})
// #endregion

// #region Methods
const convertMarkdownToHtml = (md) => {
  if (props.isDish === true) {
    return md ? marked.parse(md).replace(/\n/g, '') : ''
  } else {
    return md ? marked.parse(md) : ''
  }
}
const convertHtmlToMarkdown = (args) => {
  if (args) {
    const turndownService = new TurndownService()
    emits('change', turndownService.turndown(args))
  }
}
// #endregion

// #region Created
content.value = convertMarkdownToHtml(props.content)
// #endregion
</script>

<template>
  <div>
    <QuillEditor
      ref="editor"
      :content="content"
      @update:content="convertHtmlToMarkdown($event)"
      contentType="html"
      :toolbar="editorOptions.toolbar"
      theme="snow"
    />
  </div>
</template>
